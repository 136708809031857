import { formatTwoDecimals } from 'utils/utils'

export const displayGrades = ['Au', 'S', 'As', 'Ctoc', 'Ccarb']

export const activeStockpiles = ['A', 'B', 'C', 'D']
export const elementsOrder = ['Au', 'S', 'As', 'Ctoc', 'Ccarb', 'Ag', 'Sb', 'RQD']

export const pileTypes = {
  rom: /^rom$/i,
  lgp: /^lgp$/i,
  totalrom: /^totalrom$/i,
  totallgp: /^totallgp$/i,
  totalinventory: /^totalinventory$/i,
  unknown: /^unknown$/i
}

export const elementTypes = {
  au: /^au$/i,
  s: /^s$/i,
  as: /^as$/i,
  ctoc: /^ctoc$/i,
  ccarb: /^ccarb$/i,
  ag: /^ag$/i,
  sb: /^sb$/i,
  rqd: /^rqd$/i
}

export const elements = {
  AU: {
    regex: /^au$/i,
    content: 'g/t'
  },
  S: {
    regex: /^s$/i,
    content: '%'
  },
  AS: {
    regex: /^as$/i,
    content: '%'
  },
  CTOC: {
    regex: /^ctoc$/i,
    content: '%'
  },
  CCARB: {
    regex: /^ccarb$/i,
    content: '%'
  },
  AG: {
    regex: /^ag$/i,
    content: '%'
  },
  SB: {
    regex: /^sb$/i,
    content: '%'
  },
  RQD: {
    regex: /^rqd$/i,
    content: '%'
  }
}

export function recipePlannerColumns(t) {
  return [
    {
      title: t('common.date', 'Date'),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('common.shift', 'Shift'),
      dataIndex: 'shift',
      key: 'shift'
    },
    {
      title: 'Au',
      dataIndex: 'au',
      key: 'au',
      align: 'right',
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'S',
      dataIndex: 's',
      key: 's',
      align: 'right',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'As',
      dataIndex: 'as',
      key: 'as',
      align: 'right',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ctoc',
      dataIndex: 'ctoc',
      key: 'ctoc',
      align: 'right',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ccarb',
      dataIndex: 'ccarb',
      key: 'ccarb',
      align: 'right',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ag',
      dataIndex: 'ag',
      key: 'ag',
      align: 'right',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Sb',
      dataIndex: 'sb',
      key: 'sb',
      align: 'right',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'RQD',
      dataIndex: 'rqd',
      key: 'rqd',
      align: 'right',
      render: (grade, record) => (grade ? Math.round(grade) : null)
    },
    {
      title: t('common.areas', 'Areas'),
      dataIndex: 'areas',
      key: 'areas'
    },
    {
      title: t('common.assayed', 'Assayed'),
      dataIndex: 'assayed',
      key: 'assayed'
    },
    {
      title: t('common.tons', 'Tons'),
      dataIndex: 'tonnes',
      key: 'tonnes'
    }
  ]
}
export const SHIFT_LENGTH_IN_HOURS = 12
export const RECALCULATION_LENGTH_IN_MS = 150000
export const RECALCULATION_START_MINUTE = 19
export const RECALCULATION_START_SECONDS = 44
export const RECALCULATION_END_MINUTE = 22
export const trendLines = [
  { title: 'Au', color: 'rgb(102, 166, 30)', yaxis: 'left' },
  { title: 'S', color: 'rgb(231, 41, 138)', yaxis: 'left' },
  { title: 'As', color: 'rgb(117, 112, 179)', yaxis: 'left' },
  { title: 'Ctoc', color: 'rgb(217, 95, 2)', yaxis: 'left' },
  { title: 'Ccarb', color: 'rgb(27, 158, 119)', yaxis: 'left' },
  { title: 'Ag', color: 'rgb(230, 171, 2)', yaxis: 'left' },
  { title: 'Sb', color: 'rgb(102, 102, 102)', yaxis: 'right' },
  { title: 'RQD', color: 'rgb(166, 118, 29)', yaxis: 'right' }
]
export const roles = {
  geologist: 'MFM.Geologist',
  metallurgist: 'MFM.Metallurgist',
  readOnly: 'MFM.ReadOnly',
  crushOperator: 'MFM.CrusherOperator',
  mineGeologist: 'MFM.MineGeologist'
}
