import ky from 'ky'
import { msalInstance } from './index'
import { apiScopes } from './msalConfig'

async function setAccessToken(request) {
  const accessToken = await getAccessToken(apiScopes)
  request.headers.set('Authorization', `Bearer ${accessToken}`)
}

export const apis = {
  stockpileInventory: 'stockpile-inventory-api',
  recipePlanning: 'recipe-planning-api'
}

const api = ky.create({
  prefixUrl: `${process.env.REACT_APP_API_URL}`,
  hooks: {
    beforeRequest: [setAccessToken]
  }
})

export async function getAccessToken(args) {
  const account = msalInstance.getActiveAccount()

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
  }
  const { accessToken } = await msalInstance.acquireTokenSilent({ ...args, account })

  return accessToken
}

export const defaultQueryFn = ({ queryKey }) => api.get(queryKey[0]).json()
export const fetchRecipeStockpiles = (slices) => api.post('recipe-stockpiles', { json: slices }).json()
export const fetchRecipeSummary = (slices) => api.post(`recipe-summary`, { json: slices }).json()

export const postSliceSurveyResult = ({ sliceId, ...rest }) =>
  api.post(`stockpile-slices/${sliceId}/survey-results`, { json: rest })

export const postStockpileSurveyResult = (pileId) => (values) =>
  api.post(`stockpiles/${pileId}/survey-results`, { json: values })

export const updateStockpileComment = (pileId) => (comment) =>
  api.put(`stockpiles/${pileId}/comment`, { json: comment })

export const deleteStockpileComment = (pileId) => () => api.delete(`stockpiles/${pileId}/comment`)
