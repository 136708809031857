import { Affix, Button, Col, Row } from 'antd'
import { stockpilesListAtom } from 'atoms'
import { useClearSelections } from 'hooks/useClearSelections'
import { find } from 'rambda'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { formatTonnes } from 'utils/utils'
import styles from './total-selected-slices.module.css'

export function TotalSelectedSlices(props) {
  const { t } = useTranslation()
  const clearSelections = useClearSelections()
  const listOfStockpiles = useRecoilValue(stockpilesListAtom)
  const totalSelectedTons = () => (props.totals ? props.totals.reduce((a, c) => a + c.totalTons, 0) : 0)
  const nameOfStockpile = (pileId) => find((stockpile) => stockpile.pileId === pileId, listOfStockpiles)?.title

  return totalSelectedTons() > 0 ? (
    <Affix offsetTop={0}>
      <div className={styles.totals}>
        <Row gutter={40} align="middle">
          <Col>
            <span className={styles.totalsHeading}>{t('slice-selector.selected-tons', 'Selected tons')}</span>
          </Col>
          {props.totals.map((it) =>
            it.totalTons > 0 ? (
              <Col key={it.stockpileId} className={styles.totalsItem}>
                <span className={styles.totalsName}>{nameOfStockpile(it.stockpileId)}</span>
                <span>{formatTonnes(it.totalTons)}</span>
              </Col>
            ) : null
          )}
          <Col>
            <span className={styles.totalsTotal}>{t('common.total', 'Total')}</span>
            {formatTonnes(totalSelectedTons())}
          </Col>
          <Col>
            <Button onClick={() => clearSelections()}>
              {t('slice-selector.clear-selections', 'Clear all selections')}
            </Button>
          </Col>
        </Row>
      </div>
    </Affix>
  ) : null
}
