// @ts-check
import { Radio } from 'antd'
import { useRecoilState } from 'recoil'
import { usageRatioSelector } from 'selectors'
import styles from './percentage-selector.module.css'

/** @param {import('components').PercentageSelectorPropsType} props */
export function PercentageSelector({ stockpileId, disabled, value }) {
  const [ratio, setRatio] = useRecoilState(usageRatioSelector(stockpileId))
  /** @param {import('antd').RadioChangeEvent} event */
  const onChange = (event) => setRatio(event.target.value)
  return (
    <div className={styles.percentageSelector}>
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        size="small"
        defaultValue={null}
        onChange={onChange}
        value={disabled ? value : ratio}
        disabled={disabled}
      >
        <Radio.Button value={0}>0 %</Radio.Button>
        <Radio.Button value={10}>10 %</Radio.Button>
        <Radio.Button value={20}>20 %</Radio.Button>
        <Radio.Button value={30}>30 %</Radio.Button>
        <Radio.Button value={40}>40 %</Radio.Button>
        <Radio.Button value={50}>50 %</Radio.Button>
        <Radio.Button value={60}>60 %</Radio.Button>
        <Radio.Button value={70}>70 %</Radio.Button>
        <Radio.Button value={80}>80 %</Radio.Button>
        <Radio.Button value={90}>90 %</Radio.Button>
        <Radio.Button value={100}>100 %</Radio.Button>
      </Radio.Group>
    </div>
  )
}
