import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { Button, Spin } from 'antd'
import { reactPlugin } from 'appInsights'
import { recalculatingAtom } from 'atoms'
import { ErrorFallback } from 'components/common/error-fallback'
import { Layout } from 'components/Layout'
import dayjs from 'dayjs'
import { apiScopes } from 'msalConfig'
import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useHistory } from 'react-router'
import { useUnmount } from 'react-use'
import { useSetRecoilState } from 'recoil'
import { isRecalculating } from 'utils/date-utils'
import { CustomNavigationClient } from 'utils/utils'
import styles from '../styles/App.module.css'

function App({ pca }) {
  const history = useHistory()
  const { t } = useTranslation()
  const [iv, setIv] = useState(null)
  const setRecalculating = useSetRecoilState(recalculatingAtom)
  const navigationClient = new CustomNavigationClient(history)
  pca.setNavigationClient(navigationClient)

  function LoadingComponent() {
    return (
      <div className={styles.loadingComponent}>
        {t('common.logging-to', 'Logging in to MFM')}
        <Spin size="large" style={{ paddingTop: '100px' }} />
      </div>
    )
  }

  /** @param {import('@azure/msal-react').MsalAuthenticationResult} */
  const ErrorMessage = ({ error, login }) => {
    return (
      <>
        <p>
          {t('error.error-occured', 'Error occured')}: {error?.errorCode}
        </p>
        <Button type="primary" onClick={() => login(InteractionType.Redirect)}>
          {t('common.retry', 'Retry')}
        </Button>
      </>
    )
  }

  // set interval to check if it's recalculation time
  useEffect(() => {
    if (!iv) {
      const interval = setInterval(() => {
        const curTime = dayjs()
        if (isRecalculating(curTime)) {
          setRecalculating(true)
          return
        }
        setRecalculating(false)
      }, 15000)
      setIv(interval)
    }
  }, [setIv, iv, setRecalculating])

  useUnmount(() => clearInterval(iv))

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={apiScopes}
          loadingComponent={LoadingComponent}
          errorComponent={ErrorMessage}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback} appInsights={reactPlugin}>
            <div className={styles.app}>
              <Layout />
            </div>
          </ErrorBoundary>
        </MsalAuthenticationTemplate>
      </MsalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </AppInsightsContext.Provider>
  )
}

export default App
