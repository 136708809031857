// @ts-check
import { updateStockpileState } from 'api/stockpileInventoryApi'
import { ElementsAndGrades } from 'components/ElementsAndGrades'
import { StockpileStateBar } from 'components/StockpileStateBar'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styles from '../styles/StockpileSummaryCard.module.css'

export function StockpileSummaryCard({ stockpile }) {
  const queryClient = useQueryClient()
  const updateState = useMutation(updateStockpileState(stockpile.pileId), {
    onSuccess: () => queryClient.invalidateQueries(['stockpile-summaries', process.env.REACT_APP_API_MINEID])
  })
  /** @param {'pending' | 'ready' | 'released'} newState */
  const onStateChange = (newState) => updateState.mutate(newState)

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Link to={`stockpile/${stockpile.pileId}`}>{stockpile.title}</Link>
        </div>
        <StockpileStateBar
          value={stockpile.state}
          editableState={stockpile.editableState}
          disabled={false}
          onStateChange={onStateChange}
        />
      </div>
      <ElementsAndGrades grades={stockpile.averageGrades} tonnes={stockpile.tonnes} />
    </div>
  )
}
