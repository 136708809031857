import { Checkbox, Space } from 'antd'
import { stockpilesFilterAtom } from 'atoms'
import { StockpileFilter } from 'components/common/stockpile-filter'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { listOfStockpilesSelector } from 'selectors'
import { filterStockpiles } from 'utils/utils'
import styles from '../styles/StockpileSelector.module.css'

export function StockpileSelector(props) {
  const [checkedList, setCheckedList] = useState([])
  const { t } = useTranslation()
  const listOfStockpiles = useRecoilValue(listOfStockpilesSelector)
  const filter = useRecoilValue(stockpilesFilterAtom)

  const isChecked = (id) => checkedList.includes(id)

  function handleOnClick(id) {
    if (checkedList.includes(id)) {
      setCheckedList(checkedList.filter((it) => it !== id))
      return
    }

    setCheckedList([...checkedList, id])
  }
  return (
    <div className={styles.stockpileSelector}>
      <div className={styles.filtersMenu}>
        <StockpileFilter />
      </div>
      <div className={styles.stockpileList}>
        {listOfStockpiles.filter(filterStockpiles(filter)).map((s) => {
          return (
            <NavLink
              to={s.pileId}
              activeClassName={styles.stockpileSelected}
              key={s.pileId}
              onClick={() => handleOnClick(s.pileId)}
            >
              <div key={s.pileId} className={styles.stockpile}>
                <div className={styles.selected} />
                <div className={styles.title}>{s.title}</div>
                <Space>
                  <div className={styles.state}>{t(`stockpile.state.${s.state.toLowerCase()}`, s.state)}</div>
                  {s.pileType === 'rom' ? (
                    <div className={styles.state}>
                      {t(`stockpile.state.${s.editableState.toLowerCase()}`, s.editableState)}
                    </div>
                  ) : null}
                </Space>
                {props.selectable && <Checkbox style={{ marginLeft: '16px' }} checked={isChecked(s.pileId)} />}
              </div>
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}
