import { fetchStockpileSummaries } from 'api/stockpileInventoryApi'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useLocalStorage, useMount } from 'react-use'
import { filterRomStockpiles, sortByTitle, sortStockpilesByPileType } from 'utils/utils'
import { activeStockpiles } from '../constants'

export function useStockpiles() {
  const [listOfStockpiles, setListOfStockpiles] = useLocalStorage('stockpiles-list')
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['stockpile-summaries', process.env.REACT_APP_API_MINEID],
    fetchStockpileSummaries,
    { enabled: false }
  )

  useMount(() => {
    if (!listOfStockpiles || listOfStockpiles.length === 0) {
      refetch()
    }
  })

  useEffect(() => {
    if (data) {
      const stockpiles = data
        .filter(filterRomStockpiles(activeStockpiles))
        .sort(sortStockpilesByPileType)
        .sort(sortByTitle)
      setListOfStockpiles(stockpiles)
    }
  }, [data, setListOfStockpiles])

  return listOfStockpiles || []
}
