import { api, apis, version } from './api'

const { REACT_APP_API_URL, REACT_APP_API_MINEID, REACT_APP_NO_API_MANAGEMENT } = process.env

let inventoryApi = api.extend({ prefixUrl: `${REACT_APP_API_URL}/${apis.stockpileInventory}/${version}` })

if(REACT_APP_NO_API_MANAGEMENT === 'true'){
  inventoryApi = api.extend({ prefixUrl: `${REACT_APP_API_URL}` })
}  

export const fetchStockpileSummaries = () => inventoryApi.get(`stockpile-summaries/${REACT_APP_API_MINEID}`).json()
export const fetchStockpileSummariesNoGrades = () =>
  inventoryApi.get(`stockpile-summaries-no-grades/${REACT_APP_API_MINEID}`).json()

export const fetchStockpileSummariesKamrock = () =>
  inventoryApi.get(`stockpile-summaries-kamrock/${REACT_APP_API_MINEID}`).json()

export const fetchStockpileById = ({ queryKey }) =>
  queryKey[1] ? inventoryApi.get(`stockpiles/${queryKey[1]}`).json() : null

// STOCKPILE COMMENTS
export const addStockpileComment = (pileId) => (comment) =>
  inventoryApi.post(`stockpiles/${pileId}/comment`, { json: comment })
export const updateStockpileComment = (pileId) => (comment) =>
  inventoryApi.put(`stockpiles/${pileId}/comment`, { json: comment })
export const deleteStockpileComment = (pileId) => () => inventoryApi.delete(`stockpiles/${pileId}/comment`)

// SURVEY RESULTS
export const postSliceSurveyResult = ({ sliceId, ...rest }) =>
  inventoryApi.post(`stockpile-slices/${sliceId}/survey-results`, { json: rest })
export const postStockpileSurveyResult = (pileId) => (values) =>
  inventoryApi.post(`stockpiles/${pileId}/survey-results`, { json: values })

export const updateStockpileState = (pileId) => (newState) =>
  inventoryApi.put(`stockpiles/${pileId}/state`, { json: newState })
