import dayjs from 'dayjs'
import { head, last, pick } from 'rambda'
import { selector, selectorFamily } from 'recoil'
import { sortDateAsc } from 'utils/date-utils'
import { filterStockpiles, idByShift, renameProp, sortByShiftAsc } from 'utils/utils'
import {
  lastRecipeEndTimeAtom,
  millCapacityAtom,
  recipeStartTimeAtom,
  selectedSlicesForRecipeTooAtom,
  selectedStockpilesForRecipeAtom,
  stockpilesFilterAtom,
  stockpilesListAtom,
  usageRatioAtom,
  userAtom
} from './atoms'

export const filteredStockpileSummariesSelector = selector({
  key: 'filteredStockpilesList',
  get: ({ get }) => {
    const filter = get(stockpilesFilterAtom)
    const list = get(stockpilesListAtom)
    return list.filter((stockpile) => filterStockpiles(filter)(stockpile))
  }
})

export const listOfStockpilesSelector = selector({
  key: 'listOfStockpiles',
  get: ({ get }) => {
    const list = get(stockpilesListAtom)
    return list.filter(filterStockpiles('ALL'))
  }
})

export const listOfAllSelectedSlicesSelector = selector({
  key: 'listOfAllSelectedSlices',
  get: ({ get }) => {
    const stockpiles = get(selectedStockpilesForRecipeAtom).map((x) => x.id)
    return stockpiles.flatMap((id) => get(selectedSlicesForRecipeTooAtom(id)))
  }
})

export const selectedSlicesValidationSelector = selector({
  key: 'selectedSlicesValidation',
  get: ({ get }) => {
    const stockpiles = get(selectedStockpilesForRecipeAtom).map((x) => x.id)
    return stockpiles.reduce((res, id) => {
      const slices = get(selectedSlicesForRecipeTooAtom(id))

      if (!slices?.length) {
        res[id] = true
        return res
      }

      const last_i = slices.length - 1

      for (let i = 0; i < slices.length; i++) {
        const { usedTonnes, availableTons } = slices[i]
        if (
          usedTonnes != null &&
          (usedTonnes < 0 || availableTons < usedTonnes || (i === last_i && usedTonnes === 0))
        ) {
          res[id] = false
          return res
        }
      }

      for (let i = 1; i < slices.length; i++) {
        const prev = slices[i - 1].usedTonnes
        const curr = slices[i].usedTonnes
        if (
          (curr === 0 && prev !== 0) ||
          (i !== last_i && curr != null && prev == null) ||
          (i !== last_i && curr != null && prev != null && 0 < prev)
        ) {
          res[id] = false
          return res
        }
      }

      res[id] = true
      return res
    }, {})
  }
})

export const listOfSelectedSliceIdsSelector = selectorFamily({
  key: 'listOfSelectedSlicesIds',
  get: (pileId) => ({ get }) => {
    const selectedSlices = get(selectedSlicesForRecipeTooAtom(pileId))
    return selectedSlices.map((slice) => idByShift(slice.shift))
  }
})

export const selectedStockpilesAndTotalTonsSelector = selector({
  key: 'selectedStockpilesAndTotalTons',
  get: ({ get }) => {
    const stockpiles = get(selectedStockpilesForRecipeAtom).map((x) => x.id)
    return stockpiles.reduce((acc, cur) => {
      const slices = get(selectedSlicesForRecipeTooAtom(cur))
      const totalTons = slices.reduce((acc, cur) => {
        if (cur.usedTonnes) {
          return acc + cur.usedTonnes
        }
        if (cur.availableTons) {
          return acc + cur.availableTons
        }
        return acc + cur.tons
      }, 0)
      return [...acc, { stockpileId: cur, totalTons }]
    }, [])
  }
})

export const recipeDataSelector = selector({
  key: 'recipeDataSelector',
  get: ({ get }) => {
    const selectedStockpiles = get(selectedStockpilesForRecipeAtom).map((x) => x.id)
    const capacity = get(millCapacityAtom)
    const recipeStartTime = get(recipeStartTimeAtom)
    const startTime = recipeStartTime.date || get(lastRecipeEndTimeAtom)
    const user = get(userAtom)
    const slices = selectedStockpiles
      .map((stockpileId) => {
        const sls = get(selectedSlicesForRecipeTooAtom(stockpileId))
          .map((selectedSlice) => ({
            ...selectedSlice,
            shift: pick(['startDate', 'type'], selectedSlice.shift)
          }))
          .sort((a, b) => sortDateAsc(a.shift.startDate, b.shift.startDate))
          .sort((a, b) => sortByShiftAsc(a.shift, b.shift))

        if (sls.length > 0) {
          const lastPartialSlice = head(sls).usedTonnes ? pick(['sliceId', 'shift', 'usedTonnes'], head(sls)) : null
          const firstTonFixSlice =
            last(sls).usedTonnes && sls.length > 1 ? pick(['sliceId', 'shift', 'usedTonnes'], last(sls)) : null
          const fullSlices = sls.filter((sl) => true /*!sl.usedTonnes*/)
          const usageRatio = get(usageRatioAtom(stockpileId))
          const initialPercentage = get(initialPercentageSelector).find((ip) => ip.stockpileId === stockpileId)
            ?.initialPercentage
          return {
            stockpileId,
            firstTonFixSlice: null, // firstTonFixSlice ? renameProp('usedTonnes', 'tonnesFix', firstTonFixSlice) : null,
            lastPartialSlice: null,
            fullSlices,
            usageRatio: usageRatio !== null ? usageRatio : Math.round(initialPercentage / 10) * 10,
            usageRatioRaw: usageRatio
          }
        }

        return null
      })
      .filter((x) => x)

    return {
      author: {
        creator: user.name
      },
      isCustomStartTime: recipeStartTime.customStartTime,
      startTime: dayjs(startTime).format(),
      pilesAndSlices: [...slices],
      capacity
    }
  }
})
export const newRecipeDataSelector = selector({
  key: 'new-recipe-data',
  get: ({ get }) => {
    const recipeStartTime = get(recipeStartTimeAtom)
    const customStartTime = recipeStartTime.customStartTime ? dayjs(recipeStartTime.date).format() : null
    const author = {
      creator: get(userAtom).name,
      recipeCreationTime: dayjs().format()
    }
    const capacity = get(millCapacityAtom)

    return {
      customStartTime,
      author,
      capacity
    }
  }
})
export const usageRatioSelector = selectorFamily({
  key: 'usage-ratio-selector',
  get: (id) => ({ get }) => get(usageRatioAtom(id)),
  set: (id) => ({ set }, newVal) => set(usageRatioAtom(id), newVal)
})

export const totalBalanceSelector = selector({
  key: 'total-balance',
  get: ({ get }) =>
    get(recipeDataSelector)?.pilesAndSlices?.reduce((acc, cur) => acc + (cur.usageRatioRaw ?? 0), 0) ?? 0
})

export const initialPercentageSelector = selector({
  key: 'initial-percentage',
  get: ({ get }) => {
    const totals = get(selectedStockpilesAndTotalTonsSelector)
    const totalTons = totals.reduce((acc, cur) => acc + cur.totalTons, 0)
    return totals.map((t) => ({
      stockpileId: t.stockpileId,
      initialPercentage: Math.round((t.totalTons / totalTons) * 100)
    }))
  }
})
