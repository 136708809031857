// @ts-check
import { EditOutlined } from '@ant-design/icons'
import {
  Badge,
  Button,
  Col,
  Divider,
  message,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography
} from 'antd'
import { fetchRecipePlan, postNewRecipe } from 'api/recipePlannerApi'
import { millCapacityAtom, recalculatingAtom, recipeStartTimeAtom } from 'atoms'
import { Title } from 'components/common/title'
import { Capacity } from 'components/recipe-planner/capacity'
import { EditTonnesModal } from 'components/recipe-planner/edit-tonnes-modal'
import { PercentageSelector } from 'components/recipe-planner/percentage-selector'
import { Summary } from 'components/recipe-planner/summary'
import dayjs from 'dayjs'
import { filter, pick } from 'rambda'
import { useEffect, useReducer } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { useMount, useUnmount } from 'react-use'
import { useRecoilValue } from 'recoil'
import { initialPercentageSelector, newRecipeDataSelector, recipeDataSelector, totalBalanceSelector } from 'selectors'
import { sortDateDesc } from 'utils/date-utils'
import {
  capitalize,
  findElementsAndGrades,
  formatTonnes,
  formatTwoDecimals,
  idByShift,
  joinAreaCodesIntoString,
  pickShiftType,
  renameProp,
  sortByShiftDesc
} from 'utils/utils'
import { RECALCULATION_END_MINUTE } from '../constants'
import {
  recipePlannerActionTypes as actionTypes,
  recipePlannerInitialState as initialState,
  recipeSelectorReducer as reducer
} from '../reducers'
import styles from '../styles/RecipePlanner.module.css'
import { RecipeConfirmationModal } from './recipe-planner/recipe-confirmation-modal'

/** @param {import('components').RecipePlannerPropsType} props */
export function RecipePlanner(props) {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)
  const recalculating = useRecoilValue(recalculatingAtom)
  const { t } = useTranslation()
  const recipeData = useRecoilValue(recipeDataSelector)
  const balance = useRecoilValue(totalBalanceSelector)
  const initialPercentage = useRecoilValue(initialPercentageSelector)
  const millCapacity = useRecoilValue(millCapacityAtom)
  const newRecipeData = useRecoilValue(newRecipeDataSelector)
  const recipeStartTime = useRecoilValue(recipeStartTimeAtom)
  const { isLoading, isError, isFetching, data, error, refetch } = useQuery(
    ['recipe-planner', recipeData],
    fetchRecipePlan,
    { enabled: false, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  const postRecipe = useMutation(postNewRecipe, {
    onSuccess: () => {
      toggleConfirmationModal()
      history.push('/recipe-queue?cancel=true')
    },
    onError: (error) => {
      notification.error({
        message: t('recipe-planner.save-recipe-error', 'Failed to add recipe to queue'),
        description: `${t('recipe-planner.save-recipe-error-msg', 'Something went wrong. Error code')}: ${
          error.response?.status
        }`,
        duration: 0
      })
    }
  })
  const { Text } = Typography
  const dimmed = (input, tonnesUsedByThisRecipe) =>
    tonnesUsedByThisRecipe === 0 ? <Text type="secondary">{input}</Text> : input
  function createDataSource(data) {
    let dataSource = []

    if (data === undefined) {
      return dataSource
    }

    const sortedFullSlices = data.fullSlices
      .sort((a, b) => sortDateDesc(a.pilingShift.startDate, b.pilingShift.startDate))
      .sort((a, b) => sortByShiftDesc(a.pilingShift, b.pilingShift))

    const slices = [data.firstTonFixSlice, ...sortedFullSlices, data.lastPartialSlice].filter((x) => x)

    dataSource.push({
      key: data.stockpileId,
      date: t('common.total', 'Total'),
      ...findElementsAndGrades(data.pileAverageGrades),
      tonnes: formatTonnes(data.pileTonnes),
      areas: joinAreaCodesIntoString(data.pileMiningAreas)
    })

    slices.forEach((s) => {
      dataSource.push({
        inRecipe: Boolean(s.tonnesUsedByThisRecipe),
        key: idByShift(s.pilingShift),
        date: dayjs(s.pilingShift.startDate).format('DD.MM.YYYY'),
        shift: capitalize(pickShiftType(s.pilingShift)),
        ...findElementsAndGrades(s.averageGrades),
        areas: joinAreaCodesIntoString(s.miningAreas),
        assayed: s.assayedPercent,
        tonnes: s.usedTonnes || s.tonnes,
        tonnesUsedByThisRecipe: s.tonnesUsedByThisRecipe,
        sliceType: s.sliceType
      })
    })

    return dataSource
  }
  const columns = [
    {
      dataIndex: 'inRecipe',
      key: 'inRecipe',
      width: '6px',
      render: (boolean, record) => (record.date === 'Total' ? null : <Badge status={boolean ? 'success' : 'error'} />)
    },
    {
      title: null,
      dataIndex: 'sliceType',
      key: 'sliceType',
      minWidth: 0,
      render: (value) => renderSliceType(value, t)
    },
    {
      title: t('common.date', 'Date'),
      dataIndex: 'date',
      key: 'date',
      width: '150px',
      render: (date, { tonnesUsedByThisRecipe }) =>
        date === 'Total' ? <strong>{date}</strong> : dimmed(date, tonnesUsedByThisRecipe)
    },
    {
      title: t('common.shift', 'Shift'),
      dataIndex: 'shift',
      key: 'shift',
      width: '110px',
      render: (shift, { tonnesUsedByThisRecipe }) => dimmed(shift, tonnesUsedByThisRecipe)
    },
    {
      title: 'Au',
      dataIndex: 'au',
      key: 'au',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) =>
        grade ? dimmed(formatTwoDecimals(grade), tonnesUsedByThisRecipe) : null
    },
    {
      title: 'S',
      dataIndex: 's',
      key: 's',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) =>
        grade ? dimmed(formatTwoDecimals(grade), tonnesUsedByThisRecipe) : null
    },
    {
      title: 'As',
      dataIndex: 'as',
      key: 'as',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) =>
        grade ? dimmed(formatTwoDecimals(grade), tonnesUsedByThisRecipe) : null
    },
    {
      title: 'Ctoc',
      dataIndex: 'ctoc',
      key: 'ctoc',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) =>
        grade ? dimmed(formatTwoDecimals(grade), tonnesUsedByThisRecipe) : null
    },
    {
      title: 'Ccarb',
      dataIndex: 'ccarb',
      key: 'ccarb',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) =>
        grade ? dimmed(formatTwoDecimals(grade), tonnesUsedByThisRecipe) : null
    },
    {
      title: 'Ag',
      dataIndex: 'ag',
      key: 'ag',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) =>
        grade ? dimmed(formatTwoDecimals(grade), tonnesUsedByThisRecipe) : null
    },
    {
      title: 'Sb',
      dataIndex: 'sb',
      key: 'sb',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) =>
        grade ? dimmed(formatTwoDecimals(grade), tonnesUsedByThisRecipe) : null
    },
    {
      title: 'RQD',
      dataIndex: 'rqd',
      key: 'rqd',
      align: 'right',
      width: '90px',
      render: (grade, { tonnesUsedByThisRecipe }) => (grade ? dimmed(Math.round(grade), tonnesUsedByThisRecipe) : null)
    },
    {
      title: t('common.areas', 'Areas'),
      dataIndex: 'areas',
      key: 'areas',
      align: 'right',
      width: '250px',
      render: (areas, { tonnesUsedByThisRecipe }) => dimmed(areas, tonnesUsedByThisRecipe)
    },
    {
      title: t('common.assayed', 'Assayed'),
      dataIndex: 'assayed',
      key: 'assayed',
      align: 'right',
      render: (percent, { tonnesUsedByThisRecipe }) =>
        typeof percent === 'number' ? dimmed(`${Math.round(percent)} %`, tonnesUsedByThisRecipe) : null
    },
    {
      title: t('common.tons', 'Tons'),
      dataIndex: 'tonnes',
      key: 'tonnes',
      align: 'right',
      render: (tons, record) => {
        if (record.tonnesUsedByThisRecipe === 0) {
          return <Text type="secondary">{formatTonnes(tons)}</Text>
        }

        if (!record.tonnesUsedByThisRecipe) {
          return formatTonnes(tons)
        }

        if (Math.round(tons) === Math.round(record.tonnesUsedByThisRecipe)) {
          return dimmed(formatTonnes(tons), record)
        }

        return (
          <>
            <Text type="secondary">{formatTonnes(tons)}</Text> / {formatTonnes(record.tonnesUsedByThisRecipe)}
          </>
        )
      }
    }
  ]
  const toggleConfirmationModal = () => dispatch({ type: actionTypes.TOGGLE_RECIPE_CONFIRMATION_MODAL })
  const toggleEditTonnesModal = () => dispatch({ type: actionTypes.TOGGLE_EDIT_TONNES_MODAL })
  const handleTonnesInput = (value) => dispatch({ type: actionTypes.SET_CAPACITY, payload: value })
  const addRecipeToQueue = () => {
    const initialRecipeData = {
      ...renameProp('recipeSummaryStockpileDto', 'stockpiles', data),
      ...newRecipeData
    }

    const recipeData = filter(
      (x) => x !== null,
      pick(
        [
          'customStartTime',
          'totalHours',
          'totalTons',
          'author',
          'stockpiles',
          'capacity',
          'elementGradeRanges',
          'areas'
        ],
        initialRecipeData
      )
    )
    postRecipe.mutate(recipeData)
  }

  useEffect(() => {
    const totalUsageRatios = recipeData.pilesAndSlices.reduce((acc, cur) => acc + cur.usageRatio, 0)
    if (totalUsageRatios === 100) {
      refetch()
    }
  }, [millCapacity, recipeData, refetch])

  useEffect(() => {
    if (recalculating) {
      toggleConfirmationModal()
    }
    if (!recalculating) {
      refetch()
    }
  }, [recalculating, refetch])

  useEffect(() => {
    if (isFetching && !isLoading) {
      message.loading({
        content: t('recipe-planner.recalculating-new-recipe', 'Recalculating new recipe'),
        duration: 0,
        key: 'recalc-recipe-msg'
      })
    }
    if (!isFetching) {
      message.destroy('recalc-recipe-msg')
    }
  }, [isFetching, isLoading, t])

  useMount(() => {
    if (!recalculating) {
      refetch()
    }
  })

  useUnmount(() => {
    message.destroy('recalc-recipe-msg')
  })

  return (
    <div className={styles.recipePlanner}>
      <div className={styles.stockpiles}>
        <div className={styles.stockpilesHeader}>
          <Title underlined>{t('header.recipe-planner', 'Recipe planner')}</Title>
          {!state.viewMode ? (
            <Button size="large" onClick={() => history.push('/slice-selector?showSelectedSlices=true')}>
              <EditOutlined /> {t('slice-selector.title', 'Slice selector')}
            </Button>
          ) : null}
        </div>
        <div className={styles.slices}>
          {recalculating ? (
            <div className={styles.recalculating}>
              <Trans
                i18nKey="recipe-planner.recalculating-inventory-desc"
                defaults="Recipe planner is disabled while inventory is recalculating. This is done by {{time}} approx."
                values={{ time: dayjs().minute(RECALCULATION_END_MINUTE).format('H.mm') }}
              />
            </div>
          ) : isLoading ? (
            'Loading stockpiles and slices'
          ) : isError ? (
            'Error'
          ) : data?.recipeSummaryStockpileDto?.length > 0 ? (
            data.recipeSummaryStockpileDto.map((i) => {
              const { oreLeftInHours } = i
              return (
                <div className={styles.stockpile} key={i.stockpileId}>
                  <Table
                    size="small"
                    title={() => (
                      <Row align="middle" gutter={32} style={{ fontSize: '16px' }}>
                        <Col flex="none" style={{ borderRight: '1px solid #535353' }}>
                          <span className={styles.title}>{i.title}</span>
                        </Col>
                        <Col flex="none">
                          <Trans
                            i18nKey="recipe-queue.ore-left-hours"
                            defaults="Ore left <bold>{{count}} hours</bold>"
                            values={{ count: Math.round(oreLeftInHours) }}
                            components={{ bold: <strong /> }}
                          />
                        </Col>
                        <Col flex="auto"></Col>
                        <Col flex="none">
                          {t('recipe-planner.initial-calculated', 'Initial calculated')}{' '}
                          {initialPercentage.find((s) => s.stockpileId === i.stockpileId)?.initialPercentage} %
                        </Col>
                        <Col flex="none">
                          <PercentageSelector stockpileId={i.stockpileId} disabled={false} />
                        </Col>
                      </Row>
                    )}
                    columns={columns}
                    dataSource={createDataSource(i)}
                    pagination={false}
                    style={{ paddingBottom: '32px' }}
                  />
                </div>
              )
            })
          ) : null}
        </div>
      </div>
      <div className={styles.summary}>
        {data ? <Summary grades={data.elementGradeRanges} areas={data.areas} /> : null}
        <Divider />
        <Capacity
          toggleEditTonnesModal={toggleEditTonnesModal}
          viewMode={state.viewMode}
          totalHours={data?.totalHours}
          totalTons={data?.totalTons}
        />
        <div className={styles.actionButtons}>
          <Space size="large">
            <Popconfirm
              title={t('common.are-you-sure', 'Are you sure?')}
              placement="top"
              trigger="click"
              onConfirm={() => history.push('/recipe-queue?cancel=true')}
              overlayStyle={{ whiteSpace: 'nowrap' }}
            >
              <Button size="large">{t('common.cancel', 'Cancel')}</Button>
            </Popconfirm>
            <Button
              type="primary"
              size="large"
              onClick={() => dispatch({ type: actionTypes.TOGGLE_RECIPE_CONFIRMATION_MODAL })}
              disabled={balance !== 100 || isFetching || recalculating}
            >
              {t('recipe-planner.add-to-queue', 'Add to queue')}
            </Button>
          </Space>
        </div>
      </div>
      <EditTonnesModal
        toggleModal={toggleEditTonnesModal}
        visible={state.showEditTonnesModal}
        currentCapacity={millCapacity}
        handleTonnesInput={handleTonnesInput}
      />
      <RecipeConfirmationModal
        toggleModal={toggleConfirmationModal}
        visible={state.showConfirmationModal}
        onConfirm={addRecipeToQueue}
        recipeStartTime={recipeStartTime}
        totalHours={data?.totalHours}
        isLoading={postRecipe.isLoading}
        recalculating={recalculating}
      />
    </div>
  )
}

function renderSliceType(value, t) {
  const obj = { children: value, props: {} }

  if (value === 'ghost') {
    obj.children = <Tag>{'Ghost'}</Tag>
    return obj
  }
}
