import { useState, useMemo, useEffect } from 'react'
import { useSourceLocations } from './useSourceLocations'
import { useStockpiles } from './useStockpiles'
import { filterStockpiles } from 'utils/utils'

export function useAllSourceLocations() {
  const [allSourceLocations, setAllSourceLocations] = useState([])

  const stockpiles = useStockpiles()

  const lgpLocations = useMemo(
    () =>
      stockpiles?.filter(filterStockpiles('LGP')).map((sp) => ({
        pileId: sp.pileId,
        name: sp.title,
        elements: sp.averageGrades,
        miningAreas: [{ areaCode: 'LGP', portion: 100 }],
        assayedPercent: null
      })) || [],
    [stockpiles]
  )

  const { sourceLocations } = useSourceLocations()

  useEffect(() => {
    setAllSourceLocations([...lgpLocations, ...sourceLocations])
  }, [lgpLocations, sourceLocations, setAllSourceLocations])

  return { allSourceLocations }
}
