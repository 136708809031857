import { useState, useEffect } from 'react'
import { Modal, Form, Select, DatePicker, Row, Col, Input, InputNumber, Button, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { addSliceDataAtom, addSliceGhostBatchesAtom, addSliceExistingBatchesAtom } from 'atoms'
import { useAllSourceLocations } from 'hooks/useAllSourceLocations'
import { useAddSliceSlice } from 'hooks/useAddSliceSlice'
import { useElementsGradeValueLimits } from 'hooks/useElementsGradeValueLimits'
import { AddSliceTable } from './add-slice-table'
import { getRecipeStockpile, getBatches, postSlice } from 'api/recipePlannerApi'
import { useAddSliceTableData } from 'hooks/useAddSliceTableData'
import { sortGrades } from 'utils/utils'
import { elementsOrder } from '../../constants'

const DATE_FORMAT = 'DD.MM.YYYY'
const VALID_VALUE = { validateStatus: 'success' }

export function AddSliceModal() {
  const { t } = useTranslation()

  const [addSliceData, setAddSliceData] = useRecoilState(addSliceDataAtom)
  const setAddSliceExistingBatches = useSetRecoilState(addSliceExistingBatchesAtom)
  const [addSliceGhostBatches, setAddSliceGhostBatches] = useRecoilState(addSliceGhostBatchesAtom)
  const resetAddSliceExistingBatches = useResetRecoilState(addSliceExistingBatchesAtom)
  const resetAddSliceGhostBatches = useResetRecoilState(addSliceGhostBatchesAtom)
  const { allSourceLocations } = useAllSourceLocations()
  const { pileName, slice } = useAddSliceSlice(addSliceData?.pileId, addSliceData?.pileName)
  const { batches, total } = useAddSliceTableData()

  const [isSaving, setIsSaving] = useState(false)
  const [isCancelConfirmVisible, setIsCancelConfirmVisible] = useState(false)

  const [uiState, setUiState] = useState()
  const [validationState, setValidationState] = useState({})

  const canAddBatch = !Object.keys(validationState)?.length
  const canSubmitSlice = !isSaving && !!addSliceGhostBatches?.length
  const confirmCancel = !!addSliceGhostBatches?.length

  const showUnknownAreaInput = !uiState?.source

  const gradeValueLimits = useElementsGradeValueLimits()

  useEffect(() => {
    if (addSliceData && pileName && slice && allSourceLocations?.length) {
      setUiState({
        pileName,
        sliceType: 'ghost',
        date: dayjs(slice?.pilingShift.startDate),
        shift: slice?.pilingShift.type,
        source: addSliceData?.sourceName,
        unknownArea: '',
        tonnes: 0,
        grades: initGrades(allSourceLocations?.find(({ name }) => name === addSliceData?.sourceName)?.elements ?? [])
      })
    }
  }, [addSliceData, pileName, slice, allSourceLocations])

  function handleDateChange(date) {
    setUiState({ ...uiState, date })
  }

  function handleShiftChange(shift) {
    setUiState({ ...uiState, shift })
  }

  function handleSourceChange(source) {
    setUiState({
      ...uiState,
      source,
      grades: initGrades(allSourceLocations?.find(({ name }) => name === source)?.elements ?? [])
    })
  }

  function handleUnknownAreaChange(e) {
    setUiState({ ...uiState, unknownArea: e.target.value })
  }

  function handleTonnesChange(tonnes) {
    setUiState({
      ...uiState,
      tonnes
    })
  }

  function handleGradeValueChange(symbol, value) {
    setUiState((state) => ({
      ...uiState,
      grades: state.grades.map((g) => (g.elementSymbol === symbol ? { ...g, gradeValue: value } : g))
    }))
  }

  async function handleAddBatchClick() {
    const batches = await getGhostBatches(uiState, allSourceLocations)
    batches.forEach((batch) => (batch.uiId = uuidv4()))
    setAddSliceGhostBatches((current) => [...current, ...batches])
  }

  function handleCancel() {
    setIsCancelConfirmVisible(false)
    setAddSliceData(null)
  }

  async function handleOk() {
    try {
      setIsSaving(true)
      await postSlice(addSliceData?.pileId, { batches })
    } catch (err) {
      console.log(err)
      alert(err)
    } finally {
      setIsSaving(false)
    }
    setAddSliceData(null)
  }

  function handleAfterClose() {
    resetAddSliceGhostBatches()
    resetAddSliceExistingBatches()
    setUiState(null)
  }

  useEffect(() => {
    const state = {}

    if (uiState) {
      const minDate = dayjs(slice?.pilingShift.startDate)
      const minShift = slice?.pilingShift.type

      if (!uiState.date || uiState.date.isBefore(minDate)) {
        state.date = { validateStatus: 'error' }
      }

      if (uiState.date && uiState.date.isSame(minDate) && uiState.shift < minShift) {
        state.shift = { validateStatus: 'error' }
      }

      if (!uiState.source && !parseUnknownAreaData(uiState.unknownArea)) {
        state.unknownArea = { validateStatus: 'error' }
      }

      if (uiState.tonnes <= 0) {
        state.tonnes = { validateStatus: 'error' }
      }

      elementsOrder.forEach((symbol) => {
        const value = uiState.grades.find((g) => g.elementSymbol === symbol)?.gradeValue ?? 0
        const limits = gradeValueLimits?.find((g) => g.elementSymbol === symbol)

        if (limits && (value < limits.gradeRangeMin || limits.gradeRangeMax < value)) {
          state[symbol] = { validateStatus: 'error' }
        }
      })
    }

    setValidationState(state)
  }, [uiState, slice, gradeValueLimits, setValidationState])

  useEffect(() => {
    resetAddSliceGhostBatches()
  }, [uiState?.date, uiState?.shift])

  useEffect(async () => {
    // IF missing required data
    if (!slice || !addSliceData?.pileId || !uiState?.date || !uiState?.shift) {
      resetAddSliceExistingBatches()
      resetAddSliceGhostBatches()
      return
    }

    // IF date+shift after the default.
    const defDate = dayjs(slice?.pilingShift.startDate)
    const defShift = slice?.pilingShift.type

    if (!uiState.date || !uiState.shift || !uiState.date.isSame(defDate) || uiState.shift !== defShift) {
      resetAddSliceExistingBatches()
      resetAddSliceGhostBatches()
      return
    }

    try {
      const { batches } = await getBatches(addSliceData?.pileId, {
        shiftDate: uiState?.date.format('YYYY-MM-DD'),
        shiftType: uiState?.shift
      })
      batches.forEach((b) => (b.grades = sortGrades(b.grades)))
      setAddSliceExistingBatches(batches)
    } catch (err) {
      console.log('TODO: error handling', err)
    }
  }, [slice, addSliceData?.pileId, uiState?.date, uiState?.shift])

  return (
    !!uiState && (
      <Modal
        centered
        title={`${t('slice-selector.add-slice-to')} ${uiState.pileName}`}
        width={1000}
        visible={!!addSliceData}
        maskClosable={false}
        onCancel={handleCancel}
        afterClose={handleAfterClose}
        footer={[
          <Popconfirm
            key="cancel"
            title={t('common.are-you-sure', 'Are You sure?')}
            okText={t('common.ok', 'OK')}
            cancelText={t('common.cancel', 'Cancel')}
            onConfirm={handleCancel}
            visible={isCancelConfirmVisible}
            onVisibleChange={(visible) => {
              if (!visible) {
                setIsCancelConfirmVisible(false)
                return
              }
              if (!confirmCancel) {
                handleCancel()
              } else {
                setIsCancelConfirmVisible(true)
              }
            }}
          >
            <Button type="default">{t('common.cancel', 'Cancel')}</Button>
          </Popconfirm>,
          <Button key="submit" type="primary" onClick={handleOk} loading={isSaving} disabled={!canSubmitSlice}>
            {`${t('slice-selector.add-slice-to')} ${uiState.pileName}`}
          </Button>
        ]}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Select slice type">
                <Select disabled={true} value="ghost">
                  <Select.Option value="ghost">Ghost slice</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Date" {...(validationState.date ?? VALID_VALUE)}>
                <DatePicker
                  format={DATE_FORMAT}
                  style={{ width: '100%' }}
                  allowClear={false}
                  value={uiState.date}
                  onChange={handleDateChange}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="Shift" {...(validationState.shift ?? VALID_VALUE)}>
                <Select value={uiState.shift} onChange={handleShiftChange}>
                  <Select.Option value="dayShift">Day</Select.Option>
                  <Select.Option value="nightShift">Night</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Ore source">
                <Select
                  placeholder="Select"
                  showSearch
                  allowClear={true}
                  value={uiState.source}
                  onChange={handleSourceChange}
                >
                  {allSourceLocations.map((sl) => (
                    <Select.Option key={sl.name}>{sl.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {showUnknownAreaInput && (
              <Col span={6}>
                <Form.Item label="Area" extra={"E.g. 'X25% Y75%'"} {...(validationState.unknownArea ?? VALID_VALUE)}>
                  <Input style={{ width: '100%' }} value={uiState.unknownArea} onChange={handleUnknownAreaChange} />
                </Form.Item>
              </Col>
            )}

            <Col span={4}>
              <Form.Item label="Amount (tonnes)" {...(validationState.tonnes ?? VALID_VALUE)}>
                <InputNumber
                  min={0}
                  precision={2}
                  style={{ width: '100%' }}
                  value={uiState.tonnes}
                  onChange={handleTonnesChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            {elementsOrder.map((symbol) => (
              <Col span={3} key={symbol}>
                <Form.Item label={symbol} {...(validationState[symbol] ?? VALID_VALUE)}>
                  <InputNumber
                    min={0}
                    step="0.01"
                    precision={2}
                    style={{ width: '100%' }}
                    value={uiState.grades.find((g) => g.elementSymbol === symbol)?.gradeValue ?? 0}
                    onChange={(value) => handleGradeValueChange(symbol, value)}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>

          <Row gutter={16}>
            <Col span={6} offset={18}>
              <Button type="primary" style={{ width: '100%' }} disabled={!canAddBatch} onClick={handleAddBatchClick}>
                Add batch to slice
              </Button>
            </Col>
          </Row>

          {
            <Row gutter={16}>
              <Col span={24}>
                <AddSliceTable batches={batches} total={total} />
              </Col>
            </Row>
          }
        </Form>
      </Modal>
    )
  )
}

function initGrades(grades) {
  const base = elementsOrder.map((symbol) => ({ elementSymbol: symbol, gradeValue: 0 }))
  return sortGrades(Object.assign(base, grades))
}

async function getGhostBatches(uiState, allSourceLocations) {
  if (uiState.source) {
    const sourceLocation = allSourceLocations.find((sl) => sl.name === uiState.source)

    let assayed = 0

    if (sourceLocation) {
      assayed = sourceLocation?.assayedPercent ?? 0

      if (sourceLocation.name.startsWith('LGP') && sourceLocation.pileId) {
        const rs = await getRecipeStockpile(sourceLocation.pileId, sourceLocation.name)
        assayed = rs?.slices?.[0].assayedPercent ?? 0
      }
    }

    return [
      {
        shift: {
          startDate: uiState.date.format('YYYY-MM-DD'),
          type: uiState.shift
        },
        tonnes: uiState.tonnes,
        source: sourceLocation ? [...sourceLocation.miningAreas] : null,
        grades: [...uiState.grades],
        type: 'ghost',
        assayed
      }
    ]
  } else if (uiState.unknownArea) {
    const entries = parseUnknownAreaData(uiState.unknownArea)
    if (!entries?.length) return []

    return entries.map((entry) => ({
      shift: {
        startDate: uiState.date.format('YYYY-MM-DD'),
        type: uiState.shift
      },
      tonnes: (entry.portion / 100) * uiState.tonnes,
      source: [{ ...entry }],
      grades: [...uiState.grades],
      type: 'ghost',
      assayed: 0
    }))
  }
}

function parseUnknownAreaData(value) {
  const regex = /\b[XYSRKUH]\d{1,3}(?:\.\d{0,1})?%/g

  if (!regex.test(value)) return null

  const matches = value.match(regex)

  if (!matches.length) return null

  const values = matches.map((m) => {
    const regex = /\b[XYSRKUH]\d{1,3}(?:\.\d{0,1})?%/
    const match = regex.exec(m)
    const areaCode = match[0][0]
    const portion = +match[0].slice(1, -1)
    return { areaCode, portion }
  })

  const s = values.reduce((sum, current) => sum + current.portion, 0)

  if (s !== 100) return null

  return values
}
