import { fetchRecipeQueue } from 'api/recipePlannerApi'
import { recalculatingAtom } from 'atoms'
import clsx from 'clsx'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import styles from '../styles/SmallRecipeQueue.module.css'
import { Loading } from './common/loading'
import { RecipeTrendSmall } from './common/trend-small'
import { RecipeQueueDashboardItem } from './recipe-queue/recipe-queue-dashboard-item'

export function SmallRecipeQueue() {
  const { trend } = useFeatureFlags()
  const { t } = useTranslation()
  const recalculating = useRecoilValue(recalculatingAtom)
  const { isLoading, isError, data: recipeQueue = [], error, refetch } = useQuery('recipe-queue', fetchRecipeQueue, {
    enabled: !recalculating,
    refetchInterval: Number(process.env.REACT_APP_POLLING_INTERVAL)
  })
  return (
    <div className={styles.recipeQueue}>
      <div className={clsx(styles.trend, trend ? styles.show : styles.hide)}>
        <RecipeTrendSmall />
      </div>
      {isLoading ? (
        <div className={styles.loading}>
          <Loading text={t('recipe-queue.loading-recipe-queue', 'Loading recipe queue')} />
        </div>
      ) : (
        <div className={styles.listOfRecipes}>
          <div className={styles.recipeQueueTitle}>{t('recipe-queue.title', 'Recipe queue')}</div>
          {recipeQueue
            .filter((r) => r.recipeStatus !== 'completed')
            .map(({ recipeId, endTime, startTime, recipeStatus, oreLeftInHours }, idx) => (
              <RecipeQueueDashboardItem
                key={recipeId}
                startTime={startTime}
                endTime={endTime}
                recipeStatus={recipeStatus}
                recipeId={recipeId}
                latest={recipeQueue.length === idx + 1}
                oreLeftInHours={oreLeftInHours}
              />
            ))}
        </div>
      )}
    </div>
  )
}
