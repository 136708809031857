import { useState, useEffect } from 'react'
import { getElementsGradeValueLimits } from 'api/recipePlannerApi'

export function useElementsGradeValueLimits() {
  const [limits, setLimits] = useState(null)

  useEffect(async () => {
    try {
      const data = await getElementsGradeValueLimits()
      setLimits(data.limits)
    } catch (err) {
      console.log(err)
    }
  }, [setLimits])

  return limits
}
