import { accessRightsAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

const accessRightsTypes = {
  STOCKPILE_READ: 'STOCKPILE_READ',
  STOCKPILE_WRITE: 'STOCKPILE_WRITE',
  STOCKPILE_CORRECTIONS: 'STOCKPILE_CORRECTIONS',
  RECIPE_MODIFICATIONS: 'RECIPE_MODIFICATIONS',
  RECIPE_PERCENTAGE_READ: 'RECIPE_PERCENTAGE_READ',
  RECIPE_TREND_READ: 'RECIPE_TREND_READ',
  RECIPE_GRADE_READ: 'RECIPE_GRADE_READ',
  FULL_ACCESS: 'FULL_ACCESS',
  INPUT_CRUSH_TONS: 'INPUT_CRUSH_TONS'
}

export function useAccessRights() {
  const accessRights = useRecoilValue(accessRightsAtom)
  const stockpileRead = accessRights.includes(accessRightsTypes.STOCKPILE_READ)
  const stockpileWrite = accessRights.includes(accessRightsTypes.STOCKPILE_WRITE)
  const stockpileCorrections = accessRights.includes(accessRightsTypes.STOCKPILE_CORRECTIONS)
  const recipeModifications = accessRights.includes(accessRightsTypes.RECIPE_MODIFICATIONS)
  const recipeTrend = accessRights.includes(accessRightsTypes.RECIPE_TREND_READ)
  const recipePercentage = accessRights.includes(accessRightsTypes.RECIPE_PERCENTAGE_READ)
  const recipeGrade = accessRights.includes(accessRightsTypes.RECIPE_GRADE_READ)

  return {
    stockpileRead,
    stockpileWrite,
    stockpileCorrections,
    recipeModifications,
    recipeTrend,
    recipePercentage,
    recipeGrade
  }
}
