// @ts-check
import { Skeleton } from 'antd'
import { fetchStockpileSummaries, fetchStockpileSummariesNoGrades } from 'api/stockpileInventoryApi'
import { recalculatingAtom, stockpilesFilterAtom, stockpilesListAtom } from 'atoms'
import { StockpileFilter } from 'components/common/stockpile-filter'
import { NetworkError } from 'components/NetworkError'
import { SmallRecipeQueue } from 'components/SmallRecipeQueue'
import { StockpileSummary } from 'components/StockpileSummary'
import { StockpileSummaryCard } from 'components/StockpileSummaryCard'
import { activeStockpiles, RECALCULATION_END_MINUTE } from 'constants.js'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useRecoilState, useRecoilValue } from 'recoil'
import { filteredStockpileSummariesSelector } from 'selectors'
import { filterRomStockpiles, filterStockpiles, sortByTitle, sortStockpilesByPileType } from 'utils/utils'
import styles from '../styles/Dashboard.module.css'

export function Dashboard() {
  const { stockpileRead, recipeGrade } = useAccessRights()
  const { t } = useTranslation()
  const recalculating = useRecoilValue(recalculatingAtom)
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['stockpile-summaries', process.env.REACT_APP_API_MINEID],
    recipeGrade ? fetchStockpileSummaries : fetchStockpileSummariesNoGrades,
    {
      refetchInterval: Number(process.env.REACT_APP_POLLING_INTERVAL),
      refetchIntervalInBackground: true,
      enabled: stockpileRead && !recalculating
    }
  )
  const { recipePlanning } = useFeatureFlags()
  const filter = useRecoilValue(stockpilesFilterAtom)
  const [stockpilesList, setStockpilesList] = useRecoilState(stockpilesListAtom)
  const filteredStockpileSummaries = useRecoilValue(filteredStockpileSummariesSelector)
  useEffect(() => {
    if (data) {
      const stockpileList = data
        .filter(filterRomStockpiles(activeStockpiles))
        .sort(sortStockpilesByPileType)
        .sort(sortByTitle)
      setStockpilesList(stockpileList)
    }
  }, [data, setStockpilesList])

  useEffect(() => {
    if (!recalculating) {
      refetch()
    }
  }, [recalculating, refetch])

  return (
    <div className={styles.dashboard}>
      <div className={styles.stockpilesContainer}>
        {data ? <StockpileFilter /> : null}
        <div className={styles.stockpiles}>
          {recalculating ? (
            <div className={styles.recalculatingWarning}>
              {t('dashboard.recalculating-inventory-warning', 'Recalculating. Data shown here might be old.')}
            </div>
          ) : null}
          {recalculating && stockpilesList.length === 0 ? (
            <div className={styles.recalculating}>
              <Trans
                i18nKey="dashboard.recalculating-inventory-desc"
                defaults="Dashboard is disabled while inventory is recalculating and no data was found in memory. This is done by {{time}} approx."
                values={{ time: dayjs().minute(RECALCULATION_END_MINUTE).format('H.mm') }}
              />
            </div>
          ) : isLoading ? (
            <div style={{ paddingTop: '32px' }}>
              <Skeleton active paragraph={{ rows: 5 }} />
            </div>
          ) : isError ? (
            <NetworkError refetch={refetch} errorStatusCode={error.response?.status} />
          ) : stockpilesList.length > 0 ? (
            <>
              <StockpileSummary summary={stockpilesList.filter(filterStockpiles(`TOTAL${filter}`))[0]} />
              {filteredStockpileSummaries.map((stockpile) => (
                <StockpileSummaryCard key={stockpile.pileId} stockpile={stockpile} />
              ))}
            </>
          ) : null}
        </div>
      </div>
      {recipePlanning ? <SmallRecipeQueue /> : null}
    </div>
  )
}
