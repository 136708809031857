// @ts-check
import { CaretRightFilled, CaretDownFilled, UnorderedListOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Skeleton, Space, Input } from 'antd'
import { fetchStockpileById } from 'api/stockpileInventoryApi'
import {
  recalculatingAtom,
  recipeQueueAtom,
  recipeStartTimeAtom,
  selectedSlicesForRecipeAtom,
  selectedStockpilesForRecipeAtom,
  selectedLgpStockpilesAtom,
  selectedSourceLocationsAtom,
  lgpAndMinesSearchTermAtom,
  sliceSelectorExpandedAtom,
  addSliceDataAtom
} from 'atoms'
import clsx from 'clsx'
import { NetworkError } from 'components/NetworkError'
import { SliceSelectorTitle } from 'components/slice-selector/slice-selector-title'
import {
  SelectorItem,
  LgpSelectorItem,
  SourceLocationSelectorItem
} from 'components/slice-selector/stockpile-selector-item'
import { TotalSelectedSlices } from 'components/slice-selector/total-selected-slices'
import { Stockpile } from 'components/Stockpile'
import { CommentModal } from 'components/stockpile-details/comment-modal'
import { AddSliceModal } from '../add-slice-modal/add-slice-modal'
import dayjs from 'dayjs'
import { useClearSelections } from 'hooks/useClearSelections'
import { useRecipeQueue } from 'hooks/useRecipeQueue'
import { useStockpiles } from 'hooks/useStockpiles'
import { useSourceLocations } from 'hooks/useSourceLocations'
import { includes } from 'rambda'
import { useCallback, useEffect, useReducer } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { sliceSelectorActionTypes as actionTypes, sliceSelectorReducer as reducer } from 'reducers'
import {
  listOfAllSelectedSlicesSelector,
  selectedStockpilesAndTotalTonsSelector,
  selectedSlicesValidationSelector
} from 'selectors'
import { startOfNextShift } from 'utils/date-utils'
import { createSlicesWithBatchesForDetails, filterStockpiles, findElementsAndGrades, formatTonnes } from 'utils/utils'
import { RECALCULATION_END_MINUTE } from '../../constants'
import { SelectableSlicesStockpile } from './selectable-slices-stockpile'
import { SourceLocationTable } from './source-location-table'
import styles from './slice-selector.module.css'

export const initialState = {
  timeSwitch: false,
  date: startOfNextShift(),
  time: startOfNextShift(),
  selectorView: true,
  stockpileToViewId: '',
  slices: [],
  comment: { content: '', corrector: '', readOnly: true, editComment: false, modalVisible: false },
  recalculating: false
}

/** @param {import('components').SliceSelectorPropsType} props */
export function SliceSelector(props) {
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { t } = useTranslation()
  const stockpiles = useStockpiles()
  const { sourceLocations } = useSourceLocations()
  const recalculating = useRecoilValue(recalculatingAtom)
  const recipeStartTime = useRecoilValue(recipeStartTimeAtom)
  const [selectedStockpilesForRecipe, setSelectedStockpilesForRecipe] = useRecoilState(selectedStockpilesForRecipeAtom)
  const [selectedLgpStockpiles, setSelectedLgpStockpiles] = useRecoilState(selectedLgpStockpilesAtom)
  const [selectedSourceLocations, setSelectedSourceLocations] = useRecoilState(selectedSourceLocationsAtom)
  const selectedSlicesValidation = useRecoilValue(selectedSlicesValidationSelector)
  const addSliceData = useRecoilValue(addSliceDataAtom)
  const [lgpAndMinesSearchTerm, setLgpAndMinesSearchTerm] = useRecoilState(lgpAndMinesSearchTermAtom)
  const [selectedSlicesForRecipe, setSelectedSlicesForRecipe] = useRecoilState(selectedSlicesForRecipeAtom)
  const totals = useRecoilValue(selectedStockpilesAndTotalTonsSelector)
  const listOfAllSelectedSlices = useRecoilValue(listOfAllSelectedSlicesSelector)
  const setRecipeQueue = useSetRecoilState(recipeQueueAtom)
  const [sliceSelectorExpanded, setSliceSelectorExpanded] = useRecoilState(sliceSelectorExpandedAtom)
  const clearSelections = useClearSelections()
  const queryClient = useQueryClient()
  const { isLoading: isLoadingQueue, isError: isErrorQueue, queue, error: errorQueue } = useRecipeQueue()
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['stockpiles', state.stockpileToViewId],
    fetchStockpileById
  )
  const toggleCommentModal = useCallback(
    ({ content = '', corrector = '', readOnly = true, editComment = false, modalVisible = false }) =>
      dispatch({ type: actionTypes.SET_COMMENT, payload: { content, corrector, readOnly, editComment, modalVisible } }),
    []
  )

  const canOk = !Object.values(selectedSlicesValidation ?? {}).some((s) => s === false)

  /** @param {{ id:string, title: string }} stockpile */
  function handleStockpileClick(stockpile) {
    if (state.stockpileToViewId === stockpile.id) {
      refetch()
    }

    if (!state.selectorView) {
      dispatch({ type: actionTypes.SET_STOCKPILE_TO_VIEW_ID, payload: stockpile.id })
      return
    }

    if (isSelected(stockpile)) {
      setSelectedSlicesForRecipe(selectedSlicesForRecipe.filter((s) => s.stockpileId !== stockpile.id))
      setSelectedStockpilesForRecipe((recipes) => recipes.filter((s) => s.id !== stockpile.id))
      return
    }
    setSelectedStockpilesForRecipe((recipes) => [...recipes, stockpile])
  }

  /** @param {{ id:string, title: string }} stockpile */
  function handleLgpStockpileClick(stockpile) {
    if (state.stockpileToViewId === stockpile.id) {
      refetch()
    }

    if (!state.selectorView) {
      dispatch({ type: actionTypes.SET_STOCKPILE_TO_VIEW_ID, payload: stockpile.id })
      return
    }

    if (isLgpSelected(stockpile)) {
      // setSelectedSlicesForRecipe(selectedSlicesForRecipe.filter((s) => s.stockpileId !== stockpile.id))
      setSelectedLgpStockpiles((sp) => sp.filter((s) => s.id !== stockpile.id))
      return
    }
    setSelectedLgpStockpiles((sp) => [...sp, stockpile])
  }

  function handleSourceLocationClick(sourceLocation) {
    if (isMineSelected(sourceLocation)) {
      // setSelectedSlicesForRecipe(selectedSlicesForRecipe.filter((s) => s.stockpileId !== stockpile.id))
      setSelectedSourceLocations((sl) => sl.filter((s) => s.name !== sourceLocation.name))
      return
    }
    setSelectedSourceLocations((sl) => [...sl, sourceLocation])

    // TODO
  }

  const isSelected = (stockpile) => includes(stockpile, selectedStockpilesForRecipe)
  const isLgpSelected = (stockpile) => includes(stockpile, selectedLgpStockpiles)
  const isMineSelected = (sourceLocation) => selectedSourceLocations.some((ssl) => ssl.name === sourceLocation.name)

  const searchMinesFilter = (sourceLocation) =>
    !lgpAndMinesSearchTerm || sourceLocation.name.toLowerCase().startsWith(lgpAndMinesSearchTerm.toLowerCase())

  useEffect(() => {
    if (history.location.search === '?showSelectedSlices=true') {
      dispatch({ type: actionTypes.TOGGLE_SELECTOR_VIEW, payload: true })
    }
  }, [history.location.search])

  useEffect(() => {
    if (data) {
      const slicesWithBatches = createSlicesWithBatchesForDetails(data.slices, toggleCommentModal)
      const slicesWithTotal =
        slicesWithBatches.length > 0
          ? [
              {
                key: data.pileId,
                date: 'total',
                ...findElementsAndGrades(data.summary.averageGrades),
                tonnes: data.summary.tonnes,
                comment: !!data.comment
                  ? { onClick: () => toggleCommentModal({ content: data.comment, readOnly: true, modalVisible: true }) }
                  : null
              },
              ...slicesWithBatches
            ]
          : []
      dispatch({ type: actionTypes.SET_SLICES, payload: slicesWithTotal })
    }
  }, [data, toggleCommentModal])

  useEffect(() => {
    if (recalculating) {
      clearSelections()
    }
    if (!recalculating) {
      queryClient.invalidateQueries('recipe-stockpiles')
    }
  }, [recalculating, queryClient, clearSelections])

  useEffect(() => {
    if (queue) {
      setRecipeQueue(queue)
    }
  }, [queue, setRecipeQueue])

  useEffect(() => {
    if (!addSliceData) {
      queryClient.invalidateQueries('recipe-stockpiles')
    }
  }, [addSliceData, queryClient])

  return (
    <>
      <div className={styles.sliceSelector}>
        <SliceSelectorTitle isLoadingQueue={isLoadingQueue} />
        <div className={styles.container}>
          <div className={styles.selector}>
            <div
              className={clsx(styles.selectorView, state.selectorView && styles.selectorViewSelected)}
              onClick={() => dispatch({ type: actionTypes.TOGGLE_SELECTOR_VIEW })}
            >
              <UnorderedListOutlined /> {t('slice-selector.selector-view', 'Selector view')}
            </div>
            {state.selectorView && (
              <Input
                size="large"
                placeholder={t('slice-selector.search-placeholder')}
                suffix={<SearchOutlined />}
                style={{ marginBottom: '1rem' }}
                value={lgpAndMinesSearchTerm}
                onChange={(e) => setLgpAndMinesSearchTerm(e.target.value)}
              />
            )}
            <div className={styles.selectorTitle}>{t('common.stockpiles', 'Stockpiles')}</div>
            {stockpiles.length > 0
              ? stockpiles
                  .filter(filterStockpiles('ROM'))
                  .map((stockpile) => (
                    <SelectorItem
                      key={stockpile.pileId}
                      stockpile={stockpile}
                      checked={isSelected({ id: stockpile.pileId, title: stockpile.title })}
                      onClick={handleStockpileClick}
                    />
                  ))
              : null}
            {state.selectorView && (
              <>
                <Button
                  type="link"
                  size="large"
                  className={styles.selectorTitleExpandable}
                  icon={sliceSelectorExpanded.lgp ? <CaretDownFilled /> : <CaretRightFilled />}
                  onClick={() => setSliceSelectorExpanded((v) => ({ ...v, lgp: !v.lgp }))}
                >
                  {t('common.lgps', 'LGPs')}
                </Button>
                {sliceSelectorExpanded.lgp && stockpiles.length > 0
                  ? stockpiles
                      .filter(filterStockpiles('LGP'))
                      .map((stockpile) => (
                        <LgpSelectorItem
                          key={stockpile.pileId}
                          stockpile={stockpile}
                          checked={isLgpSelected({ id: stockpile.pileId, title: stockpile.title })}
                          onClick={handleLgpStockpileClick}
                        />
                      ))
                  : null}
                <Button
                  type="link"
                  size="large"
                  className={styles.selectorTitleExpandable}
                  icon={sliceSelectorExpanded.mines ? <CaretDownFilled /> : <CaretRightFilled />}
                  onClick={() => setSliceSelectorExpanded((v) => ({ ...v, mines: !v.mines }))}
                >
                  {t('common.mines', 'Mines')}
                </Button>
                {sliceSelectorExpanded.mines && sourceLocations.length > 0
                  ? sourceLocations
                      .filter(searchMinesFilter)
                      .slice(0, 20)
                      .map((sourceLocation) => (
                        <SourceLocationSelectorItem
                          key={sourceLocation.name}
                          sourceLocation={sourceLocation}
                          checked={isMineSelected(sourceLocation)}
                          onClick={handleSourceLocationClick}
                        />
                      ))
                  : null}
              </>
            )}
          </div>
          <div className={styles.details}>
            {state.selectorView ? (
              <div className={styles.multiView}>
                {totals.length > 0 && !recalculating ? <TotalSelectedSlices totals={totals} /> : null}
                {recalculating ? (
                  <div className={styles.recalculating}>
                    <Trans
                      i18nKey="slice-selector.recalculating-inventory-desc"
                      defaults="Slice selector is disabled while inventory is recalculating. This is done by {{time}} approx."
                      values={{ time: dayjs().minute(RECALCULATION_END_MINUTE).format('H.mm') }}
                    />
                  </div>
                ) : (
                  <>
                    {selectedStockpilesForRecipe.map((stockpile) => (
                      <SelectableSlicesStockpile
                        key={stockpile.id}
                        stockpile={stockpile}
                        recipeStartTime={recipeStartTime}
                        recalculating={recalculating}
                      />
                    ))}
                    {selectedLgpStockpiles.map((stockpile) => (
                      <SelectableSlicesStockpile
                        key={stockpile.id}
                        stockpile={stockpile}
                        recipeStartTime={recipeStartTime}
                        recalculating={recalculating}
                        disableSelection={true}
                      />
                    ))}
                    {selectedSourceLocations.map((sourceLocation) => (
                      <SourceLocationTable
                        key={sourceLocation.name}
                        sourceLocation={sourceLocation}
                      ></SourceLocationTable>
                    ))}
                  </>
                )}
                {listOfAllSelectedSlices.length > 0 ? (
                  <div className={styles.footer}>
                    <Space>
                      <Popconfirm
                        title={t('common.are-you-sure', 'Are you sure?')}
                        placement="top"
                        trigger="click"
                        onConfirm={() => history.push('/recipe-queue?cancel=true')}
                        overlayStyle={{ whiteSpace: 'nowrap' }}
                      >
                        <Button size="large">{t('common.cancel', 'Cancel')}</Button>
                      </Popconfirm>
                      <Button type="primary" disabled={!canOk} onClick={() => history.push('/recipe-planner')}>
                        {t('common.ok', 'OK')}
                      </Button>
                    </Space>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                {isLoading ? (
                  <div style={{ paddingTop: '32px' }}>
                    <Skeleton active paragraph={{ rows: 5 }} />
                  </div>
                ) : isError ? (
                  <NetworkError refetch={refetch} errorStatusCode={error.response?.status} />
                ) : data ? (
                  <Stockpile
                    id={data.pileId}
                    title={data.summary.title}
                    stockpileState={data.summary.state}
                    editableState={data.summary.editableState}
                    slices={state.slices}
                    grades={data.summary.averageGrades}
                    tonnes={formatTonnes(data.summary.tonnes)}
                    onSelect={handleStockpileClick}
                    selected={isSelected({ id: data.pileId, title: data.summary.title })}
                    stateBarDisabled
                    noMenu
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <CommentModal
        visible={state.comment.modalVisible}
        comment={state.comment.content}
        readOnly={state.comment.readOnly}
        onOk={toggleCommentModal}
        onCancel={toggleCommentModal}
        toggleCommentModal={toggleCommentModal}
      />
      <AddSliceModal />
    </>
  )
}
