import { Typography } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import styles from '../styles/NetworkError.module.css'

/** @param {import('components').NetworkErrorPropsType} props */
export function NetworkError({ errorStatusCode, refetch }) {
  const { t } = useTranslation()
  const { Text } = Typography

  function notFound() {
    return (
      <>
        <Text>{t('error.404', "Sorry, We couldn't find the resource.")}</Text>
        <Text type="secondary">{t('error.error-code', 'Error code')}: 404</Text>
      </>
    )
  }

  function unauthorizedError() {
    return (
      <>
        <Text>{t('error.401', 'Unauthorized')}</Text>
        <Text type="secondary">{t('error.error-code', 'Error code')}: 401</Text>
      </>
    )
  }

  function commonError() {
    return (
      <>
        <Text>{t('error.something-went-wrong', 'Something went wrong')}!</Text>
        <Text type="secondary">{t('error.has-been-logged', 'And that "something" has been logged.')}</Text>
        <Text type="secondary">
          {t('error.error-code', 'Error code')}: {errorStatusCode}
        </Text>
      </>
    )
  }

  function internalServerError() {
    return (
      <>
        <Trans i18nKey="error.unable-to-load-data">
          <Text>Sorry,</Text>
          <Text>We were unable to fetch data from our services!</Text>
        </Trans>
        <p className={styles.waitAminute}>
          <Trans i18nKey="error.wait-a-minute">
            Wait a minute and try to
            <span className={styles.refetch} onClick={() => refetch()}>
              refetch the data
            </span>
            .
          </Trans>
        </p>
        <Text type="secondary">
          {t('error.error-code', 'Error code')}: {errorStatusCode}
        </Text>
      </>
    )
  }

  /** @param {import('components').NetworkErrorPropsType['errorStatusCode']} errorStatusCode */
  function renderError(errorStatusCode) {
    switch (errorStatusCode) {
      case 401:
        return unauthorizedError()

      case 404:
        return notFound()

      case 500:
        return internalServerError()

      default:
        return commonError()
    }
  }

  return <div className={styles.error}>{renderError(errorStatusCode)}</div>
}
