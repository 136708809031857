import { fetchSourceLocations } from 'api/recipePlannerApi'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export function useSourceLocations() {
  const [sourceLocations, setSourceLocations] = useState([])
  const { isLoading, isError, data, error } = useQuery('source-locations', fetchSourceLocations)

  useEffect(() => {
    if (data) {
      const sortedData = data.locations
        .map((l) => ({ ...l, miningAreas: [{ areaCode: l.name[0], portion: 100 }], assayedPercent: 0 }))
        .sort((a, b) => a.name.localeCompare(b.name))
      setSourceLocations(sortedData)
    }
  }, [data])

  return { isLoading, isError, sourceLocations, error }
}
