import { find, isEmpty } from 'rambda'

export const getAccessRights = (permissions) => (role) => {
  if (role === undefined || isEmpty(permissions)) {
    return []
  }

  const accessRights = find((i) => i.role === role, permissions)

  return accessRights ? accessRights.permissions : []
}
