// @ts-check
import { Col, Row, Typography } from 'antd'
import { displayGrades } from 'constants.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'styles/ElementsAndGrades.module.css'
import { formatTonnes, formatTwoDecimals, getContent, sortGrades } from 'utils/utils'

/** @param {import('components').ElementsAndGradesPropsType} props */
export function ElementsAndGrades(props) {
  const { t } = useTranslation()
  const { Text } = Typography

  return (
    <Row gutter={14} style={{ marginBottom: '16px' }}>
      {props.grades &&
        sortGrades(props.grades).map(({ gradeValue, elementSymbol }) =>
          displayGrades.includes(elementSymbol) ? (
            <React.Fragment key={elementSymbol}>
              <Col className={styles.element}>
                <Text>{elementSymbol}</Text>
                <Text type="secondary">{getContent(elementSymbol)}</Text>
              </Col>
              <Col flex="120px">
                <div className={styles.grade}>{formatTwoDecimals(gradeValue)}</div>
              </Col>
            </React.Fragment>
          ) : null
        )}
      <Col flex="auto" />
      <Col className={styles.element}>
        <Text>{t('common.total', 'Total')}</Text>
        <Text type="secondary">{t('common.tons', 'Tons')}</Text>
      </Col>
      <Col className={styles.grade} flex="160px">
        {formatTonnes(props.tonnes)}
      </Col>
    </Row>
  )
}
