import { fetchRecipeQueue } from 'api/recipePlannerApi'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export function useRecipeQueue() {
  const [queue, setQueue] = useState(null)
  const { isLoading, isError, data, error } = useQuery('recipe-queue', fetchRecipeQueue)

  useEffect(() => {
    if (data) {
      const sortedQueue = data.slice().sort((a, b) => a.recipeOrder - b.recipeOrder)
      setQueue(sortedQueue)
    }
  }, [data])

  return { isLoading, isError, queue, error }
}
