// @ts-check
import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { Affix, Col, Row, Space, Switch } from 'antd'
import { fetchRecipeQueue } from 'api/recipePlannerApi'
import { lastRecipeEndTimeAtom, recalculatingAtom, recipeQueueAtom } from 'atoms'
import { Loading } from 'components/common/loading'
import { Title } from 'components/common/title'
import { RecipeTrend } from 'components/common/trend'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { useClearSelections } from 'hooks/useClearSelections'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { last } from 'rambda'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { RECALCULATION_END_MINUTE } from '../constants'
import styles from '../styles/RecipeQueue.module.css'
import { NetworkError } from './NetworkError'
import { RecipeQueueItem } from './recipe-queue/recipe-queue-item'

export function RecipeQueue() {
  const [isTrendOpen, setIsTrendOpen] = useState(false)
  const [showCompleted, setShowCompleted] = useState(false)
  const { recipeTrend } = useAccessRights()
  const { trend } = useFeatureFlags()
  const { t } = useTranslation()
  const history = useHistory()
  const setRecipeQueue = useSetRecoilState(recipeQueueAtom)
  const setLastRecipeEndTime = useSetRecoilState(lastRecipeEndTimeAtom)
  const { recipeModifications } = useAccessRights()
  const clearSelections = useClearSelections()
  const recalculating = useRecoilValue(recalculatingAtom)
  const { isLoading, isError, data = [], error: recipesError, refetch } = useQuery('recipe-queue', fetchRecipeQueue, {
    enabled: !recalculating,
    refetchInterval: Number(process.env.REACT_APP_POLLING_INTERVAL),
    onSuccess: (data) => setRecipeQueue(data)
  })

  useEffect(() => {
    if (history.location.search === '?cancel=true') {
      clearSelections()
    }
  }, [history.location.search, clearSelections])

  useEffect(() => {
    if (data?.length > 0) {
      setLastRecipeEndTime(data)
      setLastRecipeEndTime(dayjs(last(data).endTime))
    }
  }, [data, setLastRecipeEndTime])

  useEffect(() => {
    if (!recalculating) {
      refetch()
    }
  }, [recalculating, refetch])

  return (
    <div className={styles.recipeQueue}>
      <div className={styles.queue}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title underlined>{t('recipe-queue.title')}</Title>
          </Col>
          <Col style={{ fontSize: '16px' }}>
            <Space size="middle">
              <div className={styles.switchLabel} onClick={() => setShowCompleted((cur) => !cur)}>
                {t('recipe-queue.show-completed', 'Show completed recipes')}
              </div>
              <Switch onClick={() => setShowCompleted((cur) => !cur)} checked={showCompleted} />
            </Space>
          </Col>
        </Row>
        <div className={styles.recipes}>
          {recalculating ? (
            <div style={{ padding: '16px', fontSize: '16px', fontWeight: 800, color: 'red' }}>
              <Loading text={t('recipe-queue.recalculating-inventory-warning', 'Recalculating inventory')} />
              <div style={{ fontSize: '14px', fontWeight: 400, color: 'white' }}>
                <Trans
                  i18nKey="recipe-queue.recalculating-inventory-desc"
                  defaults="Recipe selector is disabled while inventory is recalculating. This is done by {{time}} approx."
                  values={{ time: dayjs().minute(RECALCULATION_END_MINUTE).format('H.mm') }}
                />
              </div>
            </div>
          ) : null}
          {isLoading ? (
            <Loading size="large" />
          ) : isError ? (
            <NetworkError refetch={refetch} errorStatusCode={recipesError.response?.status} />
          ) : data.length === 0 ? (
            <div className={styles.noRecipe}>{t('recipe-queue.no-recipes-in-queue', 'No recipes in queue')}</div>
          ) : data.length > 0 ? (
            data
              .slice()
              .sort((a, b) => a.recipeOrder - b.recipeOrder)
              .filter((i) => (showCompleted ? i : i.recipeStatus !== 'completed'))
              .map(({ recipeId, endTime, startTime, recipeStatus, oreLeftInHours, recipeOrder }) => (
                <RecipeQueueItem
                  key={recipeId}
                  startTime={startTime}
                  endTime={endTime}
                  recipeStatus={recipeStatus}
                  recipeId={recipeId}
                  latest={Math.max(...data.map((r) => r.recipeOrder)) === recipeOrder}
                  oldest={
                    Math.min(...data.filter((d) => d.recipeStatus !== 'completed').map((r) => r.recipeOrder)) ===
                    recipeOrder
                  }
                  oreLeftInHours={oreLeftInHours}
                />
              ))
          ) : null}
        </div>
        {trend && recipeTrend ? (
          <Affix offsetBottom={0}>
            <div>
              <RecipeTrend show={isTrendOpen} />
              <div className={styles.trendHeader}>
                {t('common.trend', 'Trend')}
                <DownOutlined onClick={() => setIsTrendOpen(!isTrendOpen)} rotate={isTrendOpen ? 0 : 180} />
              </div>
            </div>
          </Affix>
        ) : null}
      </div>
      <div className={styles.buttons}>
        {recipeModifications ? (
          <Link to="/slice-selector">
            <PlusOutlined style={{ fontSize: '36px' }} />
            <br />
            {t('recipe-queue.create-new-recipe', 'Create new recipe')}
          </Link>
        ) : null}
      </div>
    </div>
  )
}
