import { api, apis, version } from './api'

const { REACT_APP_API_URL, REACT_APP_NO_API_MANAGEMENT } = process.env
let authApi = api.extend({ prefixUrl: `${REACT_APP_API_URL}/${apis.authorization}/${version}` })

if (REACT_APP_NO_API_MANAGEMENT === 'true') {
    authApi = api.extend({ prefixUrl: `${REACT_APP_API_URL}` })
}

export const fetchPermissions = () => authApi.get('permissions').json()
