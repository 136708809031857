// @ts-check
import { ArrowRightOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider, Modal, Typography } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styles from './recipe-confirmation-modal.module.css'

/** @param {import('components').RecipeConfirmationModalPropsType} props */
export const RecipeConfirmationModal = (props) => {
  const { t } = useTranslation()
  const { Text } = Typography
  const startTime = props.recipeStartTime.date
  const onOk = () => props.onConfirm()
  const onCancel = () => props.toggleModal()
  const footer = () => (
    <>
      <Button onClick={onCancel}>{t('common.cancel', 'Cancel')}</Button>
      <Button type="primary" onClick={onOk} loading={props.isLoading} disabled={props.recalculating}>
        {t('common.confirm', 'Confirm')}
      </Button>
    </>
  )
  return (
    <Modal
      title={t('recipe-planner.add-to-queue', 'Add to queue')}
      visible={props.visible}
      destroyOnClose={true}
      className={styles.recipeConfirmationModal}
      footer={footer()}
      onCancel={onCancel}
    >
      {t('recipe-planner.add-to-queue-confirm', 'Do you want to add recipe to the queue?')}
      <Divider />
      <div className={styles.recipeTime}>
        <div className={styles.timeStamp}>
          <Text type="secondary">{t('recipe-planner.start-time', 'Start time')}</Text>
          <Text>{dayjs(startTime).format('D.M.YYYY H.mm')}</Text>
          {props.recipeStartTime.customStartTime ? (
            <span className={styles.custom}>{t('common.custom', 'Custom')}</span>
          ) : null}
        </div>
        <div className={styles.arrow}>
          <ArrowRightOutlined />
        </div>
        <div className={styles.timeStamp}>
          <Text type="secondary">{t('recipe-planner.estimated-end-time', 'Estimated end time')}</Text>
          <Text>{dayjs(startTime).add(props.totalHours, 'hours').format('D.M.YYYY H.mm')}</Text>
        </div>
      </div>
      <Checkbox checked={true}>{t('recipe-planner.activate-automatically', 'Activate automatically')}</Checkbox>
    </Modal>
  )
}
