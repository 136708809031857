import dayjs from 'dayjs'
import styles from './custom-tooltip.module.css'
export function CustomTooltip({ active, payload }) {
  if (!active) {
    return null
  }
  if (payload && payload.length) {
    const timestamp = payload[0]?.payload?.timestamp
    return (
      <div className={styles.customTooltip}>
        <p>
          <strong>{dayjs(timestamp).format('D.M.YYYY H.mm')}</strong>
        </p>
        {payload.map((p) => (
          <p key={p.name} style={{ color: p.color }}>{`${p.name} : ${p.value}`}</p>
        ))}
      </div>
    )
  }
  return null
}
