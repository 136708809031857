// @ts-check
import { useRecoilState } from 'recoil'
import { CommentOutlined, CloseOutlined } from '@ant-design/icons'
import { Table, Tag, Button } from 'antd'
import dayjs from 'dayjs'
import { has } from 'rambda'
import { useTranslation } from 'react-i18next'
import { capitalize, formatTonnes, formatTwoDecimals, pickShiftType } from 'utils/utils'
import styles from './add-slice-table.module.css'
import { addSliceGhostBatchesAtom } from 'atoms'
import stockpileStyles from '../../styles/Stockpile.module.css'

export function AddSliceTable({ batches, total }) {
  const { t } = useTranslation()
  const [addSliceGhostBatches, setAddSliceGhostBatches] = useRecoilState(addSliceGhostBatchesAtom)

  const dataSource = total ? [total, ...batches] : batches

  const columns = [
    {
      title: null,
      dataIndex: 'type',
      key: 'batchType',
      minWidth: 0,
      render: (value, record) => renderBatchType(value, record)
    },
    {
      title: t('common.date', 'Date'),
      dataIndex: ['shift', 'startDate'],
      key: 'date',
      render: (value) => renderContent(value, t)
    },
    {
      title: t('common.shift', 'Shift'),
      dataIndex: 'shift',
      key: 'shift',
      render: (value) => renderContent(value, t)
    },
    {
      title: 'Au',
      dataIndex: ['grades', '0', 'gradeValue'],
      key: 'au',
      align: 'right',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'S',
      dataIndex: ['grades', '1', 'gradeValue'],
      key: 's',
      align: 'right',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'As',
      dataIndex: ['grades', '2', 'gradeValue'],
      key: 'as',
      align: 'right',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Ctoc',
      dataIndex: ['grades', '3', 'gradeValue'],
      key: 'ctoc',
      align: 'right',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Ccarb',
      dataIndex: ['grades', '4', 'gradeValue'],
      key: 'ccarb',
      align: 'right',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Ag',
      dataIndex: ['grades', '5', 'gradeValue'],
      key: 'ag',
      align: 'right',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Sb',
      dataIndex: ['grades', '6', 'gradeValue'],
      key: 'sb',
      align: 'right',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'RQD',
      dataIndex: ['grades', '7', 'gradeValue'],
      key: 'rqd',
      align: 'right',
      render: (grade) => (grade ? Math.round(grade) : null)
    },
    {
      title: t('common.areas', 'Areas'),
      dataIndex: 'source',
      key: 'areas',
      align: 'right',
      render: (v) => (Array.isArray(v) ? v.map((a) => `${a.areaCode} ${Math.round(a.portion)} %`).join(', ') : v)
    },
    {
      title: t('common.assayed', 'Assayed'),
      dataIndex: 'assayed',
      key: 'assayed',
      align: 'right',
      render: (percent) => (typeof percent === 'number' ? `${Math.round(percent)} %` : null)
    },
    {
      title: t('common.tons', 'Tons'),
      dataIndex: 'tonnes',
      key: 'tonnes',
      align: 'right',
      render: (tonnes) => (tonnes ? formatTonnes(tonnes) : null)
    },
    {
      dataIndex: 'comment',
      key: 'comment',
      render: (comment) =>
        typeof comment?.onClick === 'function' ? (
          <CommentOutlined onClick={comment.onClick} className={styles.commentIcon} />
        ) : null
    }
  ]

  function renderBatchType(value, record) {
    return addSliceGhostBatches?.some((b) => b.uiId === record?.uiId) ? (
      <Tag
        style={{ paddingRight: 0 }}
        closable={true}
        closeIcon={
          <Button type="link" size="small" onClick={() => handleRemoveBatch(record)}>
            <CloseOutlined />
          </Button>
        }
        onClose={(e) => e.preventDefault()}
      >
        {'Ghost'}
      </Tag>
    ) : null
  }

  function handleRemoveBatch(record) {
    setAddSliceGhostBatches((current) => current.filter((b) => b.uiId !== record?.uiId))
  }

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      indentSize={16}
      className={`${stockpileStyles.table} ${styles.table}`}
    />
  )
}

function renderContent(value, t) {
  const obj = { children: value, props: {} }

  if (dayjs(value).isValid() && value !== undefined) {
    obj.children = dayjs(value).format('DD.MM.YYYY')
    return obj
  }

  if (has('type', value)) {
    obj.children = capitalize(pickShiftType(value))
    return obj
  }

  if (value === 'total') {
    obj.children = <strong>{t('common.total', 'Total')}</strong>
    return obj
  }

  if (value === 'stockpile update') {
    obj.children = (
      <span style={{ color: 'var(--accent-normal)', textTransform: 'uppercase' }}>
        {t('common.stockpile-update', 'Stockpile update')}
      </span>
    )
    obj.props.colSpan = 2
    return obj
  }

  if (value === 'colspan') {
    obj.children = null
    obj.props.colSpan = 0
  }

  return obj
}
