import dayjs, { Dayjs } from 'dayjs'
import { RECALCULATION_END_MINUTE, RECALCULATION_START_MINUTE, RECALCULATION_START_SECONDS } from '../constants'
import { formatOneDecimal } from './utils'

export const sortDateAsc = (a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : dayjs(a).isSame(dayjs(b)) ? 0 : -1)
export const sortDateDesc = (a, b) => (dayjs(a).isAfter(dayjs(b)) ? -1 : dayjs(a).isSame(dayjs(b)) ? 0 : 1)
export function startOfNextShift() {
  const currentHour = dayjs().hour()

  if (currentHour >= 20) {
    return dayjs().add(1, 'day').hour(8).minute(0).second(0).subtract(2, 'hour')
  }

  if (currentHour >= 8) {
    return dayjs().hour(20).minute(0).second(0).subtract(2, 'hour')
  }

  if (currentHour >= 0) {
    return dayjs().hour(8).minute(0).second(0).subtract(2, 'hour')
  }
}
/** @param {number} hours */
export const hoursToShifts = (hours) => formatOneDecimal(hours / 12)
/** @param {Dayjs} time */
export const isRecalculating = (time) => {
  const startTime = dayjs().minute(RECALCULATION_START_MINUTE).second(RECALCULATION_START_SECONDS).millisecond(0)
  const endTime = dayjs().minute(RECALCULATION_END_MINUTE).second(0).millisecond(0)
  return time.isSameOrAfter(startTime) && time.isBefore(endTime)
}
