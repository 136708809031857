// @ts-check
import { Table, Typography, Radio } from 'antd'
import dayjs from 'dayjs'
import { selectedStockpilesForRecipeAtom, addSliceDataAtom } from 'atoms'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { capitalize, formatTwoDecimals, pickShiftType, findElementsAndGrades } from 'utils/utils'
import styles from './selectable-slices-stockpile.module.css'
import styles2 from '../../styles/StockpileStateBar.module.css'

/** @param {import('components').SelectableSlicesStockpilePropsType} props */
export function SourceLocationTable({ sourceLocation }) {
  const { t } = useTranslation()

  const { Text } = Typography

  const columns = [
    {
      title: t('common.date', 'Date'),
      dataIndex: 'date',
      key: 'date',
      width: '120px',
      render: (date, record) =>
        date === 'total' ? <strong style={{ textTransform: 'capitalize' }}>{date}</strong> : date
    },
    {
      title: t('common.shift', 'Shift'),
      dataIndex: 'shift',
      key: 'shift',
      width: '70px',
      render: (shift, record) => (shift ? capitalize(pickShiftType(shift)) : null)
    },
    {
      title: 'Au',
      dataIndex: 'au',
      key: 'au',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'S',
      dataIndex: 's',
      key: 's',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'As',
      dataIndex: 'as',
      key: 'as',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ctoc',
      dataIndex: 'ctoc',
      key: 'ctoc',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ccarb',
      dataIndex: 'ccarb',
      key: 'ccarb',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ag',
      dataIndex: 'ag',
      key: 'ag',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Sb',
      dataIndex: 'sb',
      key: 'sb',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'RQD',
      dataIndex: 'rqd',
      key: 'rqd',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? Math.round(grade) : null)
    },
    {
      title: t('common.areas', 'Areas'),
      dataIndex: 'areas',
      key: 'areas',
      align: 'right',
      width: '250px',
      render: (areas, record) => areas
    },
    {
      title: t('common.assayed', 'Assayed'),
      dataIndex: 'assayed',
      key: 'assayed',
      align: 'right',
      render: (percent, record) => (typeof percent === 'number' ? `${Math.round(percent)} %` : null)
    },
    {
      title: t('common.tons', 'Tons'),
      dataIndex: 'tonnes',
      key: 'tonnes',
      align: 'right',
      render: (tons, record) => null
    },
    {
      title: t('common.change', 'Change'),
      dataIndex: 'change',
      key: 'change',
      align: 'right',
      render: (text, record) => null
    }
  ]

  const rowSelection = {
    hideSelectAll: true,
    type: 'checkbox',
    selectedRowKeys: [],
    renderCell: (checked, record, index, originNode) => null
  }

  const data = [
    {
      id: sourceLocation.name,
      key: `${sourceLocation.shift.startDate}${sourceLocation.shift.type}`,
      date: dayjs(sourceLocation.shift.startDate).format('DD.MM.YYYY'),
      shift: sourceLocation.shift,
      ...findElementsAndGrades(sourceLocation.elements),
      areas: `${sourceLocation.name?.[0]} 100 %`,
      assayed: 0
    }
  ]

  return data?.length > 0 ? (
    <Table
      size="small"
      title={() => <TableTitle data={sourceLocation} />}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={false}
      style={{ paddingBottom: '16px' }}
      className={styles.simpleStockpile}
      locale={{ emptyText: t('common.no-data', 'Could not find any slices for stockpile') }}
    />
  ) : null
}

function TableTitle({ data }) {
  const selectedStockpilesForRecipe = useRecoilValue(selectedStockpilesForRecipeAtom)
  const setAddSliceData = useSetRecoilState(addSliceDataAtom)

  function handleChange(e) {
    const sp = selectedStockpilesForRecipe.find((d) => d.id === e.target.value)

    setAddSliceData({
      pileId: sp.id,
      pileName: sp.title,
      sourceName: data.name
    })
  }

  return (
    <div className={styles.titleContainer}>
      <div className={styles.title}>{data.name}</div>
      <div className={styles2.stateBar}>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          size="small"
          className={styles.stateGroup}
          value={null}
          onChange={handleChange}
        >
          {selectedStockpilesForRecipe.map((sp) => (
            <Radio.Button key={sp.id} value={sp.id}>
              {sp.title}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </div>
  )
}
