const {
  REACT_APP_MSAL_CLIENTID,
  REACT_APP_MSAL_TENANTID,
  REACT_APP_MSAL_REDIRECTURI,
  REACT_APP_MSAL_LOGOUT_REDIRECTURI
} = process.env

export const msalConfig = {
  auth: {
    clientId: REACT_APP_MSAL_CLIENTID,
    authority: `https://login.microsoftonline.com/${REACT_APP_MSAL_TENANTID}`,
    redirectUri: REACT_APP_MSAL_REDIRECTURI,
    postLogoutRedirectUri: REACT_APP_MSAL_LOGOUT_REDIRECTURI
  }
}

export const loginRequest = {
  scopes: ['User.Read']
}

export const apiScopes = {
  scopes: ['api://76616c26-39f6-4187-a8a5-4f33e72e6605/user_impersonation']
}

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
