import { EditOutlined } from '@ant-design/icons'
import { Checkbox, DatePicker, Form, Input, InputNumber, Modal } from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { pickShiftType } from 'utils/utils'
import styles from '../styles/StockpileUpdateModal.module.css'

/** @param {import('components').StockpileUpdateModalPropsType} props */
export function StockpileUpdateModal(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  function onOk() {
    form.validateFields().then(({ updateComment, ...rest }) => {
      props.onOk({ ...rest, comment: updateComment })
    })
  }

  const dateFormat = 'DD.MM.YYYY'
  const startDate = dayjs(props.updateTarget.data?.pilingShift?.startDate).format(dateFormat)
  const shiftType = pickShiftType(props.updateTarget.data?.pilingShift)
  const title = (
    <>
      <EditOutlined style={{ paddingRight: '16px' }} />
      {props.updateTarget.type === 'stockpile'
        ? `${t('common.update-stockpile', 'Update stockpile')} ${props.updateTarget.data?.summary.title}`
        : `${t('common.update-slice', 'Update slice')} ${startDate} ${shiftType} shift`}
    </>
  )

  useEffect(() => {
    form.resetFields()
  }, [form, props.visible])

  return (
    <Modal
      title={title}
      visible={props.visible}
      onOk={onOk}
      onCancel={() => props.onCancel({})}
      okText={t('common.ok', 'OK')}
      cancelText={t('common.cancel', 'Cancel')}
      forceRender
    >
      <Form
        layout="vertical"
        form={form}
        className={styles.form}
        initialValues={{ surveyTime: dayjs(), correctInventoryFlag: true }}
      >
        <Form.Item label={t('common.survey-date', 'Survey date')} name="surveyTime">
          <DatePicker format={dateFormat} />
        </Form.Item>
        <Form.Item
          label={t('common.amount', 'Amount')}
          name="surveyTonnes"
          rules={[
            {
              required: true,
              message: t('common.insert-amount', 'Please insert amount')
            }
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="correctInventoryFlag" valuePropName="checked">
          <Checkbox>{t('stockpile.correct-inventory', 'Correct inventory')}</Checkbox>
        </Form.Item>
        <Form.Item
          label={t('common.comment', 'Comment')}
          name="updateComment"
          rules={[
            {
              required: true,
              message: t('common.insert-comment', 'Please insert comment')
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
