import dayjs from 'dayjs'
import { atom, atomFamily, DefaultValue } from 'recoil'
import { isRecalculating } from 'utils/date-utils'

const localStorageEffect = ({ setSelf, onSet, node }) => {
  const savedValue = localStorage.getItem(node.key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue))
  }

  onSet((newValue) =>
    newValue instanceof DefaultValue
      ? localStorage.removeItem(node.key)
      : localStorage.setItem(node.key, JSON.stringify(newValue))
  )
}

export const userAtom = atom({
  key: 'user',
  /** @type {import('types').User} */
  default: {
    name: '',
    oid: '',
    role: ''
  }
})

export const accessRightsAtom = atom({
  key: 'accessRights',
  default: []
})

export const stockpilesListAtom = atom({
  key: 'stockpiles-list',
  /** @type {import('types').Stockpile[]} */
  default: [],
  effects_UNSTABLE: [localStorageEffect]
})

export const stockpilesFilterAtom = atom({
  key: 'stockpilesFilter',
  /** @type {import('types').StockpileFilter} */
  default: 'ALL'
})

export const selectedSlicesForRecipeAtom = atom({
  key: 'selectedSlices',
  default: []
})

export const selectedStockpilesForRecipeAtom = atom({
  key: 'selected-stockpiles-for-recipe',
  /** @type {{ id:string, title: string }[]} */
  default: []
})

export const selectedSlicesForRecipeTooAtom = atomFamily({
  key: 'selected-slices-for-recipe',
  /** @type {{ sliceId: string, tons: number, usedTonnes?: number, availableTons: number, shift: import('types').Shift }[]} */
  default: []
})
export const usageRatioAtom = atomFamily({ key: 'usage-ratio', default: null })
export const millCapacityAtom = atom({ key: 'mill-capacity', default: 6000 })
export const recipeStartTimeAtom = atom({
  key: 'recipe-start-time',
  /** @type {import('types').RecipeStartTime} */
  default: { date: null, customStartTime: false }
})
export const lastRecipeEndTimeAtom = atom({ key: 'last-recipe-end-time', default: null })
export const recipeQueueAtom = atom({ key: 'recipe-queue', default: null })
export const recalculatingAtom = atom({ key: 'recalculating', default: isRecalculating(dayjs()) })
export const inactiveElementsForTrendAtom = atom({
  key: 'inactive-elements-for-atom',
  default: [],
  effects_UNSTABLE: [localStorageEffect]
})

export const selectedLgpStockpilesAtom = atom({
  key: 'selected-lgp-stockpiles',
  /** @type {{ id:string, title: string }[]} */
  default: []
})

export const selectedSourceLocationsAtom = atom({
  key: 'selected-source-locations',
  default: []
})

export const lgpAndMinesSearchTermAtom = atom({
  key: 'lgp-and-mines-search-term',
  default: ''
})

export const sliceSelectorExpandedAtom = atom({
  key: 'slice-selector-expanded',
  default: { lgp: false, mines: false }
})

export const addSliceDataAtom = atom({
  key: 'add-slice-data',
  /** @type {import('types').AddSliceData} */
  default: null
})

export const addSliceGhostBatchesAtom = atom({
  key: 'add-slice-ghost-batches',
  default: []
})

export const addSliceExistingBatchesAtom = atom({
  key: 'add-slice-existing-batches',
  default: []
})
