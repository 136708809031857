import { CloseOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import { Avatar, Layout, Menu, Popover } from 'antd'
import { userAtom } from 'atoms'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import logo from 'images/Finland_WB.png'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { msalInstance } from '..'
import styles from '../styles/Header.module.css'

export function Header() {
  const [current, setCurrent] = useState('/dashboard')
  const user = useRecoilValue(userAtom)
  const location = useLocation()
  const { t } = useTranslation()
  const { instance } = useMsal()
  const { stockpileInventory, recipePlanning } = useFeatureFlags()
  const { REACT_APP_ENV } = process.env

  useEffect(() => {
    setCurrent(location.pathname)
  }, [location])

  const handleLogout = () => instance.logout({ account: msalInstance.getActiveAccount() })

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.logo}>
        <img src={logo} alt="Agnico Eagle Finland logo" height="47" />
      </div>
      <nav>
        <Menu mode="horizontal" selectedKeys={[current]}>
          {stockpileInventory ? (
            <Menu.Item key="/dashboard">
              <Link to="/dashboard">{t('header.dashboard', 'Dashboard')}</Link>
            </Menu.Item>
          ) : null}
          {recipePlanning ? (
            <Menu.Item key="/recipe-queue">
              <Link to="/recipe-queue">{t('header.recipe-queue', 'Recipe queue')}</Link>
            </Menu.Item>
          ) : null}
        </Menu>
      </nav>
      {current === '/recipe-planner' ? (
        <div className={styles.recipePlannerBtn}>
          {t('header.recipe-planner', 'Recipe planner')}
          <Link to="/recipe-queue?cancel=true">
            <CloseOutlined style={{ fontSize: '20px', paddingLeft: '16px' }} />
          </Link>
        </div>
      ) : null}
      {REACT_APP_ENV === 'prod' ? null : <div className={styles.env}>{REACT_APP_ENV}</div>}
      <div className={styles.user}>
        <span className={styles.username}>{user.name}</span>
        <Popover
          placement="bottomRight"
          content={
            <div className={styles.userMenu}>
              <span onClick={handleLogout} className={styles.userMenuItem}>
                {t('common.log-out', 'Log out')} <LogoutOutlined />
              </span>
            </div>
          }
          trigger="click"
        >
          <Avatar size="large" icon={<UserOutlined />} className={styles.avatar} />
        </Popover>
      </div>
    </Layout.Header>
  )
}
