// @ts-check
import { CommentOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Popconfirm, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './comment-modal.module.css'

/** @param {import('components').CommentModalPropsType} props */
export const CommentModal = ({
  comment,
  onOk,
  onCancel,
  onDelete,
  readOnly,
  editComment,
  deleteCommentLoading,
  addCommentLoading,
  updateCommentLoading,
  visible
}) => {
  const [_comment, _setComment] = useState(comment)
  const [_error, _setError] = useState('')
  const { t } = useTranslation()
  const hasComment = comment?.length > 0
  const hasEdited = comment !== _comment && _comment.length > 0
  const isLoading = () => deleteCommentLoading || addCommentLoading || updateCommentLoading

  function _onOk() {
    if (isLoading()) {
      return
    }
    if (_comment === '') {
      _setError(t('common.insert-comment', 'Please insert comment'))
      return
    }
    if (comment === _comment) {
      onCancel({})
      return
    }
    const mode = _comment !== comment && comment.length > 0 ? 'EDIT' : 'ADD'
    onOk(_comment, mode)
  }

  /** @param {React.ChangeEvent<HTMLTextAreaElement>} e*/
  function onChange(e) {
    if (e.target.value.length > 0) {
      _setError('')
    }
    _setComment(e.target.value)
  }

  const title = () =>
    readOnly ? null : editComment ? t('common.edit-comment', 'Edit comment') : t('common.add-comment', 'Add comment')

  const footer = () =>
    readOnly ? null : (
      <>
        {hasComment && typeof onDelete === 'function' ? (
          <Popconfirm
            title={t('common.are-you-sure', 'Are You sure?')}
            okText={t('common.ok', 'OK')}
            cancelText={t('common.cancel', 'Cancel')}
            overlayStyle={{ whiteSpace: 'nowrap' }}
            onConfirm={() => (isLoading() ? null : onDelete())}
          >
            <Button danger type="primary" loading={deleteCommentLoading}>
              {t('common.delete', 'Delete')}
            </Button>
          </Popconfirm>
        ) : null}
        <Button type="default" onClick={() => (isLoading() ? null : onCancel({}))}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <Button type="primary" onClick={_onOk} loading={addCommentLoading || updateCommentLoading}>
          {t('common.ok', 'OK')}
        </Button>
      </>
    )
  useEffect(() => {
    _setComment(comment)
  }, [comment])

  return (
    <Modal
      title={
        <>
          <CommentOutlined style={{ paddingRight: '16px' }} />
          {title()}
        </>
      }
      visible={visible}
      onOk={_onOk}
      onCancel={onCancel}
      destroyOnClose={true}
      okText={t('common.ok', 'OK')}
      cancelText={t('common.cancel', 'Cancel')}
      footer={footer()}
      maskClosable={!hasEdited}
      afterClose={() => _setError('')}
    >
      <div className={styles.commentModal}>
        {readOnly ? (
          <p style={{ whiteSpace: 'pre-line' }}>{comment}</p>
        ) : (
          <>
            <label htmlFor="comment-input">{t('common.comment', 'Comment')}</label>
            <Input.TextArea
              name="comment-input"
              id="comment-input"
              autoSize={{ minRows: 5, maxRows: 10 }}
              value={_comment}
              onChange={onChange}
            />
            {_error ? <div className="ant-form-item-explain ant-form-item-explain-error">{_error}</div> : null}
            {hasEdited ? (
              <Typography.Text type="secondary">
                {t(
                  'common.close-with-ok-or-cancel',
                  'You need to close this view with OK- or Cancel-button after editing comment.'
                )}
              </Typography.Text>
            ) : null}
          </>
        )}
      </div>
    </Modal>
  )
}
