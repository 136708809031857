import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { totalBalanceSelector } from 'selectors'
import { formatTwoDecimals, getContent, joinAreaCodesIntoString, sortGrades } from 'utils/utils'
import styles from './summary.module.css'

export function Summary({ areas, grades = [], viewMode = false }) {
  const { t } = useTranslation()
  const balance = useRecoilValue(totalBalanceSelector)
  const isAreas = (t) => t.toLowerCase() === 'areas'
  const renderContent = (t) => (isAreas(t) ? null : <span className={styles.content}>{getContent(t)}</span>)

  return (
    <>
      <div className={styles.summaryHeader}>
        <div className={styles.summaryTitle}>{t('common.summary', 'Summary')}</div>
        <div className={styles.summaryBalance}>
          {t('common.balance', 'Balance')} <span className={styles.balance}>{viewMode ? '100' : balance} %</span>
        </div>
      </div>
      <Divider />
      {grades.length > 0 &&
        sortGrades(grades).map(({ elementSymbol, gradeAverage, rangeMax, rangeMin }) => (
          <div className={styles.summaryItem} key={elementSymbol}>
            <div className={styles.element}>{elementSymbol}</div>
            <div className={styles.values}>
              <div className={styles.grade}>
                {elementSymbol === 'RQD' ? Math.round(gradeAverage) : formatTwoDecimals(gradeAverage)}{' '}
                {!isAreas(elementSymbol) && renderContent(elementSymbol)}
              </div>
              {!isAreas(elementSymbol) && (
                <div className={styles.range}>
                  {formatTwoDecimals(rangeMin)} - {formatTwoDecimals(rangeMax)} {renderContent(elementSymbol)}
                </div>
              )}
            </div>
          </div>
        ))}
      <div className={styles.summaryItem}>
        <div className={styles.element}>{t('common.areas', 'Areas')}</div>
        <div className={styles.values}>
          <div className={styles.range}>{joinAreaCodesIntoString(areas)}</div>
        </div>
      </div>
    </>
  )
}
