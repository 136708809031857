import { userAtom } from 'atoms'
import { Redirect, Route } from 'react-router'
import { useRecoilValue } from 'recoil'

export function PrivateRoute({ children, ...rest }) {
  const user = useRecoilValue(userAtom)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        children.props.rolesAllowed.includes(user.role) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
