import { EventType, PublicClientApplication } from '@azure/msal-browser'
import dayjs from 'dayjs'
import 'dayjs/locale/fi'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import updateLocale from 'dayjs/plugin/updateLocale'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { defaultQueryFn } from './api'
import App from './components/App'
import './i18n'
import { msalConfig } from './msalConfig'
import './styles/index.less'

dayjs.extend(isSameOrAfter)
dayjs.extend(duration)
dayjs.extend(updateLocale)

dayjs.updateLocale(dayjs.locale(), {
  weekStart: 1
})

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    msalInstance.setActiveAccount(event.payload.account)
  }
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      retry: process.env.NODE_ENV === 'production' ? 4 : 1,
      queryFn: defaultQueryFn
    }
  }
})

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

ReactDOM.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App pca={msalInstance} />
      </Router>
    </QueryClientProvider>
  </RecoilRoot>,
  document.getElementById('root')
)
