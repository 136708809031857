import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from '../styles/StockpileSummary.module.css'
import { ElementsAndGrades } from './ElementsAndGrades'

/** @param {import('components').StockpileSummaryPropsType} */
export function StockpileSummary({ summary }) {
  const { t } = useTranslation()
  const { Title } = Typography

  return (
    <div className={styles.summary}>
      <div className={styles.header}>
        <Title level={5}>{t('dashboard.stockpile-inventory', 'Stockpile inventory')}</Title>
      </div>
      <ElementsAndGrades grades={summary.averageGrades} tonnes={summary.tonnes} />
    </div>
  )
}
