// @ts-check
import { ArrowLeftOutlined, ArrowRightOutlined, CommentOutlined } from '@ant-design/icons'
import { Checkbox, Space, Table } from 'antd'
import { selectedSlicesForRecipeAtom, selectedStockpilesForRecipeAtom } from 'atoms'
import { Title } from 'components/common/title'
import { ElementsAndGrades } from 'components/ElementsAndGrades'
import { EllipsesMenu } from 'components/EllipsesMenu'
import { StockpileStateBar } from 'components/StockpileStateBar'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { has, includes } from 'rambda'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { capitalize, formatTonnes, formatTwoDecimals, pickShiftType } from 'utils/utils'
import styles from '../styles/Stockpile.module.css'

export function Stockpile(props) {
  const { t } = useTranslation()
  const { stockpileCorrections } = useAccessRights()
  const [selectedStockpilesForRecipe, setSelectedStockpilesForRecipe] = useRecoilState(selectedStockpilesForRecipeAtom)
  const [selectedSlicesForRecipe, setSelectedSlicesForRecipe] = useRecoilState(selectedSlicesForRecipeAtom)
  const isSelected = (stockpile) => includes(stockpile, selectedStockpilesForRecipe)

  const columns = [
    {
      title: t('common.date', 'Date'),
      dataIndex: 'date',
      key: 'date',
      width: 200,
      render: (value) => renderContent(value, t)
    },
    {
      title: t('common.shift', 'Shift'),
      dataIndex: 'shift',
      key: 'shift',
      width: '70px',
      render: (value) => renderContent(value, t)
    },
    {
      title: 'Au',
      dataIndex: 'au',
      key: 'au',
      align: 'right',
      width: '90px',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'S',
      dataIndex: 's',
      key: 's',
      align: 'right',
      width: '90px',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'As',
      dataIndex: 'as',
      key: 'as',
      align: 'right',
      width: '90px',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Ctoc',
      dataIndex: 'ctoc',
      key: 'ctoc',
      align: 'right',
      width: '90px',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Ccarb',
      dataIndex: 'ccarb',
      key: 'ccarb',
      align: 'right',
      width: '90px',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Ag',
      dataIndex: 'ag',
      key: 'ag',
      align: 'right',
      width: '90px',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'Sb',
      dataIndex: 'sb',
      key: 'sb',
      align: 'right',
      width: '90px',
      render: (v) => (v ? formatTwoDecimals(v) : null)
    },
    {
      title: 'RQD',
      dataIndex: 'rqd',
      key: 'rqd',
      align: 'right',
      width: '90px',
      render: (grade) => (grade ? Math.round(grade) : null)
    },
    {
      title: t('common.areas', 'Areas'),
      dataIndex: 'areas',
      key: 'areas',
      width: '250px',
      align: 'right',
      render: (v) => (Array.isArray(v) ? v.map((a) => `${a.areaCode} ${Math.round(a.portion)} %`).join(', ') : v)
    },
    {
      title: t('common.assayed', 'Assayed'),
      dataIndex: 'assayed',
      key: 'assayed',
      align: 'right',
      render: (percent) => (typeof percent === 'number' ? `${Math.round(percent)} %` : null)
    },
    {
      dataIndex: 'direction',
      key: 'direction',
      width: 70,
      render: (dir) => (dir === 'in' ? <ArrowRightOutlined /> : dir === 'out' ? <ArrowLeftOutlined /> : null)
    },
    {
      title: t('common.tons', 'Tons'),
      dataIndex: 'tonnes',
      key: 'tonnes',
      align: 'right',
      render: (tonnes) => (tonnes ? formatTonnes(tonnes) : null)
    },
    {
      dataIndex: 'comment',
      key: 'comment',
      render: (comment) =>
        typeof comment?.onClick === 'function' ? (
          <CommentOutlined onClick={comment.onClick} className={styles.commentIcon} />
        ) : null,
      width: 50
    },
    {
      dataIndex: 'menu',
      key: 'menu',
      width: 50,
      render: (props) =>
        props && stockpileCorrections ? (
          <EllipsesMenu
            type={props.type}
            toggleUpdateModalFn={props.toggleUpdateModalFn}
            toggleCommentModalFn={props.toggleCommentModalFn}
            hasComment={props.hasComment}
          />
        ) : null
    }
  ]
  const onChange = () => {
    const stockpile = { id: props.id, title: props.title }
    if (isSelected(stockpile)) {
      setSelectedSlicesForRecipe(selectedSlicesForRecipe.filter((s) => s.stockpileId !== stockpile.id))
      setSelectedStockpilesForRecipe((recipes) => recipes.filter((s) => s.id !== stockpile.id))
      return
    }
    setSelectedStockpilesForRecipe((recipes) => [...recipes, stockpile])
  }

  return (
    <div>
      <div className={styles.title}>
        <Title underlined>{props.title}</Title>
        <Space size="large">
          {props.onSelect ? (
            <Checkbox
              style={{ transform: 'scale(1.125)', fontSize: '16px' }}
              onChange={onChange}
              checked={props.selected}
            >
              {t('common.selected', 'Selected')}
            </Checkbox>
          ) : null}
          <StockpileStateBar
            value={props.stockpileState}
            editableState={props.editableState}
            onStateChange={props.onStateChange}
            disabled={props.stateBarDisabled}
          />
        </Space>
      </div>
      <ElementsAndGrades grades={props.grades} tonnes={props.tonnes} />
      <Table
        size="small"
        columns={columns.filter((col) => (props.noMenu && col.key === 'menu' ? false : true))}
        dataSource={props.slices}
        pagination={false}
        expandable={{ childrenColumnName: 'batches' }}
        indentSize={16}
        className={styles.table}
      />
    </div>
  )
}

//#region helpers
function renderContent(value, t) {
  const obj = { children: value, props: {} }

  if (dayjs(value).isValid() && value !== undefined) {
    obj.children = dayjs(value).format('DD.MM.YYYY')
    return obj
  }

  if (has('type', value)) {
    obj.children = capitalize(pickShiftType(value))
    return obj
  }

  if (value === 'total') {
    obj.children = <strong>{t('common.total', 'Total')}</strong>
    return obj
  }

  if (value === 'stockpile update') {
    obj.children = (
      <span style={{ color: 'var(--accent-normal)', textTransform: 'uppercase' }}>
        {t('common.stockpile-update', 'Stockpile update')}
      </span>
    )
    obj.props.colSpan = 2
    return obj
  }

  if (value === 'colspan') {
    obj.children = null
    obj.props.colSpan = 0
  }

  return obj
}
//#endregion
