import { fetchTrend } from 'api/recipePlannerApi'
import { inactiveElementsForTrendAtom, recalculatingAtom } from 'atoms'
import { NetworkError } from 'components/NetworkError'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useRecoilState, useRecoilValue } from 'recoil'
import { trendLines } from '../../constants'
import { CustomTooltip } from './custom-tooltip'
import { Loading } from './loading'

export function RecipeTrendSmall() {
  const [inactive, setInactive] = useRecoilState(inactiveElementsForTrendAtom)
  const [trendData, setTrendData] = useState([])
  const recalculating = useRecoilValue(recalculatingAtom)
  const { trend } = useFeatureFlags()
  const { recipeGrade } = useAccessRights()
  const { isLoading, isError, data, error, refetch } = useQuery('trend', fetchTrend, {
    enabled: recipeGrade && trend && !recalculating
  })
  const handleLegendClick = ({ value }) =>
    inactive.includes(value) ? setInactive(inactive.filter((i) => i !== value)) : setInactive([...inactive, value])

  useEffect(() => {
    if (data) {
      const datapoints = data.trendItems.filter((i) => i.elements.length > 0)
      const currentTime = dayjs().minute(0).second(0).millisecond(0)
      const trendStartTime = currentTime.subtract(6, 'hours')
      const trendEndTime = currentTime.add(24, 'hours')
      const filteredDataPoints = datapoints.filter((d) => {
        const timeStamp = dayjs(d.timestamp)
        return timeStamp.isSameOrAfter(trendStartTime)
      })
      const trendData = datapoints.reduce((acc, cur, idx, arr) => {
        let dataObj = {}
        const curTimestamp = dayjs(cur.timestamp)
        const divider = arr.length < 24 ? 1 : 6

        if (curTimestamp.hour() % divider === 0) {
          cur.elements?.forEach((e) => {
            dataObj[e.elementSymbol] = Number(e.gradeValue.toFixed(2))
          })
        }

        dataObj.name = curTimestamp.hour() === 0 ? curTimestamp.format('D.M') : `${curTimestamp.format('H')} h`
        dataObj.timestamp = curTimestamp
        return [...acc, dataObj]
      }, [])
      setTrendData(trendData.filter((x) => x.As))
    }
  }, [data])

  useEffect(() => {
    if (!recalculating && recipeGrade) {
      refetch()
    }
  }, [recalculating, refetch, recipeGrade])

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <NetworkError refetch={refetch} errorStatusCode={error.response?.status} />
  ) : trendData.length > 0 ? (
    <ResponsiveContainer width={440} height="80%">
      <LineChart data={trendData}>
        <CartesianGrid stroke="#393939" />
        <XAxis
          dataKey="name"
          axisLine={{ stroke: 'var(--black-light)' }}
          tickLine={{ stroke: 'var(--black-light)' }}
          style={{ fill: 'white' }}
        />
        <YAxis
          yAxisId="left"
          type="number"
          domain={['auto', 'auto']}
          axisLine={{ stroke: 'var(--black-light)' }}
          tickLine={{ stroke: 'var(--black-light)' }}
          style={{ fill: 'white' }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          axisLine={{ stroke: 'var(--black-light)' }}
          tickLine={{ stroke: 'var(--black-light)' }}
          style={{ fill: 'white' }}
        />
        <Tooltip content={<CustomTooltip />} />
        {trendLines.map((line) => (
          <Line
            key={line.title}
            type="linear"
            dataKey={line.title}
            stroke={line.color}
            isAnimationActive={false}
            hide={inactive.includes(line.title)}
            yAxisId={line.yaxis}
          />
        ))}
        <Legend wrapperStyle={{ position: 'relative' }} onClick={handleLegendClick} />
      </LineChart>
    </ResponsiveContainer>
  ) : null
}
