// @ts-check
import { millCapacityAtom } from 'atoms'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { hoursToShifts } from 'utils/date-utils'
import { formatTonnes } from 'utils/utils'
import styles from './capacity.module.css'

/** @param {import('components').CapacityPropsType} props */
export function Capacity({ viewMode, toggleEditTonnesModal, totalHours, totalTons, recipeCapacity }) {
  const { t } = useTranslation()
  const millCapacity = useRecoilValue(millCapacityAtom)
  return (
    <div className={styles.capacity}>
      <div>
        <div>{t('recipe-planner.tons-per-day', 'Tons / day')}</div>
        <div
          className={clsx(styles.capacityValueTonnes, viewMode && styles.noHover)}
          onClick={viewMode ? null : () => toggleEditTonnesModal()}
        >
          {viewMode ? formatTonnes(recipeCapacity) : formatTonnes(millCapacity)}
        </div>
      </div>
      {totalHours ? (
        <div>
          <div>{t('common.shifts', 'Shifts')}</div>
          <div className={styles.capacityValue}>{hoursToShifts(totalHours)}</div>
        </div>
      ) : null}
      {totalTons ? (
        <div>
          <div>{t('common.tons', 'Tons')}</div>
          <div className={styles.capacityValue}>{formatTonnes(totalTons)}</div>
        </div>
      ) : null}
    </div>
  )
}
