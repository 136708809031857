import { Spin } from 'antd'
import styles from './loading.module.css'

/** @param {import('components').LoadingPropsType} props */
export function Loading({ size = 'default', style, text }) {
  return text ? (
    <div className={styles.loadingText}>{text}</div>
  ) : (
    <div className={styles.loading} style={{ ...style }}>
      <Spin size={size} />
    </div>
  )
}
