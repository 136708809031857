// @ts-check
import { Popconfirm, Radio, Space } from 'antd'
import { useAccessRights } from 'hooks/useAccessRights'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useTranslation } from 'react-i18next'
import styles from '../styles/StockpileStateBar.module.css'

/** @param {import('components').StockpileStateBarPropsType} props */
export function StockpileStateBar({ value, disabled, editableState, onStateChange }) {
  const { t } = useTranslation()
  const { recipePlanning } = useFeatureFlags()
  const { stockpileWrite } = useAccessRights()

  /** @param {'pending' | 'ready' | 'released'} newState */
  const onConfirm = (newState) => onStateChange(newState)

  /** @type {{ label: string, value: 'pending' | 'ready' | 'released'}[]} */
  const editableStateOpts = [
    {
      label: t('stockpile.state.pending', 'Pending'),
      value: 'pending'
    },
    {
      label: t('stockpile.state.ready', 'Ready'),
      value: 'ready'
    },
    {
      label: t('stockpile.state.released', 'Released'),
      value: 'released'
    }
  ]

  return (
    <Space size="large">
      <div className={styles.stateBar}>
        <Space size="large">
          <Radio.Group optionType="button" buttonStyle="solid" size="small" value={value} className={styles.stateGroup}>
            <Radio.Button value="infeed" className={styles.stateButton}>
              {t('stockpile.state.infeed', 'Infeed')}
            </Radio.Button>
            <Radio.Button value="outfeed" className={styles.stateButton}>
              {t('stockpile.state.outfeed', 'Outfeed')}
            </Radio.Button>
          </Radio.Group>
        </Space>
      </div>
      <div className={styles.editableStateBar}>
        <Radio.Group optionType="button" buttonStyle="solid" size="small" value={editableState}>
          {editableStateOpts.map((opt) => {
            return stockpileWrite && recipePlanning && !disabled ? (
              <Popconfirm
                title={`${t('stockpile.state-change-confirmation', 'Change state to')} ${opt.label.toLowerCase()}`}
                okText={t('common.ok', 'OK')}
                cancelText={t('common.cancel', 'Cancel')}
                onConfirm={() => onConfirm(opt.value)}
                overlayStyle={{ whiteSpace: 'nowrap' }}
                key={opt.value}
                disabled={editableState === opt.value}
              >
                <Radio.Button value={opt.value}>{opt.label}</Radio.Button>
              </Popconfirm>
            ) : (
              <Radio.Button key={opt.value} value={opt.value} className={styles.stateButton}>
                {opt.label}
              </Radio.Button>
            )
          })}
        </Radio.Group>
      </div>
    </Space>
  )
}
