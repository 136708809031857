import { ReactComponent as Kolomio } from 'images/triangle.svg'
import styles from './title.module.css'

/** @param {import('components').TitlePropsType} props */
export function Title({ underlined, children }) {
  return (
    <div className={styles.title}>
      <span>{children}</span>
      {underlined ? <Kolomio /> : null}
    </div>
  )
}
