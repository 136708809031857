import { Button, Spin } from 'antd'
import { fetchTrend } from 'api/recipePlannerApi'
import { inactiveElementsForTrendAtom, recipeQueueAtom } from 'atoms'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CustomTooltip } from './custom-tooltip'
import styles from './trend.module.css'

const lines = [
  { title: 'Au', color: 'rgb(102, 166, 30)', yaxis: 'left' },
  { title: 'S', color: 'rgb(231, 41, 138)', yaxis: 'left' },
  { title: 'As', color: 'rgb(117, 112, 179)', yaxis: 'left' },
  { title: 'Ctoc', color: 'rgb(217, 95, 2)', yaxis: 'left' },
  { title: 'Ccarb', color: 'rgb(27, 158, 119)', yaxis: 'left' },
  { title: 'Ag', color: 'rgb(230, 171, 2)', yaxis: 'left' },
  { title: 'Sb', color: 'rgb(102, 102, 102)', yaxis: 'right' },
  { title: 'RQD', color: 'rgb(166, 118, 29)', yaxis: 'right' }
]

/** @param {import('components').TrendPropsType} props */
export function RecipeTrend(props) {
  const [inactive, setInactive] = useRecoilState(inactiveElementsForTrendAtom)
  const [trendData, setTrendData] = useState([])
  const { stockpileRead } = useAccessRights()
  const { trend } = useFeatureFlags()
  const recipeQueue = useRecoilValue(recipeQueueAtom)
  const { t } = useTranslation()
  const { isLoading, isError, data, refetch } = useQuery('trend', fetchTrend, { enabled: stockpileRead && trend })

  const handleLegendClick = ({ value }) =>
    inactive.includes(value) ? setInactive(inactive.filter((i) => i !== value)) : setInactive([...inactive, value])

  useEffect(() => {
    if (data) {
      const datapoints = data.trendItems.filter((i) => i.elements.length > 0)
      const trendData = datapoints.reduce((acc, cur, idx, arr) => {
        let dataObj = {}
        const curTimestamp = dayjs(cur.timestamp)
        const divider = arr.length < 24 ? 1 : 6

        if (curTimestamp.hour() % divider === 0) {
          cur.elements?.forEach((e) => {
            dataObj[e.elementSymbol] = e.gradeValue.toFixed(2)
          })
        }

        dataObj.name = curTimestamp.hour() === 0 ? curTimestamp.format('D.M') : `${curTimestamp.format('H')} h`
        dataObj.timestamp = curTimestamp
        return [...acc, dataObj]
      }, [])
      setTrendData(trendData.filter((x) => x.As))
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [recipeQueue, refetch])

  const renderLegend = ({ payload }) => {
    return (
      <div className={styles.customLegend}>
        {payload.map((entry) => (
          <Button
            size="small"
            key={`item-${entry.dataKey}`}
            style={{ color: entry.inactive ? 'white' : entry.color, width: '100px' }}
            className={styles.legendItem}
            onClick={() => handleLegendClick(entry)}
          >
            {entry.value}
          </Button>
        ))}
      </div>
    )
  }

  return (
    <div className={clsx(styles.recipeTrend, props.show ? styles.show : styles.hide)}>
      {isLoading ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : isError ? (
        <div className={styles.error}>{t('error.load-trend-data-error', 'Could not load data for trend.')}</div>
      ) : recipeQueue && recipeQueue.length > 0 ? (
        <ResponsiveContainer height={300}>
          <LineChart data={trendData} margin={{ left: -12, right: 16 }}>
            <CartesianGrid stroke="#393939" />
            <XAxis
              dataKey="name"
              axisLine={{ stroke: 'var(--black-light)' }}
              tickLine={{ stroke: 'var(--black-light)' }}
              style={{ fill: 'white' }}
            />
            <YAxis
              yAxisId="left"
              type="number"
              domain={['auto', 'auto']}
              axisLine={{ stroke: 'var(--black-light)' }}
              tickLine={{ stroke: 'var(--black-light)' }}
              style={{ fill: 'white' }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={{ stroke: 'var(--black-light)' }}
              tickLine={{ stroke: 'var(--black-light)' }}
              style={{ fill: 'white' }}
            />
            <Tooltip content={<CustomTooltip />} />
            {lines.map((line) => (
              <Line
                key={line.title}
                type="linear"
                dataKey={line.title}
                stroke={line.color}
                isAnimationActive={false}
                hide={inactive.includes(line.title)}
                yAxisId={line.yaxis}
              />
            ))}
            <Legend onClick={handleLegendClick} wrapperStyle={{ position: 'relative' }} content={renderLegend} />
          </LineChart>
        </ResponsiveContainer>
      ) : null}
    </div>
  )
}
