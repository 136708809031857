// @ts-check
import { useMsal } from '@azure/msal-react'
import { Layout as AntLayout, Typography } from 'antd'
import { fetchPermissions } from 'api/authorizationApi'
import { accessRightsAtom, userAtom } from 'atoms'
import { getAccessRights } from 'authorization'
import { Loading } from 'components/common/loading'
import { PrivateRoute } from 'components/common/PrivateRoute'
import { Dashboard } from 'components/Dashboard'
import { Header } from 'components/Header'
import { NetworkError } from 'components/NetworkError'
import { ViewRecipe } from 'components/recipe-planner/view-recipe'
import { RecipePlanner } from 'components/RecipePlanner'
import { RecipeQueue } from 'components/RecipeQueue'
import { SliceSelector } from 'components/slice-selector/slice-selector'
import { StockpileDetails } from 'components/StockpileDetails'
import { apiScopes } from 'msalConfig'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { decodeToken } from 'utils/utils'
import { roles } from '../constants'
import styles from '../styles/Layout.module.css'

export function Layout() {
  const { instance: msalInstance } = useMsal()
  const [accessRights, setAccessRights] = useRecoilState(accessRightsAtom)
  const setUser = useSetRecoilState(userAtom)
  const { isLoading, isError, data, error, refetch } = useQuery('permissions', fetchPermissions, {
    onSuccess: async (permissions) => {
      const account = msalInstance.getActiveAccount()
      const { accessToken } = await msalInstance.acquireTokenSilent({ ...apiScopes, account })
      const decodedAccessToken = decodeToken(accessToken)
      const roles = decodedAccessToken.roles || []
      setUser({ name: decodedAccessToken.name || '', oid: account.localAccountId, role: roles[0] })
      setAccessRights(getAccessRights(permissions)(roles[0]))
    },
    onError: () => setAccessRights([]),
    refetchOnWindowFocus: false
  })

  const { Content } = AntLayout

  return (
    <AntLayout className={styles.layout}>
      <Header />
      <AntLayout className={styles.content}>
        <Content>
          {isLoading ? (
            <Loading size="large" />
          ) : data && accessRights.length > 0 ? (
            <Routes />
          ) : isError ? (
            <NetworkError refetch={refetch} errorStatusCode={error.response?.status} />
          ) : accessRights.length === 0 ? (
            <NoUserRoles />
          ) : null}
        </Content>
      </AntLayout>
    </AntLayout>
  )
}

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <PrivateRoute path="/stockpile/:id">
        <StockpileDetails rolesAllowed={[roles.geologist, roles.metallurgist, roles.mineGeologist, roles.readOnly]} />
      </PrivateRoute>
      <Route path="/recipe-queue">
        <RecipeQueue />
      </Route>
      <PrivateRoute path="/slice-selector">
        <SliceSelector rolesAllowed={[roles.geologist, roles.metallurgist, roles.mineGeologist]} />
      </PrivateRoute>
      <PrivateRoute exact path="/recipe-planner">
        <RecipePlanner rolesAllowed={[roles.geologist, roles.metallurgist, roles.mineGeologist]} />
      </PrivateRoute>
      <Route exact path="/recipe-planner/:recipeId/view">
        <ViewRecipe />
      </Route>
    </Switch>
  )
}

function NoUserRoles() {
  const { t } = useTranslation()
  return (
    <div className={styles.noUserRoles}>
      <Typography.Text>
        {t('error.no-mfm-roles', "There aren't any MFM roles assigned to your account")}
      </Typography.Text>
    </div>
  )
}
