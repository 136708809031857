import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory({ basename: '' })
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    disableTelemetry: process.env.NODE_ENV === 'development',
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    disableFetchTracking: false,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
})
appInsights.loadAppInsights()
// Change role name for the Application map
// https://github.com/microsoft/applicationinsights-js#telemetry-initializers
appInsights.addTelemetryInitializer((envelope) => (envelope.tags['ai.cloud.role'] = 'mfm-frontend'))

export { reactPlugin, appInsights }
