// @ts-check
import { ArrowRightOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Popconfirm, Row, Skeleton, Space } from 'antd'
import { completeRecipe, deleteRecipe, fetchRecipeQueueItem, fetchRecipeQueueItemNoGrades } from 'api/recipePlannerApi'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { queryClient } from 'index'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { hoursToShifts } from 'utils/date-utils'
import { formatTonnes, formatTwoDecimals, sortGrades } from 'utils/utils'
import styles from './recipe-queue-item.module.css'

/** @param {import('components').RecipeQueueItemPropsType} props */
export function RecipeQueueItem({ recipeId, latest, oldest, startTime, endTime, recipeStatus, oreLeftInHours }) {
  const history = useHistory()
  const { t } = useTranslation()
  const { recipeModifications, recipeGrade, recipePercentage } = useAccessRights()
  const { data, isLoading, isError, error, refetch } = useQuery(
    ['recipe-queue-item', recipeId],
    recipeGrade ? fetchRecipeQueueItem : fetchRecipeQueueItemNoGrades,
    {
      refetchOnWindowFocus: false
    }
  )
  const completeRecipex = useMutation(completeRecipe, {
    onSuccess: () => queryClient.invalidateQueries('recipe-queue')
  })
  const deleteRecipex = useMutation(deleteRecipe, {
    onSuccess: () => queryClient.invalidateQueries('recipe-queue')
  })
  const onRecipeView = (recipeId) => history.push(`/recipe-planner/${recipeId}/view`)
  const onRecipeComplete = (recipeId) => completeRecipex.mutate(recipeId)
  const onRecipeDelete = (recipeId) => deleteRecipex.mutate(recipeId)
  return isLoading ? (
    <div style={{ paddingTop: '32px' }}>
      <Skeleton active paragraph={{ rows: 1 }} />
    </div>
  ) : isError ? (
    <div>{t('recipe-queue.unable-to-load-recipe', 'Unable to load recipe details')}</div>
  ) : data ? (
    <div className={styles.recipe}>
      <div className={styles.recipeTitle}>
        <Row gutter={32}>
          <Col flex="none" style={{ width: '400px' }}>
            <div className={styles.duration}>
              <strong>{dayjs(startTime).format('D.M.YYYY H.mm')}</strong> <ArrowRightOutlined />{' '}
              <strong>{dayjs(endTime).format('D.M.YYYY H.mm')}</strong>
            </div>
          </Col>
          <Col flex="none" style={{ borderRight: '1px solid #535353', width: '125px' }}>
            <Badge
              status={
                recipeStatus === 'active'
                  ? 'processing'
                  : recipeStatus === 'waiting'
                  ? 'warning'
                  : recipeStatus === 'completed'
                  ? 'success'
                  : null
              }
              text={recipeStatus}
              style={{ textTransform: 'capitalize' }}
            />
          </Col>
          <Col flex="none" style={{ borderRight: '1px solid #535353', width: '270px' }}>
            <Space size="middle">
              {data.recipePileRatio.map((r) => (
                <div key={r.title}>
                  <strong>{r.title}</strong> {Math.round(r.usageRatio).toString()} %
                </div>
              ))}
            </Space>
          </Col>
          <Col flex="none" style={{ borderRight: '1px solid #535353' }}>
            <Trans
              i18nKey="recipe-queue.ore-left-shifts"
              defaults="Ore left <bold>{{count}} shifts</bold>"
              values={{ count: hoursToShifts(oreLeftInHours) }}
              components={{ bold: <strong /> }}
            />
          </Col>
          <Col flex="none">
            <div className={styles.author}>
              <strong>{data.author.creator}</strong> {dayjs(data.author.recipeCreationTime).format('D.M.YYYY H.mm')}
            </div>
          </Col>
          <Col flex="auto" className={styles.toolBar}>
            <Space>
              {recipeGrade && recipePercentage ? (
                <Button size="small" onClick={() => onRecipeView(recipeId)}>
                  <ArrowRightOutlined /> {t('common.view', 'View')}
                </Button>
              ) : null}
              {oldest && recipeModifications && recipeStatus !== 'completed' ? (
                <Popconfirm
                  placement="top"
                  trigger="click"
                  title={t('common.are-you-sure', 'Are You sure?')}
                  overlayStyle={{ whiteSpace: 'nowrap' }}
                  okText={t('common.ok', 'OK')}
                  cancelText={t('common.cancel', 'Cancel')}
                  onConfirm={() => onRecipeComplete(recipeId)}
                >
                  <Button size="small" type="primary" loading={completeRecipex.isLoading}>
                    <CheckOutlined /> {t('common.complete', 'Complete')}
                  </Button>
                </Popconfirm>
              ) : null}
              {latest && recipeModifications && recipeStatus !== 'completed' ? (
                <Popconfirm
                  placement="top"
                  trigger="click"
                  title={t('common.are-you-sure', 'Are You sure?')}
                  overlayStyle={{ whiteSpace: 'nowrap' }}
                  okText={t('common.ok', 'OK')}
                  cancelText={t('common.cancel', 'Cancel')}
                  onConfirm={() => onRecipeDelete(recipeId)}
                >
                  <Button size="small" type="primary" danger loading={deleteRecipex.isLoading}>
                    <DeleteOutlined /> {t('common.delete', 'Delete')}
                  </Button>
                </Popconfirm>
              ) : null}
            </Space>
          </Col>
        </Row>
      </div>
      <div className={styles.elementsAndTons}>
        <Row>
          {recipeGrade ? (
            <>
              {sortGrades(data.elementGradeRanges).map((element) => (
                <Col flex="none" key={element.elementSymbol}>
                  <div className={styles.element}>{element.elementSymbol}</div>
                  <div className={styles.grade}>
                    {element.elementSymbol === 'RQD'
                      ? Math.round(element.gradeAverage)
                      : formatTwoDecimals(element.gradeAverage)}
                  </div>
                </Col>
              ))}
              <Col flex="none">
                <div className={styles.element} style={{ textAlign: 'left' }}>
                  {t('common.areas', 'Areas')}
                </div>
                <div className={styles.grade} style={{ textAlign: 'left' }}>
                  {data.areas.map((a) => `${a.areaCode} ${Math.round(a.portion).toString()} %`).join(', ')}
                </div>
              </Col>
            </>
          ) : null}
          <Col flex="auto">
            <div className={styles.elementLast}>{t('common.tons', 'Tons')}</div>
            <div className={styles.gradeLast}>{formatTonnes(data.totalTons)}</div>
          </Col>
        </Row>
      </div>
    </div>
  ) : null
}
