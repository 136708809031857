// @ts-check
import {
  millCapacityAtom,
  selectedSlicesForRecipeTooAtom,
  selectedStockpilesForRecipeAtom,
  selectedLgpStockpilesAtom,
  selectedSourceLocationsAtom,
  lgpAndMinesSearchTermAtom,
  sliceSelectorExpandedAtom,
  addSliceDataAtom,
  usageRatioAtom,
  recipeStartTimeAtom
} from 'atoms'
import { useRecoilCallback, useResetRecoilState } from 'recoil'

export function useClearSelections() {
  const resetSelectedStockpiles = useResetRecoilState(selectedStockpilesForRecipeAtom)
  const resetMillCapacity = useResetRecoilState(millCapacityAtom)
  const resetSelectedLgpStockpiles = useResetRecoilState(selectedLgpStockpilesAtom)
  const resetSelectedSourceLocations = useResetRecoilState(selectedSourceLocationsAtom)
  const resetLgpAndMinesSearchTerm = useResetRecoilState(lgpAndMinesSearchTermAtom)
  const resetSliceSelectorExpanded = useResetRecoilState(sliceSelectorExpandedAtom)
  const resetAddSliceData = useResetRecoilState(addSliceDataAtom)
  const resetRecipeStartTime = useResetRecoilState(recipeStartTimeAtom)

  const resetSelections = useRecoilCallback(({ snapshot, reset }) => () => {
    const stockpiles = snapshot.getLoadable(selectedStockpilesForRecipeAtom).contents
    stockpiles.forEach(({ id }) => {
      reset(usageRatioAtom(id))
      reset(selectedSlicesForRecipeTooAtom(id))
    })
  })

  function clearSelections() {
    resetMillCapacity()
    resetSelectedStockpiles()
    resetSelections()
    resetSelectedLgpStockpiles()
    resetSelectedSourceLocations()
    resetLgpAndMinesSearchTerm()
    resetSliceSelectorExpanded()
    resetAddSliceData()
    resetRecipeStartTime()
  }

  return clearSelections
}
