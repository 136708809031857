export const sliceSelectorActionTypes = {
  SET_DATE: 'SET_DATE',
  SET_TIME: 'SET_TIME',
  TOGGLE_TIME_SWITCH: 'TOGGLE_TIME_SWITCH',
  TOGGLE_SELECTOR_VIEW: 'TOGGLE_SELECTOR_VIEW',
  SET_STOCKPILE_TO_VIEW_ID: 'SET_STOCKPILE_TO_VIEW_ID',
  SET_SLICES: 'SET_SLICES',
  TOGGLE_COMMENT_MODAL: 'TOGGLE_COMMENT_MODAL',
  SET_COMMENT: 'SET_COMMENT',
  SET_RECALCULATING: 'SET_RECALCULATING'
}

export function sliceSelectorReducer(state, action) {
  switch (action.type) {
    case sliceSelectorActionTypes.SET_DATE:
      return { ...state, date: action.payload }
    case sliceSelectorActionTypes.SET_TIME:
      return { ...state, time: action.payload }
    case sliceSelectorActionTypes.TOGGLE_TIME_SWITCH:
      return { ...state, timeSwitch: !state.timeSwitch }
    case sliceSelectorActionTypes.TOGGLE_SELECTOR_VIEW:
      return { ...state, selectorView: action.payload || !state.selectorView }
    case sliceSelectorActionTypes.SET_STOCKPILE_TO_VIEW_ID:
      return { ...state, stockpileToViewId: action.payload }
    case sliceSelectorActionTypes.SET_SLICES:
      return { ...state, slices: action.payload }
    case sliceSelectorActionTypes.TOGGLE_COMMENT_MODAL:
      return { ...state, isCommentModalVisible: !state.isCommentModalVisible }
    case sliceSelectorActionTypes.SET_COMMENT:
      return { ...state, comment: action.payload }
    case sliceSelectorActionTypes.SET_RECALCULATING:
      return { ...state, recalculating: action.payload }
    default:
      throw new Error()
  }
}

export const recipePlannerInitialState = {
  isTrendOpen: false,
  capacity: 6000,
  showEditTonnesModal: false,
  stockpiles: [],
  summary: [],
  /** @type {stockpileId: string, balance: number } balance */
  balance: [],
  recipeData: [],
  showConfirmationModal: false,
  viewMode: false,
  viewRecipeId: null
}

export const recipePlannerActionTypes = {
  TOGGLE_TREND: 'TOGGLE_TREND',
  SET_CAPACITY: 'SET_CAPACITY',
  TOGGLE_EDIT_TONNES_MODAL: 'TOGGLE_EDIT_TONNES_MODAL',
  SET_STOCKPILES: 'SET_STOCKPILES',
  SET_SUMMARY: 'SET_SUMMARY',
  SET_BALANCE: 'SET_BALANCE',
  SET_RECIPEDATA: 'SET_RECIPEDATA',
  TOGGLE_RECIPE_CONFIRMATION_MODAL: 'TOGGLE_RECIPE_CONFIRMATION_MODAL',
  TOGGLE_VIEW_MODE: 'TOGGLE_VIEW_MODE',
  SET_VIEW_RECIPEID: 'SET_VIEW_RECIPEID'
}

export function recipeSelectorReducer(state, action) {
  switch (action.type) {
    case recipePlannerActionTypes.TOGGLE_TREND:
      return { ...state, isTrendOpen: !state.isTrendOpen }
    case recipePlannerActionTypes.SET_CAPACITY:
      return { ...state, capacity: action.payload }
    case recipePlannerActionTypes.TOGGLE_EDIT_TONNES_MODAL:
      return { ...state, showEditTonnesModal: !state.showEditTonnesModal }
    case recipePlannerActionTypes.SET_STOCKPILES:
      return { ...state, stockpiles: action.payload }
    case recipePlannerActionTypes.SET_SUMMARY:
      return { ...state, summary: action.payload }
    case recipePlannerActionTypes.TOGGLE_SHOW_TREND:
      return { ...state, showTrend: !state.showTrend }
    case recipePlannerActionTypes.SET_BALANCE:
      return { ...state, balance: action.payload }
    case recipePlannerActionTypes.SET_RECIPEDATA:
      return { ...state, recipeData: action.payload }
    case recipePlannerActionTypes.TOGGLE_RECIPE_CONFIRMATION_MODAL:
      return { ...state, showConfirmationModal: !state.showConfirmationModal }
    case recipePlannerActionTypes.TOGGLE_VIEW_MODE:
      return { ...state, viewMode: !state.viewMode }
    case recipePlannerActionTypes.SET_VIEW_RECIPEID: {
      return { ...state, viewRecipeId: action.payload }
    }
    default:
      throw new Error()
  }
}

export const stockpileDetailsInitialState = {
  isCommentModalVisible: false,
  isUpdateModalVisible: false,
  updateTarget: { type: '', id: '', data: null },
  slices: [],
  comment: { content: '', corrector: '', readOnly: true, editComment: false }
}

export const stockpileDetailsActionTypes = {
  COMMENT_MODAL_VISIBLE: 'COMMENT_MODAL_VISIBLE',
  UPDATE_MODAL_VISIBLE: 'UPDATE_MODAL_VISIBLE',
  SET_UPDATE_TARGET: 'SET_UPDATE_TARGET',
  SET_SLICES: 'SET_SLICES',
  SET_COMMENT: 'SET_COMMENT'
}

export function stockpileDetailsReducer(state, action) {
  switch (action.type) {
    case stockpileDetailsActionTypes.COMMENT_MODAL_VISIBLE:
      return { ...state, isCommentModalVisible: !state.isCommentModalVisible }
    case stockpileDetailsActionTypes.UPDATE_MODAL_VISIBLE:
      return { ...state, isUpdateModalVisible: !state.isUpdateModalVisible }
    case stockpileDetailsActionTypes.SET_UPDATE_TARGET:
      return { ...state, updateTarget: action.payload }
    case stockpileDetailsActionTypes.SET_SLICES:
      return { ...state, slices: action.payload }
    case stockpileDetailsActionTypes.SET_COMMENT:
      return { ...state, comment: action.payload }
    default:
      throw new Error()
  }
}
