import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { Button } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocalStorage } from 'react-use'
import styles from './error-fallback.module.css'

export function ErrorFallback({ error }) {
  const { t } = useTranslation()
  const history = useHistory()
  const appInsights = useAppInsightsContext()
  const [, setStockpilesList] = useLocalStorage('stockpiles-list')

  function handleReset() {
    setStockpilesList([])
    history.go(0)
  }

  useEffect(() => {
    if (appInsights && error) {
      appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error })
    }
  }, [error, appInsights])

  return (
    <div className={styles.errorFallback} role="alert">
      <p>{t('error.something-went-wrong', 'Something went wrong')}</p>
      <Button type="primary" onClick={handleReset}>
        {t('common.refresh', 'Refresh')}
      </Button>
    </div>
  )
}
