import { InputNumber, Modal } from 'antd'
import { millCapacityAtom } from 'atoms'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

export function EditTonnesModal(props) {
  const { t } = useTranslation()
  const [millCapacity, setMillCapacity] = useRecoilState(millCapacityAtom)
  const [newTonnes, setNewTonnes] = useState(props.currentCapacity)

  function onCancel() {
    setNewTonnes(props.currentCapacity)
    props.toggleModal()
  }

  function onOk() {
    setMillCapacity(newTonnes)
    props.toggleModal()
  }

  return (
    <Modal
      title={t('recipe-planner.input-new-tonnes', 'Set new capacity for mill infeed')}
      visible={props.visible}
      onOk={onOk}
      onCancel={onCancel}
      width="300px"
      bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      centered
    >
      <div>
        <InputNumber
          defaultValue={millCapacity}
          onChange={(value) => setNewTonnes(value)}
          onPressEnter={onOk}
          okText={t('common.ok', 'OK')}
          cancelText={t('common.cancel', 'Cancel')}
          value={newTonnes}
        />
      </div>
    </Modal>
  )
}
