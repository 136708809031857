import { ArrowLeftOutlined } from '@ant-design/icons'
import { notification, Skeleton } from 'antd'
import {
  addStockpileComment,
  deleteStockpileComment,
  fetchStockpileById,
  postSliceSurveyResult,
  postStockpileSurveyResult,
  updateStockpileComment,
  updateStockpileState
} from 'api/stockpileInventoryApi'
import { recalculatingAtom, userAtom } from 'atoms'
import { NetworkError } from 'components/NetworkError'
import { Stockpile } from 'components/Stockpile'
import { CommentModal } from 'components/stockpile-details/comment-modal'
import { StockpileSelector } from 'components/StockpileSelector'
import { StockpileUpdateModal } from 'components/StockpileUpdateModal'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { useCallback, useEffect, useReducer } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import {
  stockpileDetailsActionTypes as actionTypes,
  stockpileDetailsInitialState as initialState,
  stockpileDetailsReducer as reducer
} from 'reducers'
import { createSlicesWithBatchesForDetails, findElementsAndGrades, formatTonnes } from 'utils/utils'
import { RECALCULATION_END_MINUTE } from '../constants'
import styles from '../styles/StockpileDetails.module.css'

/** @param {import('components').StockpileDetailsPropsType} props */
export function StockpileDetails(props) {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const recalculating = useRecoilValue(recalculatingAtom)
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()
  const { stockpileRead } = useAccessRights()
  const { isLoading, isError, data, error, refetch } = useQuery(['stockpiles', id], fetchStockpileById, {
    refetchInterval: Number(process.env.REACT_APP_POLLING_INTERVAL),
    refetchIntervalInBackground: true,
    enabled: stockpileRead && !recalculating
  })
  const addComment = useMutation('addStockpileComment', addStockpileComment(id), {
    onSuccess: () => queryClient.invalidateQueries(['stockpiles', id]),
    onError: () => notification.error({ description: t('error.add-comment', 'Could not add comment'), duration: 0 }),
    onSettled: () => toggleCommentModal({})
  })
  const updateComment = useMutation('updateStockpileComment', updateStockpileComment(id), {
    onSuccess: () => queryClient.invalidateQueries(['stockpiles', id]),
    onError: () => notification.error({ description: t('error.edit-comment', 'Could not edit comment'), duration: 0 }),
    onSettled: () => toggleCommentModal({})
  })
  const deleteComment = useMutation('deleteStockpileComment', deleteStockpileComment(id), {
    onSuccess: () => queryClient.invalidateQueries(['stockpiles', id]),
    onError: () => notification.error({ description: t('error.del-comment', 'Could not delete comment'), duration: 0 }),
    onSettled: () => toggleCommentModal({})
  })
  const updateStockpileSurveyResult = useMutation('updateStockpileSurveyResult', postStockpileSurveyResult(id), {
    onSuccess: () => queryClient.invalidateQueries(['stockpiles', id]),
    onError: () =>
      notification.error({ description: t('error.update-stockpile', 'Could not update stockpile'), duration: 0 })
  })
  const updateSliceSurveyResult = useMutation('updateSliceSurveyResult', (values) => postSliceSurveyResult(values), {
    onSuccess: () => queryClient.invalidateQueries(['stockpiles', id]),
    onError: () => notification.error({ description: t('error.update-slice', 'Could not update slice'), duration: 0 })
  })
  const updateState = useMutation(updateStockpileState(id), {
    onSuccess: () => queryClient.invalidateQueries(['stockpiles', id]),
    onError: (error) =>
      notification.error({
        description: t('error.stockpile-state-update', 'Could not update stockpile state'),
        duration: 0
      })
  })
  const user = useRecoilValue(userAtom)

  const toggleCommentModal = useCallback(({ content = '', corrector = '', readOnly = true, editComment = false }) => {
    dispatch({ type: actionTypes.SET_COMMENT, payload: { content, corrector, readOnly, editComment } })
    dispatch({ type: actionTypes.COMMENT_MODAL_VISIBLE })
  }, [])

  const toggleStockpileUpdateModal = useCallback(({ type = '', id = '', data = null }) => {
    dispatch({ type: actionTypes.SET_UPDATE_TARGET, payload: { type, id, data } })
    dispatch({ type: actionTypes.UPDATE_MODAL_VISIBLE })
  }, [])

  function handleUpdateComment(comment, mode) {
    switch (mode) {
      case 'ADD': {
        addComment.mutate(comment)
        break
      }
      case 'EDIT': {
        updateComment.mutate(comment)
        break
      }
      default: {
        throw Error()
      }
    }
  }
  function handleStockpileUpdate(values) {
    const userId = user.oid
    const surveyTime = values.surveyTime.toISOString()
    const id = state.updateTarget.id

    if (state.updateTarget.type === 'stockpile') {
      updateStockpileSurveyResult.mutate({ ...values, pileId: id, userId, surveyTime })
    }

    if (state.updateTarget.type === 'slice') {
      updateSliceSurveyResult.mutate({
        ...values,
        sliceId: id,
        userId,
        surveyTime
      })
    }

    toggleStockpileUpdateModal({})
  }

  const onStateChange = (newState) => updateState.mutate(newState)

  useEffect(() => {
    if (data) {
      const slicesWithBatches = createSlicesWithBatchesForDetails(
        data.slices,
        toggleCommentModal,
        toggleStockpileUpdateModal
      )

      const slicesWithTotal =
        slicesWithBatches.length > 0
          ? [
              {
                key: data.pileId,
                date: 'total',
                ...findElementsAndGrades(data.summary.averageGrades),
                tonnes: data.summary.tonnes,
                comment: !!data.comment
                  ? { onClick: () => toggleCommentModal({ content: data.comment, readOnly: true }) }
                  : null,
                menu: {
                  type: 'stockpile',
                  hasComment: !!data.comment,
                  toggleCommentModalFn: () =>
                    toggleCommentModal({ content: data.comment, readOnly: false, editComment: !!data.comment })
                }
              },
              ...slicesWithBatches
            ]
          : []
      dispatch({ type: actionTypes.SET_SLICES, payload: slicesWithTotal })
    }
  }, [data, toggleStockpileUpdateModal, toggleCommentModal])

  useEffect(() => {
    if (!recalculating) {
      queryClient.invalidateQueries('stockpiles')
    }
  }, [recalculating, queryClient])

  return (
    <>
      <div className={styles.stockpileDetails}>
        <StockpileSelector />
        <div className={styles.details}>
          {recalculating ? (
            <div className={styles.recalculating}>
              <Trans
                i18nKey="stockpile.recalculating-inventory-desc"
                defaults="Viewing stockpile details is disabled while inventory is recalculating. This is done by {{time}} approx."
                values={{ time: dayjs().minute(RECALCULATION_END_MINUTE).format('H.mm') }}
              />
            </div>
          ) : isLoading ? (
            <div style={{ paddingTop: '32px' }}>
              <Skeleton active paragraph={{ rows: 6 }} />
            </div>
          ) : isError ? (
            <NetworkError refetch={refetch} errorStatusCode={error.response?.status} />
          ) : data ? (
            <>
              <div className={styles.pageHeader}>
                <ArrowLeftOutlined
                  onClick={() => history.push('/dashboard')}
                  style={{ fontSize: '20px', padding: 0, margin: '0 16px 0 0' }}
                />
                {t('common.back-to-dashboard', 'Back to dashboard')}
              </div>
              <Stockpile
                id={id}
                title={data.summary.title}
                stockpileState={data.summary.state.toLowerCase()}
                editableState={data.summary.editableState}
                slices={state.slices}
                grades={data.summary.averageGrades}
                tonnes={formatTonnes(data.summary.tonnes)}
                onStateChange={onStateChange}
              />
            </>
          ) : null}
        </div>
      </div>
      <StockpileUpdateModal
        onOk={handleStockpileUpdate}
        onCancel={toggleStockpileUpdateModal}
        visible={state.isUpdateModalVisible}
        updateTarget={state.updateTarget}
      />
      <CommentModal
        visible={state.isCommentModalVisible}
        onOk={handleUpdateComment}
        onCancel={toggleCommentModal}
        onDelete={() => deleteComment.mutate()}
        comment={state.comment.content}
        readOnly={state.comment.readOnly}
        editComment={state.comment.editComment}
        addCommentLoading={addComment.isLoading}
        deleteCommentLoading={deleteComment.isLoading}
        updateCommentLoading={updateComment.isLoading}
        toggleCommentModal={toggleCommentModal}
      />
    </>
  )
}
