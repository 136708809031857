import { DatePicker, Space, Switch, TimePicker } from 'antd'
import { lastRecipeEndTimeAtom, recipeQueueAtom, recipeStartTimeAtom } from 'atoms'
import { Loading } from 'components/common/loading'
import dayjs, { Dayjs } from 'dayjs'
import { last } from 'rambda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { startOfNextShift } from 'utils/date-utils'
import styles from './slice-selector-title.module.css'

/** @param {import('components').SliceSelectorTitlePropsType} props */
export function SliceSelectorTitle(props) {
  const { t } = useTranslation()
  const [recipeStartTime, setRecipeStartTime] = useRecoilState(recipeStartTimeAtom)
  const recipeQueue = useRecoilValue(recipeQueueAtom)
  const setLastRecipeEndTime = useSetRecoilState(lastRecipeEndTimeAtom)

  /** @param {Dayjs} date */
  const onDateChange = (date) => {
    if (date === null) return
    setRecipeStartTime((cur) => ({ ...cur, date }))
  }

  /** @param {Dayjs} time */
  const onTimeChange = (time) => {
    if (time === null) return
    setRecipeStartTime((cur) => ({ ...cur, date: cur.date.hour(time.hour()).minute(time.minute()) }))
  }

  function toggleTimeSwitch(checked) {
    const sortedByEndTimes = recipeQueue.slice().sort((a, b) => new Date(a.endTime) - new Date(b.endTime))
    const lastRecipeEndTime = dayjs(last(sortedByEndTimes).endTime)
    checked
      ? setRecipeStartTime({ date: startOfNextShift(), customStartTime: true })
      : setRecipeStartTime({ date: lastRecipeEndTime, customStartTime: false })
  }

  useEffect(() => {
    if (recipeQueue?.length === 0) {
      setRecipeStartTime({ date: startOfNextShift(), customStartTime: true })
    }
    if (recipeQueue?.length > 0) {
      const sortedByEndTimes = recipeQueue.slice().sort((a, b) => new Date(a.endTime) - new Date(b.endTime))
      const lastRecipeEndTime = dayjs(last(sortedByEndTimes).endTime)
      setLastRecipeEndTime(lastRecipeEndTime)
      setRecipeStartTime((curr) => (curr.customStartTime ? curr : { date: lastRecipeEndTime, customStartTime: false }))
    }
  }, [recipeQueue, setLastRecipeEndTime, setRecipeStartTime])

  const value = recipeStartTime.date || startOfNextShift()
  return (
    <div className={styles.titleBar}>
      <div className={styles.title}>{t('slice-selector.title', 'Slice selector')}</div>
      {props.isLoadingQueue ? (
        <Loading />
      ) : (
        <Space>
          {t('slice-selector.custom-start-time', 'Custom start time')}
          <Switch
            checked={recipeStartTime.customStartTime}
            onChange={toggleTimeSwitch}
            disabled={recipeQueue?.length === 0}
          />
          <DatePicker
            onChange={onDateChange}
            disabled={!recipeStartTime.customStartTime}
            format={'DD.MM.YYYY'}
            value={value}
          />
          <TimePicker
            onChange={onTimeChange}
            disabled={!recipeStartTime.customStartTime}
            format={'HH.mm'}
            minuteStep={15}
            value={value}
          />
        </Space>
      )}
      <div className={styles.startTime}></div>
      <div></div>
    </div>
  )
}
