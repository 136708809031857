import { Menu } from 'antd'
import { stockpilesFilterAtom } from 'atoms'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import styles from './stockpile-filter.module.css'

export function StockpileFilter() {
  const [filter, setFilter] = useRecoilState(stockpilesFilterAtom)
  const { t } = useTranslation()
  return (
    <div className={styles.filtersMenu}>
      <Menu selectedKeys={[filter]} mode="horizontal">
        <Menu.Item onClick={() => setFilter('ALL')} key="ALL">
          {t('common.all', 'All')}
        </Menu.Item>
        <Menu.Item onClick={() => setFilter('ROM')} key="ROM">
          ROM
        </Menu.Item>
        <Menu.Item onClick={() => setFilter('LGP')} key="LGP">
          LGP
        </Menu.Item>
      </Menu>
    </div>
  )
}
