import ky from 'ky'
import { msalInstance } from '../index'
import { apiScopes } from '../msalConfig'

export const version = 'v1'
export const apis = {
  authorization: 'authorization-api',
  stockpileInventory: 'stockpile-inventory-api',
  recipePlanning: 'recipe-planning-api'
}
export async function getAccessToken(args) {
  const account = msalInstance.getActiveAccount()

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.')
  }
  const { accessToken } = await msalInstance.acquireTokenSilent({ ...args, account })

  return accessToken
}

export const api = ky.create({
  hooks: {
    beforeRequest: [
      async (request) => {
        const accessToken = await getAccessToken(apiScopes)
        request.headers.set('Authorization', `Bearer ${accessToken}`)
      }
    ]
  }
})
