// @ts-check
import { NavigationClient } from '@azure/msal-browser'
import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import { has } from 'rambda'
import { sortDateAsc, sortDateDesc } from 'utils/date-utils'
import { v4 as uuidv4 } from 'uuid'
import { elements, elementsOrder, pileTypes } from '../constants'

/** @param {import('types').StockpileFilter} filter */
export function filterStockpiles(filter) {
  /** @param {import('types').StockpileSummary} stockpile */
  return function (stockpile) {
    switch (filter) {
      case 'ALL':
        return pileTypes.rom.test(stockpile.pileType) || pileTypes.lgp.test(stockpile.pileType)
      case 'ROM':
        return pileTypes.rom.test(stockpile.pileType)
      case 'LGP':
        return pileTypes.lgp.test(stockpile.pileType)
      case 'TOTALROM':
        return pileTypes.totalrom.test(stockpile.pileType)
      case 'TOTALLGP':
        return pileTypes.totallgp.test(stockpile.pileType)
      case 'TOTALALL':
        return pileTypes.totalinventory.test(stockpile.pileType)
      default:
        return []
    }
  }
}

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
  constructor(history) {
    super()
    this.history = history
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url, options) {
    const relativePath = url.replace(window.location.origin, '')
    if (options.noHistory) {
      this.history.replace(relativePath)
    } else {
      this.history.push(relativePath)
    }

    return false
  }
}

/**
 * Return object containing minimumFractionDigits and maximumFractionDigits
 * @param {number} d Number of decimals
 * @returns {Object}
 *   @property {number} minimumFractionDigits
 *   @property {number} maximumFractionDigits
 */
const withDecimals = (d) => ({ minimumFractionDigits: d, maximumFractionDigits: d })
const locale = 'fi-FI'

/**
 * Format number to display two decimals
 * @param {number} num
 * @returns {string} Given number with two decimals
 */
export const formatTwoDecimals = (num) => (num === undefined ? '0' : num.toLocaleString(locale, withDecimals(2)))
export const formatOneDecimal = (num) => (num === undefined ? '0' : num.toLocaleString(locale, withDecimals(1)))

/**
 * Format given tonnes
 * @param {number} tonnes
 * @returns {string} Given tonnes separated with space
 */
export const formatTonnes = (tonnes) => (tonnes === undefined ? '0' : tonnes.toLocaleString(locale, withDecimals(0)))

/**
 * Capitalizes given string
 * @param {string} s
 * @returns {string} Capitalized string
 */
export const capitalize = (s) => (typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : '')

export function findElementsAndGrades(arr) {
  let elementsAndGrades = {}

  if (!Array.isArray(arr)) {
    return {}
  }

  arr.forEach((el) =>
    Object.assign(elementsAndGrades, {
      [el.elementSymbol.toLowerCase()]: el.gradeValue
    })
  )

  return elementsAndGrades
}

/**
 * Joins areas from array to a string
 * @param {import('types').Area[]} arr
 */
export const joinAreaCodesIntoString = (arr) => arr.map((a) => `${a.areaCode} ${Math.round(a.portion)} %`).join(', ')

/**
 * Takes Shift as a parameter and return shift type without the word 'Shift' in the end
 * @param {import('types').Shift} shift */
export const pickShiftType = (shift) => (has('type', shift) ? shift.type.split(/(?=[A-Z])/)[0] : '')

/** @param {string} element */
export function getContent(element = '') {
  const e = element.toUpperCase()

  if (!Object.keys(elements).includes(e)) {
    return null
  }

  return elements[e].content
}

export const sortStockpilesByPileType = (a, b) => (pileTypes.rom.test(a.pileType) ? -1 : 1)
export const sortByTitle = (a, b) => a.title.localeCompare(b.title)

/**
 * Funtion to sort grades in correct order
 * @param {import('types').ElementGrade[] | import('types').ElementGradeRange[]} grades
 */
export const sortGrades = (grades) => {
  if (grades.length === 0) {
    return []
  }

  const result = grades.slice().sort((a, b) => {
    const aIdx = elementsOrder.findIndex((e) => e === a.elementSymbol)
    const bIdx = elementsOrder.findIndex((e) => e === b.elementSymbol)

    return aIdx > bIdx ? 1 : -1
  })

  return result
}

/**
 * Sort by shift in ascending order (day-shift first)
 * @param {import('types').Shift} a
 * @param {import('types').Shift} b
 */
export const sortByShiftAsc = (a, b) => {
  if (a.startDate === b.startDate) {
    return a.type === 'dayShift' ? -1 : 1
  }

  return 1
}

/**
 * Sort by shift in descending order (night-shift first)
 * @param {import('types').Shift} a
 * @param {import('types').Shift} b
 */
export const sortByShiftDesc = (a, b) => {
  if (a.startDate === b.startDate) {
    return a.type === 'nightShift' ? -1 : 1
  }

  return 1
}

export const filterRomStockpiles = (arr) => (stockpile) => {
  if (stockpile.pileType.toUpperCase() !== 'ROM') {
    return true
  }

  return arr.includes(stockpile.title.toUpperCase())
}

/** @param {string} token */
export const decodeToken = (token) => (token ? jwtDecode(token) : '')

export const createInfeedBatches = (infeedBatches) =>
  infeedBatches.map(({ shift, mostAccurateGrades, source, assayedPercent, tonnes }) => ({
    key: uuidv4(),
    date: shift.startDate,
    shift: shift,
    ...findElementsAndGrades(mostAccurateGrades),
    areas: source,
    assayed: assayedPercent,
    direction: 'in',
    tonnes: tonnes
  }))

export const createOutfeedBatches = (outfeedBatches) =>
  outfeedBatches.map(({ shift, averageGrades, miningAreas, tonnes }) => ({
    key: uuidv4(),
    date: shift.startDate,
    shift: shift,
    ...findElementsAndGrades(averageGrades),
    areas: miningAreas,
    direction: 'out',
    tonnes: tonnes
  }))

export const createManualBatches = (manualBatches, toggleCommentModal) =>
  manualBatches.map(({ correctionTime, corrector, comment, tonnes }) => ({
    key: uuidv4(),
    date: correctionTime,
    shift: '',
    direction: tonnes > 0 ? 'in' : 'out',
    tonnes: tonnes,
    comment: { onClick: () => toggleCommentModal({ content: comment, corrector, readOnly: true, modalVisible: true }) }
  }))

export const createSlicesWithBatchesForDetails = (slices, toggleCommentModal, toggleStockpileUpdateModal) =>
  slices
    .sort((a, b) => sortDateDesc(a.pilingShift.startDate, b.pilingShift.startDate))
    .sort((a, b) => sortByShiftDesc(a.pilingShift, b.pilingShift))
    .map((slice) => {
      let batches = []

      const infeedBatches = createInfeedBatches(slice.infeedBatches)
      const outfeedBatches = createOutfeedBatches(slice.outfeedBatches)
      batches = [...infeedBatches, ...outfeedBatches]
        .sort((a, b) => sortDateDesc(a.shift.startDate, b.shift.startDate))
        .sort((a, b) => sortByShiftDesc(a.shift, b.shift))

      if (slice.manualBatches.length > 0) {
        batches.push({ key: 'stockpile-update', date: 'stockpile update', shift: 'colspan' })
        batches.push(...createManualBatches(slice.manualBatches, toggleCommentModal))
      }

      return {
        key: slice.id,
        date: slice.pilingShift.startDate,
        shift: slice.pilingShift,
        batches,
        ...findElementsAndGrades(slice.averageGrades),
        areas: slice.miningAreas,
        assayed: slice.assayedPercent,
        tonnes: slice.tonnes,
        menu: {
          type: 'slice',
          toggleUpdateModalFn: () => toggleStockpileUpdateModal({ type: 'slice', id: slice.id, data: slice })
        }
      }
    })

export function createDataSource(data) {
  let dataSource = []

  if (data === undefined || data.slices.length === 0) {
    return dataSource
  }

  dataSource.push({
    key: data.pileId,
    date: 'total',
    ...findElementsAndGrades(data.summary.averageGrades),
    tonnes: data.summary.tonnes
  })

  data.slices
    .sort((a, b) => sortDateAsc(a.pilingShift.startDate, b.pilingShift.startDate))
    .sort((a, b) => sortByShiftAsc(a.pilingShift, b.pilingShift))
    .forEach((s) => {
      dataSource.push({
        key: s.id,
        date: dayjs(s.pilingShift.startDate).format('DD.MM.YYYY'),
        shift: capitalize(pickShiftType(s.pilingShift)),
        ...findElementsAndGrades(s.averageGrades),
        areas: joinAreaCodesIntoString(s.miningAreas),
        assayed: s.assayedPercent,
        tonnes: s.tonnes,
        availableTons: s.availableTons
      })
    })

  return dataSource
}
export function createSlices(data) {
  let dataSource = []

  if (data === undefined || data.slices.length === 0) {
    return dataSource
  }

  data.slices
    .slice()
    .sort((a, b) => sortDateDesc(a.pilingShift.startDate, b.pilingShift.startDate))
    .sort((a, b) => sortByShiftDesc(a.pilingShift, b.pilingShift))
    .forEach((s) => {
      dataSource.push({
        key: idByShift(s.pilingShift),
        id: s.id,
        date: dayjs(s.pilingShift.startDate).format('DD.MM.YYYY'),
        shift: s.pilingShift,
        ...findElementsAndGrades(s.averageGrades),
        areas: joinAreaCodesIntoString(s.miningAreas),
        assayed: s.assayedPercent,
        tonnes: s.tonnes,
        availableTons: s.tonnes - s.tonnesUsedByRecipes,
        selectable: s.tonnes - s.tonnesUsedByRecipes > 0,
        sliceType: s.sliceType
      })
    })

  return dataSource
}
/** @param {import('types').Shift} shift */
export const idByShift = (shift) => `${shift.startDate}${shift.type}`

export const renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => ({
  [newProp]: old,
  ...others
})
export const calculateUsageRatios = (recipeSummaryStockpileDto) =>
  recipeSummaryStockpileDto
    ? recipeSummaryStockpileDto.reduce((acc, cur) => {
        let usageRatio = {}
        usageRatio.title = cur.title
        usageRatio.ratio = cur.usageRatio
        return [...acc, usageRatio]
      }, [])
    : []
