// @ts-check
import { ArrowRightOutlined } from '@ant-design/icons'
import { Badge, Col, Divider, Row, Skeleton, Space } from 'antd'
import { fetchRecipeQueueItem, fetchRecipeQueueItemNoGrades } from 'api/recipePlannerApi'
import dayjs from 'dayjs'
import { useAccessRights } from 'hooks/useAccessRights'
import { useQuery } from 'react-query'
import { formatTwoDecimals, sortGrades } from 'utils/utils'
import styles from './recipe-queue-dashboard-item.module.css'

/** @param {import('components').RecipeQueueItemPropsType} props */
export function RecipeQueueDashboardItem({ recipeId, latest, startTime, endTime, recipeStatus, oreLeftInHours }) {
  const { recipeGrade } = useAccessRights()
  const { data, isLoading, isError } = useQuery(
    ['recipe-queue-item', recipeId],
    recipeGrade ? fetchRecipeQueueItem : fetchRecipeQueueItemNoGrades,
    { refetchInterval: Number(process.env.REACT_APP_POLLING_INTERVAL) }
  )

  return isLoading ? (
    <div style={{ paddingTop: '32px' }}>
      <Skeleton active paragraph={{ rows: 1 }} />
    </div>
  ) : isError ? (
    'error'
  ) : data ? (
    <div className={styles.recipeQueueItem}>
      <div className={styles.duration}>
        <Badge
          status={
            recipeStatus === 'active'
              ? 'processing'
              : recipeStatus === 'waiting'
              ? 'warning'
              : recipeStatus === 'completed'
              ? 'success'
              : null
          }
        />
        <Space size="middle">
          <strong style={{ paddingLeft: '8px' }}>{dayjs(startTime).format('D.M.YYYY H.mm')}</strong>
          <ArrowRightOutlined />
          <strong>{dayjs(endTime).format('D.M.YYYY H.mm')}</strong>
        </Space>
      </div>
      <Divider />
      <div className={styles.areas}>
        <Space size="middle">
          {data.recipePileRatio.map((r) => (
            <span key={r.title}>
              <strong>{r.title}</strong> {Math.round(r.usageRatio).toString()} %
            </span>
          ))}
        </Space>
        <Divider />
        <div className={styles.grades}>
          <Row gutter={[8, 8]}>
            {recipeGrade
              ? sortGrades(data.elementGradeRanges).map((element) => (
                  <Col span={6} key={element.elementSymbol}>
                    <div className={styles.element}>{element.elementSymbol}</div>
                    <div className={styles.grade}>
                      {element.elementSymbol === 'RQD'
                        ? Math.round(element.gradeAverage)
                        : formatTwoDecimals(element.gradeAverage)}
                    </div>
                  </Col>
                ))
              : null}
          </Row>
        </div>
      </div>
    </div>
  ) : null
}
