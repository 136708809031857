import { useMemo, useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { recalculatingAtom } from 'atoms'
import { sortByShiftDesc } from 'utils/utils'
import { sortDateDesc } from 'utils/date-utils'
import { getRecipeStockpile } from 'api/recipePlannerApi'

export function useAddSliceSlice(pileId, pileName) {
  const recalculating = useRecoilValue(recalculatingAtom)
  const [data, setData] = useState()

  useEffect(async () => {
    if (!pileId || !pileName || recalculating) {
      return
    }

    const d = await getRecipeStockpile(pileId, pileName)
    setData(d)
  }, [pileId, pileName, recalculating])

  let slice = useMemo(() => {
    const s = data?.slices
      .slice()
      .sort((a, b) => sortDateDesc(a.pilingShift.startDate, b.pilingShift.startDate))
      .sort((a, b) => sortByShiftDesc(a.pilingShift, b.pilingShift))
    return s?.[0]
  }, [data])

  return { slice, pileName }
}
