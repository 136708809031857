import { Checkbox, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './stockpile-selector-item.module.css'

export function SelectorItem({ stockpile, checked, onClick }) {
  const { t } = useTranslation()
  return (
    <div className={styles.stockpile} onClick={() => onClick({ id: stockpile.pileId, title: stockpile.title })}>
      <div className={styles.stockpileTitle}>{stockpile.title}</div>
      <Space>
        <div className={styles.stockpileState}>
          {t(`stockpile.state.${stockpile.state.toLowerCase()}`, stockpile.state)}
        </div>
        <div className={styles.stockpileState}>
          {t(`stockpile.state.${stockpile.editableState.toLowerCase()}`, stockpile.editableState)}
        </div>
      </Space>
      <Checkbox style={{ marginLeft: '16px', transform: 'scale(1.125)' }} checked={checked} />
    </div>
  )
}

export function LgpSelectorItem({ stockpile, checked, onClick }) {
  return (
    <div className={styles.stockpile} onClick={() => onClick({ id: stockpile.pileId, title: stockpile.title })}>
      <div className={styles.stockpileTitle}>{stockpile.title}</div>
      <Checkbox style={{ marginLeft: '16px', transform: 'scale(1.125)' }} checked={checked} />
    </div>
  )
}

export function SourceLocationSelectorItem({ sourceLocation, checked, onClick }) {
  return (
    <div className={styles.stockpile} onClick={() => onClick(sourceLocation)}>
      <div className={styles.stockpileTitle}>{sourceLocation.name}</div>
      <Checkbox style={{ marginLeft: '16px', transform: 'scale(1.125)' }} checked={checked} />
    </div>
  )
}
