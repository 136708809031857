// @ts-check
import { InputNumber, Skeleton, Table, Typography, Button, Radio, Tag, Tooltip, Popconfirm } from 'antd'
import { PlusOutlined, InfoCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { deleteGhostSlice, fetchStockpileDetailsFromTheFuture } from 'api/recipePlannerApi'
import { selectedSlicesForRecipeTooAtom, selectedStockpilesForRecipeAtom, addSliceDataAtom } from 'atoms'
import { NetworkError } from 'components/NetworkError'
import dayjs from 'dayjs'
import { head, last } from 'rambda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { listOfSelectedSliceIdsSelector, selectedSlicesValidationSelector } from 'selectors'
import { capitalize, createSlices, formatTonnes, formatTwoDecimals, idByShift, pickShiftType } from 'utils/utils'
import styles from './selectable-slices-stockpile.module.css'
import styles2 from '../../styles/StockpileStateBar.module.css'

/** @param {import('components').SelectableSlicesStockpilePropsType} props */
export function SelectableSlicesStockpile(props) {
  const [slices, setSlices] = useState([])
  const [selectableSliceIds, setSelectableSliceIds] = useState([])
  const [selectedSlices, setSelectedSlices] = useRecoilState(selectedSlicesForRecipeTooAtom(props.stockpile.id))
  const selectedSliceIds = useRecoilValue(listOfSelectedSliceIdsSelector(props.stockpile.id))
  const selectedSlicesValidation = useRecoilValue(selectedSlicesValidationSelector)
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['recipe-stockpiles', props.stockpile.id, props.stockpile.title, dayjs(props.recipeStartTime.date).format()],
    fetchStockpileDetailsFromTheFuture,
    { refetchOnWindowFocus: true, enabled: !props.recalculating }
  )
  const { t } = useTranslation()
  const { Text } = Typography
  const inputTons = (input, record) => {
    const userInputTons = typeof input === 'number' ? input : null
    setSelectedSlices((slices) =>
      slices.map((s) => (s.sliceId === record.id ? { ...s, usedTonnes: userInputTons } : s))
    )
  }

  const isValidUsedTonnes = selectedSlicesValidation?.[props.stockpile.id] ?? true

  const dimmedOrNot = (input, record) => (record.availableTons <= 0 ? <Text type="secondary">{input}</Text> : input)

  const columns = [
    {
      title: null,
      dataIndex: 'sliceType',
      key: 'sliceType',
      minWidth: 0,
      render: (value, record) => renderSliceType(value, record, data.pileId, refetch, t)
    },
    {
      title: t('common.date', 'Date'),
      dataIndex: 'date',
      key: 'date',
      width: '120px',
      render: (date, record) =>
        date === 'total'
          ? dimmedOrNot(<strong style={{ textTransform: 'capitalize' }}>{date}</strong>, record)
          : dimmedOrNot(date, record)
    },
    {
      title: t('common.shift', 'Shift'),
      dataIndex: 'shift',
      key: 'shift',
      width: '70px',
      render: (shift, record) => (shift ? dimmedOrNot(capitalize(pickShiftType(shift)), record) : null)
    },
    {
      title: 'Au',
      dataIndex: 'au',
      key: 'au',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(formatTwoDecimals(grade), record) : null)
    },
    {
      title: 'S',
      dataIndex: 's',
      key: 's',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(formatTwoDecimals(grade), record) : null)
    },
    {
      title: 'As',
      dataIndex: 'as',
      key: 'as',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(formatTwoDecimals(grade), record) : null)
    },
    {
      title: 'Ctoc',
      dataIndex: 'ctoc',
      key: 'ctoc',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(formatTwoDecimals(grade), record) : null)
    },
    {
      title: 'Ccarb',
      dataIndex: 'ccarb',
      key: 'ccarb',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(formatTwoDecimals(grade), record) : null)
    },
    {
      title: 'Ag',
      dataIndex: 'ag',
      key: 'ag',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(formatTwoDecimals(grade), record) : null)
    },
    {
      title: 'Sb',
      dataIndex: 'sb',
      key: 'sb',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(formatTwoDecimals(grade), record) : null)
    },
    {
      title: 'RQD',
      dataIndex: 'rqd',
      key: 'rqd',
      align: 'right',
      width: '90px',
      render: (grade, record) => (grade ? dimmedOrNot(Math.round(grade), record) : null)
    },
    {
      title: t('common.areas', 'Areas'),
      dataIndex: 'areas',
      key: 'areas',
      align: 'right',
      width: '250px',
      render: (areas, record) => dimmedOrNot(areas, record)
    },
    {
      title: t('common.assayed', 'Assayed'),
      dataIndex: 'assayed',
      key: 'assayed',
      align: 'right',
      render: (percent, record) =>
        typeof percent === 'number' ? dimmedOrNot(`${Math.round(percent)} %`, record) : null
    },
    {
      title: t('common.tons', 'Tons'),
      dataIndex: 'tonnes',
      key: 'tonnes',
      align: 'right',
      render: (tons, record) => {
        /*
        remove comments if we add total row
        if (record.date === 'total') {
          return dimmedOrNot(formatTonnes(tons), record)
        }
        */
        if (
          !record.availableTons ||
          record.availableTons < 0 ||
          Math.round(tons) === Math.round(record.availableTons)
        ) {
          return dimmedOrNot(formatTonnes(tons), record)
        }

        return (
          <>
            <Text type="secondary">{formatTonnes(tons)}</Text> / {formatTonnes(record.availableTons)}
          </>
        )
      }
    },
    {
      title: t('common.change', 'Change'),
      dataIndex: 'change',
      key: 'change',
      align: 'right',
      render: (text, record) => {
        if (!showChangeInput(record, selectedSliceIds)) return null

        const value = selectedSlices?.find((s) => s.sliceId === record.id)?.usedTonnes

        const style = { width: '100px' }

        let tooltipTitle = null

        if (!isValidUsedTonnes) {
          style.borderColor = 'var(--error)'
          tooltipTitle = (
            <>
              <InfoCircleOutlined /> {t('slice-selector.see-instructions')}{' '}
              <a
                href="https://dev.azure.com/AgnicoEagleAzure/Finland%20Mill%20Feed%20Management%20Project%20(MFM)/_wiki/wikis/MFM/433/Ghost-slices-Slice-selector?anchor=reset-or-update-slice-tons"
                target="_blank"
                style={{ color: 'var(--primary-normal)', fontWeight: 'bold' }}
              >
                link
              </a>
            </>
          )
        }

        return (
          <Tooltip title={tooltipTitle} mouseEnterDelay={0.8}>
            <InputNumber
              style={style}
              size="small"
              value={value}
              onChange={(tons) => inputTons(tons, record)}
              min={0}
            />
          </Tooltip>
        )
      }
    }
  ]
  const rowSelection = {
    hideSelectAll: true,
    type: 'checkbox',
    selectedRowKeys: selectedSliceIds,
    onSelect: ({ key, id, tonnes, availableTons, shift }, selected) =>
      selected
        ? setSelectedSlices((slices) => [...slices, { sliceId: id, tons: tonnes, availableTons, shift }])
        : last(selectedSliceIds) === key
        ? setSelectedSlices((slices) => slices.filter((slice) => idByShift(slice.shift) !== key))
        : null,
    renderCell: (checked, record, index, originNode) => {
      if (props.disableSelection) return null
      const disabled = !selectableSliceIds.slice(0, Math.abs(selectedSliceIds.length + 1)).includes(record.key)
      return record.date === 'total' || record.availableTons <= 0
        ? null
        : {
            ...originNode,
            props: {
              ...originNode.props,
              disabled
            }
          }
    }
  }
  useEffect(() => {
    if (data) {
      const slices = createSlices(data)
      setSlices(slices)
      setSelectableSliceIds(slices.filter((d) => d.selectable).map((d) => d.key))
    }
  }, [data])

  return isLoading ? (
    <div style={{ paddingTop: '32px' }}>
      <Skeleton active paragraph={{ rows: 5 }} />
    </div>
  ) : data && slices.length > 0 ? (
    <Table
      size="small"
      title={() => <TableTitle data={data} />}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={slices}
      pagination={false}
      style={{ paddingBottom: '16px' }}
      className={styles.simpleStockpile}
      locale={{ emptyText: t('common.no-data', 'Could not find any slices for stockpile') }}
    />
  ) : isError ? (
    <NetworkError refetch={refetch} errorStatusCode={error.response?.status} />
  ) : null
}

function TableTitle({ data }) {
  const { t } = useTranslation()
  const setAddSliceData = useSetRecoilState(addSliceDataAtom)

  function handleClick(e) {
    setAddSliceData({
      pileId: data.pileId,
      pileName: data.title,
      sourceName: null
    })
  }

  return (
    <div className={styles.titleContainer}>
      <div className={styles.title}>{data.title}</div>
      {data.pileType === 'rom' ? (
        <Button type="link" size="small" icon={<PlusOutlined />} onClick={handleClick}>
          <span className={styles.addSliceButtonText}>{t('slice-selector.add-slice', 'Add slice')}</span>
        </Button>
      ) : (
        <RomSelector data={data} />
      )}
    </div>
  )
}

function RomSelector({ data }) {
  const selectedStockpilesForRecipe = useRecoilValue(selectedStockpilesForRecipeAtom)
  const setAddSliceData = useSetRecoilState(addSliceDataAtom)

  function handleChange(e) {
    const sp = selectedStockpilesForRecipe.find((d) => d.id === e.target.value)

    setAddSliceData({
      pileId: sp.id,
      pileName: sp.title,
      sourceName: data.title
    })
  }

  return (
    <div className={styles2.stateBar}>
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        size="small"
        className={styles.stateGroup}
        value={null}
        onChange={handleChange}
      >
        {selectedStockpilesForRecipe.map((sp) => (
          <Radio.Button key={sp.id} value={sp.id}>
            {sp.title}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  )
}

function renderSliceType(value, record, pileId, refetch, t) {
  const obj = { children: value, props: {} }

  const handleRemove = async (e) => {
    e.preventDefault()
    try {
      await deleteGhostSlice(pileId, record.id)
      refetch()
    } catch {
      // TODO: Add error handling
    }
  }

  if (value === 'ghost') {
    const closeIcon = (
      <Popconfirm
        key="cancel"
        title={t('common.are-you-sure', 'Are You sure?')}
        okText={t('common.ok', 'OK')}
        cancelText={t('common.cancel', 'Cancel')}
        onConfirm={handleRemove}
      >
        <Button type="link" size="small">
          <CloseOutlined />
        </Button>
      </Popconfirm>
    )

    obj.children = (
      <Tag style={{ paddingRight: 0 }} closable={true} closeIcon={closeIcon} onClose={(e) => e.preventDefault()}>
        {'Ghost'}
      </Tag>
    )

    return obj
  }
}

function showChangeInput(record, selectedSliceIds) {
  return selectedSliceIds.includes(record.key)
}
