import { CommentOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from '../styles/EllipsesMenu.module.css'

/** @param {import('components').EllipsesMenuPropsType} props */
export function EllipsesMenu({ toggleUpdateModalFn, toggleCommentModalFn, hasComment, type }) {
  const { t } = useTranslation()

  const content = (
    <div className={styles.menu}>
      {toggleUpdateModalFn ? (
        <div className={styles.menuItem}>
          <EditOutlined />
          <span onClick={toggleUpdateModalFn}>{t(`common.update-${type}`, `Update ${type}`)}</span>
        </div>
      ) : null}
      {toggleCommentModalFn ? (
        <div className={styles.menuItem}>
          <CommentOutlined />
          <span onClick={toggleCommentModalFn}>
            {hasComment ? t('common.edit-comment', 'Edit comment') : t('common.add-comment', 'Add comment')}
          </span>
        </div>
      ) : null}
    </div>
  )

  return (
    <Popover placement="bottomRight" content={content} trigger="click" color="white">
      <EllipsisOutlined style={{ fontSize: '16px' }} />
    </Popover>
  )
}
