import { ArrowRightOutlined } from '@ant-design/icons'
import { Badge, Col, Divider, Row, Skeleton, Space, Table, Tag, Typography } from 'antd'
import { fetchRecipeQueueItem } from 'api/recipePlannerApi'
import { Title } from 'components/common/title'
import { Capacity } from 'components/recipe-planner/capacity'
import { PercentageSelector } from 'components/recipe-planner/percentage-selector'
import { Summary } from 'components/recipe-planner/summary'
import dayjs from 'dayjs'
import { useRecipeQueue } from 'hooks/useRecipeQueue'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { sortDateDesc } from 'utils/date-utils'
import {
  capitalize,
  findElementsAndGrades,
  formatTonnes,
  formatTwoDecimals,
  idByShift,
  joinAreaCodesIntoString,
  pickShiftType,
  sortByShiftDesc
} from 'utils/utils'
import styles from './view-recipe.module.css'

export function ViewRecipe() {
  const { recipeId } = useParams()
  const [recipeQueueItem, setRecipeQueueItem] = useState()
  const { isLoading: isLoadingQueue, isError: isErrorQueue, queue } = useRecipeQueue()
  const { t } = useTranslation()
  const { isLoading, isError, data } = useQuery(['recipe-queue', recipeId], fetchRecipeQueueItem, {
    refetchOnWindowFocus: false
  })
  const { Text } = Typography
  function createDataSource(data) {
    let dataSource = []

    if (data === undefined) {
      return dataSource
    }

    const slices = [data.firstTonFixSlice, ...data.fullSlices, data.lastPartialSlice].filter((x) => x)
    dataSource.push({
      key: data.stockpileId,
      date: <strong>{t('common.total', 'Total')}</strong>,
      ...findElementsAndGrades(data.pileAverageGrades),
      tonnes: formatTonnes(data.pileTonnes),
      areas: joinAreaCodesIntoString(data.pileMiningAreas)
    })

    slices
      // filter out slices that aren't actually used by the recipe but are still stored in db (for debugging purposes).
      .filter((s) => s.tonnesUsedByThisRecipe !== 0)
      .sort((a, b) => sortDateDesc(a.pilingShift.startDate, b.pilingShift.startDate))
      .sort((a, b) => sortByShiftDesc(a.pilingShift, b.pilingShift))
      .forEach((s) => {
        dataSource.push({
          key: idByShift(s.pilingShift),
          date: dayjs(s.pilingShift.startDate).format('DD.MM.YYYY'),
          shift: capitalize(pickShiftType(s.pilingShift)),
          ...findElementsAndGrades(s.averageGrades),
          areas: joinAreaCodesIntoString(s.miningAreas),
          assayed: s.assayedPercent,
          tonnes: s.tonnesUsedByThisRecipe,
          sliceType: s.sliceType
        })
      })

    return dataSource
  }
  const columns = [
    {
      title: null,
      dataIndex: 'sliceType',
      key: 'sliceType',
      minWidth: 0,
      render: (value) => renderSliceType(value, t)
    },
    {
      title: t('common.date', 'Date'),
      dataIndex: 'date',
      key: 'date',
      width: 150
    },
    {
      title: t('common.shift', 'Shift'),
      dataIndex: 'shift',
      key: 'shift',
      width: 70
    },
    {
      title: 'Au',
      dataIndex: 'au',
      key: 'au',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'S',
      dataIndex: 's',
      key: 's',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'As',
      dataIndex: 'as',
      key: 'as',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ctoc',
      dataIndex: 'ctoc',
      key: 'ctoc',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ccarb',
      dataIndex: 'ccarb',
      key: 'ccarb',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Ag',
      dataIndex: 'ag',
      key: 'ag',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'Sb',
      dataIndex: 'sb',
      key: 'sb',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? formatTwoDecimals(grade) : null)
    },
    {
      title: 'RQD',
      dataIndex: 'rqd',
      key: 'rqd',
      align: 'right',
      width: 90,
      render: (grade) => (grade ? Math.round(grade) : null)
    },
    {
      title: t('common.areas', 'Areas'),
      dataIndex: 'areas',
      key: 'areas',
      align: 'right',
      width: 300
    },
    {
      title: t('common.assayed', 'Assayed'),
      dataIndex: 'assayed',
      key: 'assayed',
      align: 'right',
      width: 150,
      render: (percent) => (typeof percent === 'number' ? `${Math.round(percent)} %` : null)
    },
    {
      title: t('common.tons', 'Tons'),
      dataIndex: 'tonnes',
      key: 'tonnes',
      align: 'right',
      render: (tons, record) => {
        if (!record.availableTons) {
          return formatTonnes(tons)
        }

        if (record.availableTons === 0) {
          return 0
        }

        if (Math.round(tons) === Math.round(record.availableTons)) {
          return formatTonnes(tons)
        }

        return (
          <>
            <Text type="secondary">{formatTonnes(tons)}</Text> / {formatTonnes(record.availableTons)}
          </>
        )
      }
    }
  ]
  useEffect(() => {
    if (queue) {
      setRecipeQueueItem(queue.find((i) => i.recipeId === recipeId))
    }
  }, [queue, setRecipeQueueItem, recipeId])
  return (
    <div className={styles.recipePlanner}>
      <div className={styles.stockpiles}>
        <div className={styles.stockpilesHeader}>
          <Title underlined>{t('recipe-planner.view-recipe', 'View recipe')}</Title>
          {data && recipeQueueItem ? (
            <Space size="large">
              <div>
                {dayjs(recipeQueueItem.startTime).format('DD.MM.YYYY HH.mm')}
                <ArrowRightOutlined style={{ padding: '0 16px' }} />
                {dayjs(recipeQueueItem.endTime).format('DD.MM.YYYY HH.mm')}
              </div>
              <div>
                <Badge
                  status={
                    data.status === 'active'
                      ? 'processing'
                      : data.status === 'waiting'
                      ? 'warning'
                      : data.status === 'completed'
                      ? 'success'
                      : null
                  }
                  text={data.status}
                  style={{ textTransform: 'capitalize' }}
                />
              </div>
              <div>{data.author.creator}</div>
            </Space>
          ) : null}
        </div>
        <div className={styles.slices}>
          {isLoading || isLoadingQueue ? (
            <div style={{ paddingTop: '32px' }}>
              <Skeleton active paragraph={{ rows: 5 }} />
            </div>
          ) : isError || isErrorQueue ? (
            t('recipe-queue.loading-recipe-details-error', 'Error')
          ) : data?.recipeSummaryStockpileDto?.length > 0 && recipeQueueItem ? (
            data.recipeSummaryStockpileDto.map((i) => (
              <div className={styles.stockpile} key={i.stockpileId}>
                <Table
                  size="small"
                  title={() => (
                    <Row align="middle" gutter={32}>
                      <Col flex="none" style={{ borderRight: '1px solid #535353' }}>
                        <span className={styles.title}>{i.title}</span>
                      </Col>
                      <Col flex="none">
                        <Trans
                          i18nKey="recipe-queue.ore-left-hours"
                          defaults="Ore left <bold>{{count}} hours</bold>"
                          values={{ count: i.oreLeftInHours }}
                          components={{ bold: <strong /> }}
                        />
                      </Col>
                      <Col flex="auto"></Col>
                      <Col flex="none">
                        <PercentageSelector value={i.usageRatio} stockpileId={i.stockpileId} disabled={true} />
                      </Col>
                    </Row>
                  )}
                  columns={columns}
                  dataSource={createDataSource(i)}
                  pagination={false}
                  style={{ paddingBottom: '32px' }}
                />
              </div>
            ))
          ) : null}
        </div>
      </div>
      <div className={styles.summary}>
        {data ? (
          <>
            <Summary areas={data.areas} grades={data.elementGradeRanges} viewMode={true} />
            <Divider />
            <Capacity
              viewMode={true}
              totalHours={data?.totalHours}
              totalTons={data?.totalTons}
              recipeCapacity={data?.capacity}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

function renderSliceType(value, t) {
  const obj = { children: value, props: {} }

  if (value === 'ghost') {
    obj.children = <Tag>{'Ghost'}</Tag>
    return obj
  }
}
