import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { addSliceDataAtom, addSliceGhostBatchesAtom, addSliceExistingBatchesAtom } from 'atoms'
import { postGetSliceSummary } from 'api/recipePlannerApi'
import { sortGrades, sortByShiftDesc } from 'utils/utils'
import { sortDateDesc } from 'utils/date-utils'

export function useAddSliceTableData() {
  const addSliceExistingBatches = useRecoilValue(addSliceExistingBatchesAtom)

  const addSliceData = useRecoilValue(addSliceDataAtom)

  const [total, setTotal] = useState()

  const addSliceGhostBatches = useRecoilValue(addSliceGhostBatchesAtom)

  const batches = useMemo(
    () =>
      [...addSliceExistingBatches, ...addSliceGhostBatches]
        .sort((a, b) => sortDateDesc(a.shift.startDate, b.shift.startDate))
        .sort((a, b) => sortByShiftDesc(a.shift, b.shift))
        .map((v) => ({ ...v, key: uuidv4() })),
    [addSliceExistingBatches, addSliceGhostBatches]
  )

  useEffect(async () => {
    if (!addSliceData?.pileId || !batches?.length) {
      setTotal(null)
      return
    }
    try {
      const data = await postGetSliceSummary(addSliceData.pileId, { batches })
      setTotal(mapTotal(data))
    } catch (err) {
      console.log(err)
    }
  }, [addSliceData, batches, postGetSliceSummary, setTotal])

  return {
    total,
    batches
  }
}

function mapTotal(data) {
  return {
    key: uuidv4(),
    shift: { startDate: 'total', type: '' },
    tonnes: data.tonnes,
    source: data.areas,
    grades: sortGrades(data.averageGrades),
    assayed: data.assayed
  }
}
