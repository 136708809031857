import { api, apis, version } from './api'

const { REACT_APP_API_URL, REACT_APP_NO_API_MANAGEMENT } = process.env
let plannerApi = api.extend({ prefixUrl: `${REACT_APP_API_URL}/${apis.recipePlanning}/${version}` })

if (REACT_APP_NO_API_MANAGEMENT === 'true') {
  plannerApi = api.extend({ prefixUrl: `${REACT_APP_API_URL}` })
}

export const fetchRecipeQueue = () => plannerApi.get('recipe-queue').json()
export const fetchRecipeQueueItem = ({ queryKey }) =>
  queryKey[1] ? plannerApi.get(`recipe-queue/${queryKey[1]}`).json() : null
export const fetchRecipeQueueItemNoGrades = ({ queryKey }) =>
  queryKey[1] ? plannerApi.get(`recipe-queue-no-grades/${queryKey[1]}`).json() : null

export const fetchStockpileDetailsFromTheFuture = ({ queryKey }) =>
  queryKey[1]
    ? plannerApi.get(`${queryKey[0]}/${queryKey[1]}?stockpileTitle=${queryKey[2]}&startTime=${queryKey[3]}`).json()
    : null
export const fetchRecipePlan = ({ queryKey }) => plannerApi.post('recipe-planner', { json: { ...queryKey[1] } }).json()
export const postNewRecipe = (newRecipe) => plannerApi.post('recipe-queue', { json: newRecipe })

export const completeRecipe = (recipeId) => plannerApi.put(`recipe-queue/${recipeId}/complete`)
export const deleteRecipe = (recipeId) => plannerApi.delete(`recipe-queue/${recipeId}`)
export const fetchTrend = () => plannerApi.get('recipe-trend').json()

export const fetchSourceLocations = () => plannerApi.get('source-locations').json()

export const postGetSliceSummary = (stockpileId, data) =>
  plannerApi.post(`recipe-stockpiles/${stockpileId}/slice-summary`, { json: data }).json()

export const getBatches = (stockpileId, query) =>
  plannerApi.get(`recipe-stockpiles/${stockpileId}/batches`, { searchParams: query }).json()

export const postSlice = (stockpileId, data) =>
  plannerApi.post(`recipe-stockpiles/${stockpileId}/slices`, { json: data }).json()

export const getRecipeStockpile = (stockpileId, stockpileTitle) =>
  plannerApi.get(`recipe-stockpiles/${stockpileId}`, { searchParams: { stockpileTitle } }).json()

export const getElementsGradeValueLimits = () => plannerApi.get('elements-grade-value-limits').json()

export const deleteGhostSlice = (stockpileId, sliceId) =>
  plannerApi.delete(`recipe-stockpiles/${stockpileId}/slices/${sliceId}`)
